import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setReadNotif } from "../../store/actions/pageContainer";

// Asset
import alarm from "../../assets/icon/Alarm.svg";
import NoData from "../NoData";
import AppRoutes from "../../config/routes";
import { setActiveStep as setActivePengguna } from "../../store/actions/dataPengguna";
import { setActiveStep as setActiveGrup } from "../../store/actions/dataGroup";
import { setActiveStep as setActiveProyek } from "../../store/actions/dataProyek";
import { setActiveStep as setActiveLoker } from "../../store/actions/dataLoker";
import { setActiveStep as setActivePost } from "../../store/actions/dataPostingan";
import { setActiveStep as setActiveTiket } from "../../store/actions/dataTiket";
import { stringToColor } from "../../utils/helpers";

const Notification = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const { listNotif } = useSelector(
    ({ pageContainer }) => ({
      listNotif: pageContainer.listNotif,
    }),
    shallowEqual
  );

  const handleOpenNotif = (notif) => {
    dispatch(setReadNotif(notif.notif_id));
    const destRoute = AppRoutes.find(
      (item) => item.id === parseInt(notif.fitur === "8" ? "602" : notif.fitur)
    );
    if (destRoute !== undefined) {
      if (destRoute.id === 6) {
        dispatch(setActivePost("all"));
      } else if (destRoute.id === 3) {
        dispatch(setActiveGrup("all"));
      } else if (destRoute.id === 4) {
        dispatch(setActiveLoker("all"));
      } else if (destRoute.id === 5) {
        dispatch(setActiveProyek("all"));
      } else if (destRoute.id === 2) {
        dispatch(setActivePengguna("all"));
      } else if (destRoute.id === 12) {
        dispatch(setActiveTiket("all"));
      } else if (destRoute.id === 8) {
        dispatch(setActivePost("all"));
      }
    }
    history.push(destRoute.path);
  };

  return (
    <div
      style={{
        width: "506px",
        borderRadius: "20px",
      }}
    >
      <div
        style={{
          backgroundColor: "#115abe",
          padding: "10px",
          display: " flex",
          alignItems: "center",
          borderRadius: "20px 20px 0 0",
        }}
      >
        <img src={alarm} alt="alarm-logo" />
        <span
          style={{
            color: "#fff",
            fontWeight: "500",
            fontSize: "15px",
            marginLeft: "9px",
          }}
        >
          Notifikasi
        </span>
      </div>
      {listNotif.length > 0 ? (
        <div style={{ height: "680px", overflow: "scroll" }}>
          {listNotif.map((notif, index) => (
            <MenuItem
              style={{
                display: "flex",
                gap: "10px",
                padding: "10px",
                backgroundColor:
                  notif.is_read_admin === "1" ? "#fff" : "#D1E4F2",
              }}
              key={index}
              onClick={() => handleOpenNotif(notif)}
            >
              {notif.foto_profile !== null ? (
                <img
                  src={notif.foto_profile}
                  alt="profileImage"
                  style={{ borderRadius: "10px" }}
                  height={50}
                  width={50}
                />
              ) : (
                <DefaultImgContainer
                  style={{
                    backgroundColor: stringToColor(
                      `${
                        notif?.full_name !== null ? notif?.full_name : "Blank"
                      }`
                    ),
                  }}
                >
                  <DefaultImg>
                    {notif?.full_name !== null
                      ? notif?.full_name?.slice(0, 1).toUpperCase()
                      : "-"}
                  </DefaultImg>
                </DefaultImgContainer>
              )}
              <div style={{ whiteSpace: "pre-wrap" }}>
                <span
                  style={{
                    fontWeight: "700",
                    marginRight: "5px",
                  }}
                >
                  {notif.full_name}
                </span>
                <span style={{ fontWeight: "300" }}>{notif.conten}</span>
                <span style={{ fontWeight: "500", marginLeft: "5px" }}>
                  {moment(notif.create_at).fromNow()}
                </span>
              </div>
            </MenuItem>
          ))}
        </div>
      ) : (
        <div style={{ height: "100px" }}>
          <NoData />
        </div>
      )}
    </div>
  );
};

const DefaultImgContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 50px;
  border-radius: 10px;
`;

const DefaultImg = styled("p")`
  color: white;
  text-decoration: none;
  font-size: 13px;
`;

export default Notification;
