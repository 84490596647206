/* eslint-disable react-hooks/exhaustive-deps */
// Page Data Postingan
// --------------------------------------------------------

import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PageContainer from "../../../components/PageContainer";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../../../store/actions/dataPostingan";

// Page
import DataPostingan from "./DataPostingan";
import DetailPostingan from "./DetailPostingan";
import DetailPengguna from "./DetailPengguna";
import SemuaPostingan from "./SemuaPostingan";

const DataPostinganReport = () => {
  const dispatch = useDispatch();

  const { activeStep } = useSelector(
    (state) => state.dataPostingan,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      dispatch(setActiveStep("page"));
    };
  }, []);

  return (
    <PageContainer>
      <Container>
        {activeStep === "page" && <DataPostingan />}
        {activeStep === "detail" && <DetailPostingan />}
        {activeStep === "pengguna" && <DetailPengguna />}
        {activeStep === "all" && <SemuaPostingan />}
      </Container>
    </PageContainer>
  );
};

const Container = styled("div")(() => ({
  width: "100%",
  height: "100%",
}));

export default DataPostinganReport;
