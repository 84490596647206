import {
  serviceGetDashboardDataProyek,
  serviceGetFilterDataProyek,
  serviceProyekDetail,
  serviceRequestDetail,
  serviceGetDetailDataPengguna,
  serviceTabTask,
  serviceGetCity,
  serviceApproved,
  serviceTabDiskusiTask,
  serviceExportProyek,
} from "../../utils/api";
import { setLoading, setValueReason } from "./pageContainer";
import { capFirstLetter } from "../../utils/helpers";

export const SET_DASHBOARD_DATA_PROYEK = "DASHBOARD_DATA_PROYEK";
export const SET_ALL_DATA_PROYEK = "SET_ALL_DATA_PROYEK";
export const SET_DETAIL = "SET_DETAIL";
export const SET_ACTIVE_STEP_PROYEK = "SET_ACTIVE_STEP_PROYEK";
export const SET_REQUEST = "SET_REQUEST";
export const SET_DETAIL_PENGGUNA = "SET_DETAIL_PENGGUNA";
export const SET_TAB_TASK = "SET_TAB_TASK";
export const SET_ALL_CITY = "SET_ALL_CITY";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CITY = "SET_CITY";
export const SET_TYPE = "SET_TYPE";
export const SET_IS_STATUS = "SET_IS_STATUS";
export const SET_ACTIVE_PAGE_PROYEK = "SET_ACTIVE_PAGE_PROYEK";
export const SET_TOTAL_PAGE_PROYEK = "SET_TOTAL_PAGE_PROYEK";
export const SET_MAX_PAGE_PROYEK = "SET_MAX_PAGE_PROYEK";
export const SET_TASK_DIKUSI = "SET_TASK_DIKUSI";
export const SET_TOTAL_DATA_PROYEK = "SET_TOTAL_DATA_PROYEK";

export const setTotalData = (payload) => ({
  type: SET_TOTAL_DATA_PROYEK,
  payload,
});

export const setMaxPage = (payload) => ({
  type: SET_MAX_PAGE_PROYEK,
  payload,
});

export const setTotalPage = (payload) => ({
  type: SET_TOTAL_PAGE_PROYEK,
  payload,
});

export const setActivePage = (payload) => ({
  type: SET_ACTIVE_PAGE_PROYEK,
  payload,
});

export const setCategory = (payload) => ({
  type: SET_CATEGORY,
  payload,
});

export const setCity = (payload) => ({
  type: SET_CITY,
  payload,
});

export const setType = (payload) => ({
  type: SET_TYPE,
  payload,
});

export const setIsStatus = (payload) => ({
  type: SET_IS_STATUS,
  payload,
});

export const setActiveStep = (payload) => ({
  type: SET_ACTIVE_STEP_PROYEK,
  payload,
});

export const setAllCity = (payload) => ({
  type: SET_ALL_CITY,
  payload,
});

export const setDataDashboard = (payload) => ({
  type: SET_DASHBOARD_DATA_PROYEK,
  payload,
});

export const setAllDataProyek = (payload) => ({
  type: SET_ALL_DATA_PROYEK,
  payload,
});

export const setDetailDataProyek = (payload) => ({
  type: SET_DETAIL,
  payload,
});

export const setRequestDetailProyek = (payload) => ({
  type: SET_REQUEST,
  payload,
});

export const setDetailPengguna = (payload) => ({
  type: SET_DETAIL_PENGGUNA,
  payload,
});

export const setTabTask = (payload) => ({
  type: SET_TAB_TASK,
  payload,
});

export const setTabDiskusi = (payload) => ({
  type: SET_TASK_DIKUSI,
  payload,
});

export const getAllCity = () => async (dispatch) => {
  try {
    const { status, data } = await serviceGetCity({
      province_id: 0,
    });

    if (status === 200) {
      let count = 1;
      let allCity = [
        {
          kode: 0,
          label: "Semua Kab/Kota",
          value: 0,
        },
      ];
      data.data.forEach((item) => {
        allCity.push({
          ...item,
          label: capFirstLetter(item.nama),
          value: count,
        });
        count = count + 1;
      });
      dispatch(setAllCity(allCity));
    }
  } catch {}
};

export const getDashboardDataProyek = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetDashboardDataProyek();
    if (status === 200) {
      dispatch(setDataDashboard(data.data));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getFilterDataProyek = (keyword) => async (dispatch, getState) => {
  const { allCity, city, type, isStatus, activePage, maxPage, totalPage } =
    getState().dataProyek;

  dispatch(getAllCity());

  const { tableRows } = getState().setting;
  console.log(tableRows);

  try {
    dispatch(setLoading(true));
    const { data } = await serviceGetFilterDataProyek({
      page: activePage,
      limit: tableRows,
      category: "",
      city: allCity[city[0]]?.kode || "",
      type: type[0].length === 0 ? "" : type[0] > 0 ? type[0] : "",
      status:
        isStatus[0].length === 0 ? "" : isStatus[0] > 0 ? isStatus[0] : "",
      keyword: keyword,
    });
    dispatch(setAllDataProyek(data.data));
    dispatch(setTotalData(data.total));

    // Jika data 10 atau lebih & maksimal page tidak sama dengan totalPage, maka ada kemungkinan data di next page, maka total page bertambah
    if (data.data.length > tableRows && maxPage !== totalPage) {
      dispatch(setTotalPage(totalPage + 1));
    }
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const getDetailDataProyek = (id_proyek) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceProyekDetail({
      id_proyek,
    });

    if (status === 200) {
      dispatch(setDetailDataProyek(data.data));
      dispatch(setActiveStep("proyek"));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getRequestDataProyek = (id_proyek) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceRequestDetail({
      id_proyek,
    });
    dispatch(setRequestDetailProyek(data.data));
    if (status === 200) {
      dispatch(setActiveStep("info"));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const searchPenggunaFromProyek = (id_user) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetDetailDataPengguna({
      id_user,
    });

    dispatch(setDetailPengguna(data.data));
    if (status === 200) dispatch(setActiveStep("detailUser"));
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getTabTask = (id_proyek) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceTabTask({
      id_proyek,
    });
    if (status === 200) dispatch(setTabTask(data.data));
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const actionRequest =
  (id_proyek, action) => async (dispatch, getState) => {
    dispatch(setValueReason(""));
    const { valueReason } = getState().pageContainer;
    try {
      dispatch(setLoading(true));
      await serviceApproved({
        id_proyek,
        action,
        feature: "4",
        reason: valueReason,
        referenc_id: id_proyek,
      });
      dispatch(setValueReason(""));
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const getDiskusi = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceTabDiskusiTask({
      id_task: "1",
      page: "1",
    });
    if (status === 200) dispatch(setTabDiskusi(data.data));
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const proyekExport = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceExportProyek({});
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Proyek.xlsx");
      document.body.appendChild(link);
      link.click();
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};
