import {
  serviceDashboard,
  serviceLogin,
  serviceReadNotif,
} from "../../utils/api";

export const SET_LOADING = "SET_LOADING";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_LIST_NOTIF = "SET_LIST_NOTIF";
export const SET_POPUP_LOGOUT = "SET_POPUP_LOGOUT";
export const SET_MENU_ACCESS = "SET_MENU_ACCESS";
export const SET_VALUE_REASON = "SET_VALUE_REASON";
export const SET_ACTIVE_STEP_PAGE = "SET_ACTIVE_STEP_PAGE";
export const SET_ROUTE = "SET_ROUTE";
export const SET_POPUP_FAILED = "SET_POPUP_FAILED";

export const setPopupFailed = (payload) => ({
  type: SET_POPUP_FAILED,
  payload,
});

export const setRoute = (payload) => ({
  type: SET_ROUTE,
  payload,
});

export const setActiveStep = (payload) => ({
  type: SET_ACTIVE_STEP_PAGE,
  payload,
});

export const setMenuAccess = (payload) => ({
  type: SET_MENU_ACCESS,
  payload,
});

export const setPopupLogout = (payload) => ({
  type: SET_POPUP_LOGOUT,
  payload,
});

export const setListNotif = (payload) => ({
  type: SET_LIST_NOTIF,
  payload,
});

export const setMessage = (payload) => ({
  type: SET_MESSAGE,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

// state for reason penolakan
export const setValueReason = (payload) => ({
  type: SET_VALUE_REASON,
  payload,
});

export const actionLogin = (email, password) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceLogin({ email, password });
    if (status === 200) {
      if (data.status) {
        localStorage.setItem("dataUser", JSON.stringify(data));
        dispatch(setMenuAccess(data.data.menu_access?.map((item) => item.id)));
      } else {
        dispatch(setMessage(data.message));
      }
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getNotif = () => async (dispatch) => {
  try {
    const { status, data } = await serviceDashboard();
    if (status === 200) {
      dispatch(setListNotif(data.data.list_notif));
    } else if (status === 401) {
      dispatch(setPopupLogout(true));
    }
  } catch {}
};

export const setReadNotif = (id_notif) => async (dispatch) => {
  try {
    await serviceReadNotif({
      notif_id: id_notif,
      action: "1",
    });
    dispatch(getNotif());
  } catch {}
};
