import {
  serviceActionPengguna,
  serviceDashboardUsers,
  serviceGetAllUsers,
  serviceGetCity,
  serviceGetDetailDataPengguna,
  serviceGetSearchPengguna,
  servicePenggunaFilter,
  serviceUsersExport,
  serviceVerifiedPengguna,
} from "../../utils/api";
import { capFirstLetter } from "../../utils/helpers";
import { setLoading, setPopupFailed } from "./pageContainer";

export const ACTIVE_STATE = "ACTIVE_STATE";
export const DETAIL_DATA_PENGGUNA = "DETAIL_DATA_PENGGUNA";
export const STATUS_DETAIL = "STATUS_DETAIL";
export const DASHBOARD_USERS = "DASHBOARD_USERS";
export const ALL_USERS = "ALL_USERS";
export const FILTER = "FILTER";
export const SET_VALUE_GENDER = "SET_VALUE_GENDER";
export const SET_VALUE_AGE = "VALUE_AGE";
export const SET_VALUE_CITY = "VALUE_CITY";
export const SET_DROPDOWN_CITY = "SET_DROPDOWN_CITY";
export const SET_VALUE_STATUS = "SET_VALUE_STATUS";
export const SET_SEARCH = "SET_SEARCH";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_ACTIVE_PAGE_USER = "SET_ACTIVE_PAGE_USER";
export const SET_TOTAL_PAGE_USER = "SET_TOTAL_PAGE_USER";
export const SET_MAX_PAGE_USER = "SET_MAX_PAGE_USER";
export const SET_TOTAL_DATA_USER = "SET_TOTAL_DATA_USER";
export const SET_POPUP_VERIFIED = "SET_POPUP_VERIFIED";

export const setPopupVerified = (payload) => ({
  type: SET_POPUP_VERIFIED,
  payload,
});

export const setTotalData = (payload) => ({
  type: SET_TOTAL_DATA_USER,
  payload,
});

export const setMaxPage = (payload) => ({
  type: SET_MAX_PAGE_USER,
  payload,
});

export const setTotalPage = (payload) => ({
  type: SET_TOTAL_PAGE_USER,
  payload,
});

export const setActivePage = (payload) => ({
  type: SET_ACTIVE_PAGE_USER,
  payload,
});

export const setActiveStep = (payload) => ({
  type: ACTIVE_STATE,
  payload,
});

export const setDashboardUsers = (payload) => ({
  type: DASHBOARD_USERS,
  payload,
});

export const setAllUsers = (payload) => ({
  type: ALL_USERS,
  payload,
});

export const setDetailDataPengguna = (payload) => ({
  type: DETAIL_DATA_PENGGUNA,
  payload,
});

export const setStatusDetail = (payload) => ({
  type: STATUS_DETAIL,
  payload,
});

export const setFilter = (payload) => ({
  type: FILTER,
  payload,
});

export const setValueGender = (payload) => ({
  type: SET_VALUE_GENDER,
  payload,
});

export const setValueAge = (payload) => ({
  type: SET_VALUE_AGE,
  payload,
});

export const setValueCity = (payload) => ({
  type: SET_VALUE_CITY,
  payload,
});

export const setDropDownCity = (payload) => ({
  type: SET_DROPDOWN_CITY,
  payload,
});

export const setValueStatus = (payload) => ({
  type: SET_VALUE_STATUS,
  payload,
});

export const setSearch = (payload) => ({
  type: SET_SEARCH,
  payload,
});

export const setPagination = (payload) => ({
  type: SET_PAGINATION,
  payload,
});

export const getDashboardUsers = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceDashboardUsers({});
    if (status === 200) {
      dispatch(setDashboardUsers(data.data));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getAllUsers = () => async (dispatch, getState) => {
  try {
    const { status, data } = await serviceGetAllUsers({
      page: getState().dataPengguna.pagination.page,
      limit: 10,
    });

    if (status === 200) {
      dispatch(setAllUsers(data.data));
    }
  } catch {}
};

export const getDetailDataPengguna = (id_user) => async (dispatch) => {
  dispatch(setLoading(true));
  const { status, data } = await serviceGetDetailDataPengguna({
    id_user,
  });

  if (status === 200) {
    dispatch(setDetailDataPengguna(data.data));
    dispatch(setActiveStep("detail"));
  }
  dispatch(setLoading(false));
};

export const getCity = () => async (dispatch) => {
  try {
    const { status, data } = await serviceGetCity({
      province_id: 0,
    });

    if (status === 200) {
      let count = 1;
      let allCity = [
        {
          kode: 0,
          label: "Semua Kab/Kota",
          value: 0,
        },
      ];
      data.data.forEach((item) => {
        allCity.push({
          ...item,
          label: capFirstLetter(item.nama),
          value: count,
        });
        count = count + 1;
      });
      dispatch(setDropDownCity(allCity));
    }
  } catch {}
};

export const getPenggunaSearch = (keyword) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(setSearch(true));
  const { pagination } = getState().dataPengguna;
  const { status, data } = await serviceGetSearchPengguna({
    page: pagination.page,
    limit: "10",
    keyword,
  });
  if (status === 200) {
    dispatch(setAllUsers(data.data));
  }
  dispatch(setLoading(false));
};

export const getPenggunaFilter =
  (gender, age, isStatus, valueCity) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {
      dropDownCity,
      activePage,
      //  maxPage,
      // totalPage
    } = getState().dataPengguna;

    const { tableRows } = getState().setting;

    try {
      const { status, data } = await servicePenggunaFilter({
        page: activePage,
        limit: tableRows,
        gender:
          gender.length === 0 ? "" : gender[0] > 0 ? `${gender[0] - 1}` : "",
        age: age.length === 0 ? "" : age[0] > 0 ? `${age[0] - 1}` : "",
        city: dropDownCity[valueCity[0]]?.kode || "",
        status:
          isStatus.length === 0
            ? ""
            : isStatus[0] > 0
            ? `${isStatus[0] - 1}`
            : "",
      });

      if (dropDownCity.length === 0) dispatch(getCity());

      if (status === 200) {
        dispatch(setAllUsers(data.data));
        dispatch(setTotalData(parseInt(data.total_data)));

        // Jika data 10 atau lebih & maksimal page tidak sama dengan totalPage, maka ada kemungkinan data di next page, maka total page bertambah
        // if (data.data.length > 10 && maxPage !== totalPage) {
        //   dispatch(setTotalPage(totalPage + 1));
        // }
        dispatch(setTotalPage(Math.ceil(parseInt(data.total_data) / 10)));
      }
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const executeUser = () => async (dispatch, getState) => {
  const { id_user, is_status } = getState().dataPengguna.userDetail;
  try {
    dispatch(setLoading(true));
    const { status } = await serviceActionPengguna({
      user_id: id_user,
      action: is_status === "2" ? 1 : 2,
    });
    if (status === 200) {
      dispatch(setActiveStep("all"));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const executeVerifiedUser = () => async (dispatch, getState) => {
  const { id_user, is_verified } = getState().dataPengguna.userDetail;
  try {
    dispatch(setLoading(true));
    const { status } = await serviceVerifiedPengguna({
      user_id: id_user,
      action: is_verified === "0" ? 1 : 0,
    });
    if (status === 200) {
      dispatch(setPopupVerified(is_verified === "0" ? 1 : 2));
      dispatch(getDetailDataPengguna(id_user));
    } else {
      dispatch(setPopupFailed(true));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const userExport = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceUsersExport();
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Anggota.xlsx");
      document.body.appendChild(link);
      link.click();
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};
