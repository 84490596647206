import {
  SET_DASHBOARD_DATA_PROYEK,
  SET_ALL_DATA_PROYEK,
  SET_DETAIL,
  SET_ACTIVE_STEP_PROYEK,
  SET_REQUEST,
  SET_DETAIL_PENGGUNA,
  SET_TAB_TASK,
  SET_ALL_CITY,
  SET_CATEGORY,
  SET_CITY,
  SET_TYPE,
  SET_IS_STATUS,
  SET_ACTIVE_PAGE_PROYEK,
  SET_TOTAL_PAGE_PROYEK,
  SET_MAX_PAGE_PROYEK,
  SET_TASK_DIKUSI,
  SET_TOTAL_DATA_PROYEK,
} from "../actions/dataProyek";

const initialState = {
  activeStep: "page",
  dashboardProyek: [],
  allData: [],
  detailProyek: {},
  requestProyek: {},
  userDetail: {},
  tabTask: [],
  allCity: [],
  category: [0],
  city: [0],
  type: [0],
  isStatus: [0],
  activePage: 1,
  totalPage: 1,
  maxPage: 0,
  tabDiskusi: [],
  totalData: 0,
};

const dataProyek = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOTAL_DATA_PROYEK:
      return {
        ...state,
        totalData: action.payload,
      };
    case SET_MAX_PAGE_PROYEK:
      return {
        ...state,
        maxPage: action.payload,
      };
    case SET_TOTAL_PAGE_PROYEK:
      return {
        ...state,
        totalPage: action.payload,
      };
    case SET_ACTIVE_PAGE_PROYEK:
      return {
        ...state,
        activePage: action.payload,
      };
    case SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case SET_CITY:
      return {
        ...state,
        city: action.payload,
      };
    case SET_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case SET_IS_STATUS:
      return {
        ...state,
        isStatus: action.payload,
      };

    case SET_ACTIVE_STEP_PROYEK:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_DASHBOARD_DATA_PROYEK:
      return {
        ...state,
        dashboardProyek: action.payload,
      };
    case SET_ALL_DATA_PROYEK:
      return {
        ...state,
        allData: action.payload,
      };
    case SET_DETAIL:
      return {
        ...state,
        detailProyek: action.payload,
      };
    case SET_REQUEST:
      return {
        ...state,
        requestProyek: action.payload,
      };
    case SET_DETAIL_PENGGUNA:
      return {
        ...state,
        userDetail: action.payload,
      };
    case SET_TAB_TASK:
      return {
        ...state,
        tabTask: action.payload,
      };
    case SET_ALL_CITY:
      return {
        ...state,
        allCity: action.payload,
      };
    case SET_TASK_DIKUSI:
      return {
        ...state,
        tabDiskusi: action.payload,
      };
    default:
      return state;
  }
};

export default dataProyek;
