import {
  serviceActionSettingUser,
  serviceAddUser,
  serviceGetUser,
  serviceSaveSettingWeb,
  serviceSettingWeb,
} from "../../utils/api";
import { setLoading } from "./pageContainer";

export const SET_DATA_WEB = "SET_DATA_WEB";
export const SET_POPUP_SAVE = "SET_POPUP_SAVE";
export const SET_DATA_USER = "SET_DATA_USER";
export const SET_TABLE_ROWS = "SET_TABLE_ROWS";

export const setTableRows = (payload) => ({
  type: SET_TABLE_ROWS,
  payload,
});

export const setDataUser = (payload) => ({
  type: SET_DATA_USER,
  payload,
});

export const setPopupSave = (payload) => ({
  type: SET_POPUP_SAVE,
  payload,
});

export const setDataWeb = (payload) => ({
  type: SET_DATA_WEB,
  payload,
});

export const getSettingWeb = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceSettingWeb();
    if (status === 200) {
      dispatch(setDataWeb(data.data));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const saveSettingWeb = (dataParam, index, image) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceSaveSettingWeb({
      ...dataParam[index],
      image: image[index].image === null ? null : image[index].image.slice(22),
    });
    if (status === 200) {
      dispatch(setPopupSave(true));
      dispatch(getSettingWeb());
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getDataUser = (search) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetUser({
      keyword: search !== undefined ? search : "",
    });
    if (status === 200) {
      dispatch(setDataUser(data.data));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getActionUser = (id_user, action) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceActionSettingUser({
      id_user,
      action,
    });
    if (status === 200) {
      dispatch(getDataUser(""));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getSaveUser =
  (name, email, password, menuAccess) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { status } = await serviceAddUser({
        full_name: name,
        email: email,
        password: password,
        type: "1",
        akses: menuAccess
          .filter((el) => el.select === true)
          .map((item) => (item.select === true ? { id_menu: item.id } : {})),
      });
      if (status === 200) {
        dispatch(setPopupSave(true));
        dispatch(getSettingWeb());
        dispatch(getDataUser());
      }
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };
