/* eslint-disable react-hooks/exhaustive-deps */
// Page Semua Proyek
// --------------------------------------------------------

import React, { useEffect, useState } from "react";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";
import Table from "../../../components/Table";
import PageNextPrev from "../../../components/PageNextPrev";
import Button from "../../../components/Button";
import InputAdornment from "@mui/material/InputAdornment";
import InputText from "../../../components/InputText";
import Colors from "../../../utils/helpers/colors";
import Menu from "@mui/material/Menu";
import DropDown from "../../../components/DropDown";

// Asset
import eye from "../../../assets/icon/Eye.svg";
import iconSlider from "../../../assets/icon/icon-slider.png";
import iconSearch from "../../../assets/icon/icon-search.png";
import iconXls from "../../../assets/icon/icon-xls-white.png";
import profile from "../../../assets/img/profile-post.png";

// Dummy Data
import { ReactComponent as IconGroup } from "../../../assets/icon/icon_group.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailDataProyek,
  getFilterDataProyek,
  getRequestDataProyek,
  setActiveStep,
  setIsStatus,
  setCity,
  setType,
  setActivePage,
  proyekExport,
} from "../../../store/actions/dataProyek";
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { setTableRows } from "../../../store/actions/setting";

const SemuaProyek = ({ setHistory }) => {
  const [menuFilter, setMenuFilter] = useState(null);

  // state for filter
  const [keyword, setKeyword] = useState("");
  const [pages, setPages] = useState(1);

  const dispatch = useDispatch();
  const {
    allData,
    allCity,
    city,
    type,
    isStatus,
    activePage,
    totalPage,
    totalData,
  } = useSelector((state) => state.dataProyek);

  const { tableRows } = useSelector((state) => state.setting);

  const dataHeader = [
    {
      title: "No",
      key: "id_proyek",
      width: 20,
      center: true,
      render: (_, index) => <p>{(activePage - 1) * tableRows + (index + 1)}</p>,
    },
    {
      title: "Profile",
      width: 50,
      center: true,
      render: (rowData) => (
        <img
          alt="profile-chat"
          src={profile}
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "100%",
          }}
        />
      ),
    },
    {
      title: "Nama Proyek",
      width: 0,
      key: "nama_proyek",
    },
    {
      title: "Jenis Proyek",
      key: "privacy",
      center: true,
      render: (rowData) => (
        <span>{rowData.privacy === "1" ? "Private" : "Public"}</span>
      ),
    },
    {
      title: "Pembuat Proyek",
      key: "full_name",
    },
    {
      title: "Member",
      width: 70,
      center: true,
      render: (rowData) => (
        <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
          <IconGroup />
          <p>{rowData.total_member}</p>
        </div>
      ),
    },
    {
      title: "Tanggal Dibuat",
      key: "create_at",
      center: true,
      render: (rowData) => (
        <p>{moment(rowData.create_at).format("DD MMMM YYYY")}</p>
      ),
    },
    {
      title: "Status",
      width: 140,
      center: true,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: `1px solid ${
                rowData.id_status === "1"
                  ? "#115ABE"
                  : rowData.id_status === "2"
                  ? "#039C40"
                  : rowData.id_status === "3"
                  ? "#C80707"
                  : ""
              }`,
              backgroundColor:
                rowData.id_status === "1"
                  ? "rgba(181, 190, 233, 0.29)"
                  : rowData.id_status === "2"
                  ? "#AEF8AC"
                  : rowData.id_status === "3"
                  ? "rgba(245, 150, 150, 0.2)"
                  : "",
              borderRadius: "30px",
              padding: "4px 20px",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "16px",
              color:
                rowData.id_status === "1"
                  ? "#115ABE"
                  : rowData.id_status === "2"
                  ? "#039C40"
                  : rowData.id_status === "3"
                  ? "#C80707"
                  : "",
            }}
          >
            {rowData.status}
          </div>
        </div>
      ),
    },
    {
      title: "Aksi",
      width: 100,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            borderRadius="5px"
            padding="0px 7px 0px 9px"
            onClick={() => {
              // dispatch(getDetailDataProyek(rowData.id_proyek));
              actionHandler(rowData.id_proyek, rowData);
            }}
          >
            Detail
            <img src={eye} alt="eye" />
          </Button>
        </div>
      ),
      center: true,
    },
  ];

  useEffect(() => {
    dispatch(getFilterDataProyek(keyword));
  }, [dispatch]);

  const actionHandler = (id_proyek, status) => {
    if (status === "2" || status === "4") {
      dispatch(getDetailDataProyek(id_proyek));
    } else {
      dispatch(getRequestDataProyek(id_proyek));
    }
    setHistory("all");
  };

  const searchHandler = (event) => {
    if (event.key === "Enter") {
      dispatch(getFilterDataProyek(keyword, pages));
    }
  };

  return (
    <Container>
      {/* Title */}
      <Title
        title="Semua Proyek"
        withBack
        onBack={() => dispatch(setActiveStep("page"))}
      >
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          {/* Pencarian */}
          <InputText
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={searchHandler}
            placeholder="pencarian"
            noPadding
            width="175px"
            borderRadius="5px"
            borderColor="#115AAA"
            iconAdornment={
              <InputAdornment
                position="start"
                className="inputAdorment"
                sx={{
                  zIndex: 200,
                  height: "40px",
                  justifyContent: "center",
                  width: "67px",
                  maxHeight: "56px",
                  borderRadius: "5px 0px 0px 5px",
                  backgroundColor: Colors.primary.hard,
                  ".MuiTypography-root": {
                    color: "white",
                    fontSize: "0.938rem",
                    fontWeight: "400",
                    lineHeight: "1.124rem",
                  },
                }}
              >
                <img
                  src={iconSearch}
                  alt="icon-search"
                  width={24}
                  height={24}
                />
              </InputAdornment>
            }
          />
          {/* Tombol Export */}
          <div style={{ display: "flex", gap: "2px" }}>
            <Button
              onClick={() => dispatch(proyekExport())}
              padding="8px"
              width="fit-content"
              borderRadius="5px"
            >
              Export
              <img
                src={iconXls}
                style={{ marginLeft: "5px" }}
                alt="icon-slider"
                width={24}
                height={24}
              />
            </Button>
          </div>

          {/* Tombol Filter */}
          <Button
            padding="8px"
            width="fit-content"
            onClick={(event) => {
              setMenuFilter(event.currentTarget);
            }}
          >
            <img src={iconSlider} alt="icon-slider" width={24} height={24} />
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={menuFilter}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              top: "40px",
              left: "10px",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "15px",
              lineHeight: "18px",
              color: "#000000",
              "& .MuiPaper-root": {
                borderRadius: "10px",
              },
              "& .MuiList-root": {
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              },
            }}
            open={Boolean(menuFilter)}
            onClose={() => setMenuFilter(null)}
          >
            {/* <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Pilih Kategori :</p>
              <DropDown
                dropdownValue={category}
                handleChange={(e) => {
                  dispatch(setCategory([e.target.value]));
                  dispatch(getFilterDataProyek(keyword, pages));
                  setPages(1);
                }}
                placeHolder="Pilih Kategori"
                listDropDown={[
                  {
                    label: "Pilih Semua Kategori",
                    value: 0,
                  },
                  {
                    label: "Belum Mulai",
                    value: 1,
                  },
                  { label: "Batal", value: 2 },
                  { label: "Progress", value: 3 },
                  { label: "Selesai", value: 4 },
                ]}
              />
            </div> */}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Pilih Kota :</p>
              <DropDown
                dropdownValue={city}
                listDropDown={allCity}
                placeHolder="Pilih Kota"
                handleChange={(e) => {
                  dispatch(setCity([e.target.value]));
                  dispatch(getFilterDataProyek(keyword, pages));
                  setPages(1);
                }}
                isSearch
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Pilih Tipe :</p>
              <DropDown
                dropdownValue={type}
                handleChange={(e) => {
                  dispatch(setType([e.target.value]));
                  dispatch(getFilterDataProyek(keyword, pages));
                  setPages(1);
                }}
                placeHolder="Pilih Tipe"
                listDropDown={[
                  {
                    label: "Semua Tipe",
                    value: "0",
                  },
                  { label: "Private", value: "1" },
                  { label: "Publik", value: "2" },
                ]}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Pilih Status :</p>
              <DropDown
                dropdownValue={isStatus}
                handleChange={(e) => {
                  dispatch(setIsStatus([e.target.value]));
                  dispatch(getFilterDataProyek(keyword, pages));
                  setPages(1);
                }}
                placeHolder="Pilih Status"
                listDropDown={[
                  {
                    label: "Pilih Semua Status",
                    value: 0,
                  },
                  {
                    label: "Menunggu",
                    value: 1,
                  },
                  { label: "Disetujui", value: 2 },
                  { label: "Ditolak", value: 3 },
                  { label: "Dinonaktifkan", value: 4 },
                ]}
              />
            </div>
          </Menu>
        </div>
      </Title>

      {/* Table */}
      <RowWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <Table headerContent={dataHeader} dataContent={allData} />

          <div style={{ width: "100px", alignSelf: "flex-end" }}>
            <FormControl fullWidth>
              <InputLabel id="Select Rows Data">Rows</InputLabel>
              <Select
                labelId="Select Rows Data"
                id="rowsSelect"
                label="Rows"
                onChange={(e) => {
                  dispatch(setTableRows(e.target.value));
                  dispatch(getFilterDataProyek(keyword));
                }}
                value={tableRows}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>

          <PageNextPrev
            totalData={totalData}
            totalPage={totalPage}
            activePage={activePage}
            onChange={(value) => {
              dispatch(
                setActivePage(
                  value === "LEFT" ? activePage - 1 : activePage + 1
                )
              );
              dispatch(getFilterDataProyek(keyword));
            }}
          />
        </div>
      </RowWrapper>
    </Container>
  );
};

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const RowWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
}));

export default SemuaProyek;
