// Page Data Postingan
// --------------------------------------------------------

import React, { useState } from "react";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";

// Asset
import Button from "../../../components/Button";
import { ReactComponent as BlueThumb } from "../../../assets/icon/icon-blue-thumb.svg";
import { ReactComponent as ShareGray } from "../../../assets/icon/icon-share-gray.svg";
import { ReactComponent as IconComment } from "../../../assets/icon/icon-comment.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  actionPostingan,
  setActiveStep,
  setPopupStatus,
  setValueReason,
} from "../../../store/actions/dataPostingan";
import PopUp from "../../../components/PopUp";
import iconEdit from "../../../assets/icon/icon-edit.png";
import PopUpPenolakan from "../../../components/PopUpPenolakan";

const DetailPostingan = () => {
  const dispatch = useDispatch();

  const { post_page, image_list } = useSelector(
    (state) => state.dataPostingan.detailPostingan
  );

  const { popupStatus, valueReason } = useSelector(
    (state) => state.dataPostingan
  );

  const [confDecline, setConfDecline] = useState(false);

  return (
    <Container>
      {/* Title */}
      <Title
        title="Postingan"
        withBack
        onBack={() => dispatch(setActiveStep("page"))}
      >
        {post_page?.id_status === "1" && (
          <Button
            color="#E20000"
            onClick={() => {
              setConfDecline(true);
            }}
          >
            Nonaktifkan Postingan
          </Button>
        )}
      </Title>

      {/* Image & Post */}
      <RowWrapper>
        <PostPageWrapper>
          <div
            style={{
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "23px",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "15px",
              }}
            >
              <img
                alt="profile-img"
                src={post_page?.foto_profile}
                height="52px"
                width="52px"
                style={{
                  height: "52px",
                  width: "52px",
                  borderRadius: "100%",
                }}
              />
              {post_page?.full_name}
            </div>
            <div
              style={{
                padding: "10px 10px 0px 10px",
              }}
            >
              <p
                style={{
                  whiteSpace: "pre-wrap",
                  maxWidth: "fit-content",
                  height: "fit-content",
                }}
              >
                {post_page?.post_text}
              </p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button
              full
              borderRadius="50px"
              color="#FAFAFA"
              border="1px solid #EDEDED"
            >
              <BlueThumb />
              <p style={{ color: "black" }}>{post_page?.total_like}</p>
            </Button>
            <Button
              full
              borderRadius="50px"
              color="#FAFAFA"
              border="1px solid #EDEDED"
            >
              <IconComment />
              <p style={{ color: "black" }}>{post_page?.total_comment}</p>
            </Button>
            <Button
              full
              borderRadius="50px"
              color="#FAFAFA"
              border="1px solid #EDEDED"
              image_list
            >
              <ShareGray />
              <p style={{ color: "black" }}>{post_page?.total_share}</p>
            </Button>
          </div>
        </PostPageWrapper>
      </RowWrapper>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {image_list?.map((item) => (
          <ContentWrapper>
            <img
              alt="img-post"
              src={item.file_name}
              style={{ maxWidth: "100%", objectFit: "cover", height: "100%" }}
            />
          </ContentWrapper>
        ))}
        <ContentWrapper>
          {/* <img
            alt="img-post"
            src={imagePost}
            style={{ maxWidth: "100%", objectFit: "cover", height: "100%" }}
          />
        </ContentWrapper>
        <ContentWrapper>
          <img
            alt="img-post"
            src={imagePost}
            style={{ maxWidth: "100%", objectFit: "cover", height: "100%" }}
          />
        </ContentWrapper>
        <ContentWrapper>
          <img
            alt="img-post"
            src={imagePost}
            style={{ maxWidth: "100%", objectFit: "cover", height: "100%" }}
          /> */}
        </ContentWrapper>
      </div>

      <PopUpPenolakan
        open={confDecline}
        type="choice"
        width="350px"
        valueInput={valueReason}
        onChange={(e) => dispatch(setValueReason(e.target.value))}
        onClickAction={() => {
          setConfDecline(false);
          dispatch(actionPostingan(post_page.post_id, "2"));
        }}
        onClose={() => {
          setConfDecline(false);
        }}
        title="Konfirmasi"
        info="Anda yakin ingin menonaktifkan postingan?"
      />

      <PopUp
        open={popupStatus}
        imgSrc={iconEdit}
        onClose={() => {
          dispatch(setPopupStatus(false));
          dispatch(setActiveStep("page"));
        }}
        buttonWord="Ok"
        onClickAction={() => {
          dispatch(setPopupStatus(false));
          dispatch(setActiveStep("page"));
        }}
        title="Postingan Berhasil Dinonaktifkan"
      />
    </Container>
  );
};

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const RowWrapper = styled("div")(() => ({
  display: "flex",
  gap: "20px",
}));

const PostPageWrapper = styled("div")(() => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
  minHeight: "267px",
  gap: "15px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  flexGrow: "1",
  flexBasis: "0",
}));

const ContentWrapper = styled("div")(() => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
  height: "267px",
  gap: "15px",
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
  width: "100%",
  flexGrow: "1",
  flexBasis: "0",
}));

export default DetailPostingan;
