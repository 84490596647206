// Pagination Component
// --------------------------------------------------------

import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Container = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const Info = styled("p")(() => ({
  fontSize: "15px",
  color: "black",
  margin: "0px",
}));

const PageNextPrev = ({ onChange, totalPage, activePage, totalData }) => {
  return (
    <Container
      style={{ justifyContent: totalData !== null ? "space-between" : "end" }}
    >
      {totalData !== null && (
        <Info>
          Total Data : <b>{totalData}</b>
        </Info>
      )}
      <div>
        <IconButton
          color="primary"
          component="label"
          disabled={activePage === 1}
          onClick={() => onChange("LEFT")}
        >
          <ChevronLeftIcon />
        </IconButton>

        <IconButton
          color="primary"
          component="label"
          // disabled={totalPage === 1 || activePage === totalPage}
          onClick={() => onChange("RIGHT")}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>
    </Container>
  );
};

PageNextPrev.propTypes = {
  onChange: PropTypes.func,
  totalPage: PropTypes.number,
  activePage: PropTypes.number,
  totalData: PropTypes.any,
};

PageNextPrev.defaultProps = {
  onChange: () => {},
  totalPage: 1,
  activePage: 1,
  totalData: null,
};

export default PageNextPrev;
