/* eslint-disable react-hooks/exhaustive-deps */
// Page Semua Pengguna
// --------------------------------------------------------

import {
  FormControl,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import DropDown from "../../../components/DropDown";
import InputText from "../../../components/InputText";
import PageNextPrev from "../../../components/PageNextPrev";
import Table from "../../../components/Table";
import Title from "../../../components/Title";
import Colors from "../../../utils/helpers/colors";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  getGetListPostingan,
  setActivePage,
  setActiveStep,
  setFitur,
  setIsStatus,
  setKeyword,
  setMaxPage,
  setPrivacy,
} from "../../../store/actions/dataPostingan";

// Asset
import iconSlider from "../../../assets/icon/icon-slider.png";
import iconSearch from "../../../assets/icon/icon-search.png";
import { setTableRows } from "../../../store/actions/setting";

const SemuaPostingan = ({ setHistory }) => {
  const [menuFilter, setMenuFilter] = useState(null);

  // state global
  const dispatch = useDispatch();

  // state global
  const {
    dataHeader,
    listPost,
    totalPage,
    activePage,
    isStatus,
    fitur,
    privacy,
    keyword,
    totalData,
  } = useSelector((state) => state.dataPostingan, shallowEqual);
  // state for filter

  const searchHandler = (event) => {
    if (event.key === "Enter") {
      handleChangeFilter();
    }
  };

  const handleChangeFilter = () => {
    dispatch(getGetListPostingan(true));
  };

  // Jika page yang aktif sama dengan total page, maka simpan maksimal page agar tidak menambah total page
  useEffect(() => {
    if (activePage === totalPage && totalData < "11") {
      dispatch(setMaxPage(totalPage));
    }
  }, [activePage]);

  const { tableRows } = useSelector((state) => state.setting);

  return (
    <Container>
      <Title
        title="Semua Postingan"
        withBack
        onBack={() => dispatch(setActiveStep("page"))}
      >
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          {/* Pencarian */}
          <InputText
            type="text"
            value={keyword}
            onChange={(e) => dispatch(setKeyword(e.target.value))}
            placeholder="pencarian"
            onKeyDown={searchHandler}
            noPadding
            width="175px"
            borderRadius="5px"
            borderColor="#115AAA"
            iconAdornment={
              <InputAdornment
                position="start"
                className="inputAdorment"
                sx={{
                  zIndex: 200,
                  height: "40px",
                  justifyContent: "center",
                  width: "67px",
                  maxHeight: "56px",
                  borderRadius: "5px 0px 0px 5px",
                  backgroundColor: Colors.primary.hard,
                  ".MuiTypography-root": {
                    color: "white",
                    fontSize: "0.938rem",
                    fontWeight: "400",
                    lineHeight: "1.124rem",
                  },
                }}
              >
                <img
                  src={iconSearch}
                  alt="icon-search"
                  width={24}
                  height={24}
                />
              </InputAdornment>
            }
          />

          {/* Tombol Filter */}
          <Button
            padding="8px"
            width="fit-content"
            onClick={(event) => {
              setMenuFilter(event.currentTarget);
            }}
          >
            <img src={iconSlider} alt="icon-slider" width={24} height={24} />
          </Button>
          {/* Filter */}
          <Menu
            id="menu-appbar"
            anchorEl={menuFilter}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              top: "40px",
              left: "10px",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "15px",
              lineHeight: "18px",
              color: "#000000",
              "& .MuiPaper-root": {
                borderRadius: "10px",
              },
              "& .MuiList-root": {
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              },
            }}
            open={Boolean(menuFilter)}
            onClose={() => setMenuFilter(null)}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Status :</p>
              <DropDown
                dropdownValue={isStatus}
                listDropDown={[
                  {
                    label: "Semua Status",
                    value: "0",
                  },
                  {
                    label: "Aktif",
                    value: "1",
                  },
                  { label: "Tidak Aktif", value: "2" },
                ]}
                placeHolder="Pilih Status Akun"
                handleChange={(e) => {
                  dispatch(setIsStatus([e.target.value]));
                  handleChangeFilter();
                }}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Fitur :</p>
              <DropDown
                dropdownValue={fitur}
                listDropDown={[
                  {
                    label: "Semua Fitur",
                    value: "0",
                  },
                  {
                    label: "Social",
                    value: "1",
                  },
                  { label: "Grup", value: "2" },
                ]}
                placeHolder="Pilih Jenis Kelamin"
                handleChange={(e, value) => {
                  dispatch(setFitur([e.target.value]));
                  handleChangeFilter();
                }}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Privasi :</p>
              <DropDown
                dropdownValue={privacy}
                listDropDown={[
                  {
                    label: "Semua Jenis",
                    value: "0",
                  },
                  {
                    label: "Publik",
                    value: "1",
                  },
                  { label: "Privasi", value: "2" },
                ]}
                placeHolder="Pilih Jenis"
                handleChange={(e) => {
                  dispatch(setPrivacy([e.target.value]));
                  handleChangeFilter();
                }}
              />
            </div>
          </Menu>
        </div>
      </Title>

      <Table dataContent={listPost} headerContent={dataHeader} />

      <div style={{ width: "100px", alignSelf: "flex-end" }}>
        <FormControl fullWidth>
          <InputLabel id="Select Rows Data">Rows</InputLabel>
          <Select
            labelId="Select Rows Data"
            id="rowsSelect"
            label="Rows"
            onChange={(e) => {
              dispatch(setTableRows(e.target.value));
              dispatch(getGetListPostingan(true));
            }}
            value={tableRows}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </div>

      <PageNextPrev
        totalPage={totalPage}
        activePage={activePage}
        totalData={totalData}
        onChange={(value) => {
          dispatch(
            setActivePage(value === "LEFT" ? activePage - 1 : activePage + 1)
          );
          dispatch(getGetListPostingan(true));
        }}
      />
    </Container>
  );
};

// Style

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default SemuaPostingan;
