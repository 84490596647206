/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import PageContainer from "../../../components/PageContainer";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Table from "../../../components/Table";
import PageNextPrev from "../../../components/PageNextPrev";
import Button from "../../../components/Button";
import InputText from "../../../components/InputText";
import { Avatar } from "@mui/material";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  actionBanner,
  addDataBanner,
  getDataBanner,
  setActivePage,
  setMaxPage,
  setPopupAdd,
} from "../../../store/actions/banner";
import iconCamera from "../../../assets/icon/icon-camera.svg";
import Compressor from "compressorjs";

const initialBanner = {
  testType: "global",
  message: "",
  image: null,
  originalSize: null,
  reducedSize: null,
  files: [],
  image2: null,
  image2ReducedSize: null,
};

const Banner = () => {
  const dispatch = useDispatch();
  const dataHeaderAll = [
    {
      title: "No",
      key: "no",
      width: 30,
      render: (_, index) => <p>{(activePage - 1) * 10 + (index + 1)}</p>,
    },
    {
      title: "Gambar Banner",
      key: "type",
      render: (rowData) => (
        <img
          src={rowData.img_banner}
          alt=""
          width={100}
          height={100}
          style={{ objectFit: "contain" }}
        />
      ),
    },
    {
      title: "Link Banner",
      key: "link",
    },
    {
      title: "Aksi",
      width: 100,
      render: (row) => (
        <Button
          variant="contained"
          borderRadius="5px"
          padding="0 9px"
          style={{
            backgroundColor: "#FB7171",
          }}
          onClick={() => {
            dispatch(actionBanner(row.id));
          }}
        >
          Hapus
        </Button>
      ),
      center: true,
    },
  ];

  const { dataBanner, popupAdd, activePage, totalPage, totalData } =
    useSelector((state) => state.banner, shallowEqual);

  const convertToBase64 = (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const inputBanner = useRef(null);
  const [link, setLink] = useState("");
  const [banner, setBanner] = useState(initialBanner);

  const onClickBanner = () => {
    inputBanner.current.click();
  };

  const onChangeBanner = async (event) => {
    const originalFile = event.target.files[0];
    const regex = /^.*base64,/;

    if (originalFile.type.split("/")[0] !== "image") {
      setBanner({
        ...banner,
        message: `The file selected was a not an image type and will not be reduced.`,
      });
    } else {
      new Promise((resolve, reject) => {
        new Compressor(originalFile, {
          quality: 0.9,
          maxWidth: 1200,
          maxHeight: 675,
          checkOrientation: false,
          success: resolve,
          error: reject,
        });
      })
        .then((blob) => convertToBase64(blob))
        .then((base64Image) => {
          const decoded = atob(base64Image.replace(regex, ""));
          setBanner({
            image: base64Image,
            originalSize: originalFile.size,
            reducedSize: decoded.length,
          });
        });
    }
  };

  useEffect(() => {
    dispatch(getDataBanner());
  }, [dispatch]);

  useEffect(() => {
    if (!popupAdd) {
      setLink("");
      setBanner(initialBanner);
    }
  }, [popupAdd]);

  // Jika page yang aktif sama dengan total page, maka simpan maksimal page agar tidak menambah total page
  useEffect(() => {
    if (activePage === totalPage) {
      dispatch(setMaxPage(totalPage));
    }
  }, [activePage]);

  return (
    <PageContainer>
      <Container>
        {/* Title */}
        <Title title="Banner">
          <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
            <Button
              padding="12px"
              width="110px"
              borderRadius="5px"
              onClick={() => {
                dispatch(setPopupAdd(true));
              }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "18px",
                  color: "white",
                }}
              >
                Tambah
              </p>
            </Button>
          </div>
        </Title>

        {/* Table */}
        <RowWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <Table headerContent={dataHeaderAll} dataContent={dataBanner} />
            <PageNextPrev
              totalData={totalData}
              totalPage={totalPage}
              activePage={activePage}
              onChange={(value) => {
                dispatch(
                  setActivePage(
                    value === "LEFT" ? activePage - 1 : activePage + 1
                  )
                );
                dispatch(getDataBanner());
              }}
            />
          </div>
        </RowWrapper>

        <PopUpDialog
          open={popupAdd}
          onClose={() => dispatch(setPopupAdd(false))}
          aria-labelledby="popup-dialog-title"
        >
          <DialogContentCustom>
            <InputWrap>
              <InpuTitle>Gambar Banner</InpuTitle>
              <Wrapper>
                <TitleDesc>Banner Loker</TitleDesc>
                <div
                  style={{
                    border: `1px dashed ${
                      banner.image === null ? "red" : "#E0E0E0"
                    }`,
                    aspectRatio: "10 / 2",
                    padding: "10px",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  {banner.image ? (
                    <Button sx={{ p: 0 }} onClick={onClickBanner}>
                      <Avatar
                        sx={{
                          height: "100%",
                          width: "100%",
                          aspectRatio: "10 / 2",
                        }}
                        style={{
                          borderRadius: "0px",
                        }}
                        alt="avatar2"
                        className="avatar"
                        src={banner.image}
                      />
                    </Button>
                  ) : (
                    <Button
                      variant="text"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#F0F0F0",
                        height: "100%",
                        width: "100%",
                      }}
                      onClick={onClickBanner}
                    >
                      <div
                        style={{
                          display: "flex",
                          height: "100%",
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          gap: "5px",
                        }}
                      >
                        <img
                          alt="icon-camera"
                          src={iconCamera}
                          height="40"
                          width="40"
                        />
                        <p
                          style={{
                            width: "100%",
                            fontStyle: "italic",
                            fontWeight: " 300",
                            fontSize: " 10px",
                            lineHeight: "10px",
                            color: "rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          Gambar 1200 x 675, max : 2MB
                        </p>
                      </div>
                    </Button>
                  )}
                </div>
                <input
                  type="file"
                  ref={inputBanner}
                  accept=".jpg, .png"
                  style={{ visibility: "hidden", display: "none" }}
                  onChange={onChangeBanner}
                />
              </Wrapper>
            </InputWrap>
            <InputWrap>
              <InpuTitle>Link Banner</InpuTitle>
              <InputText
                placeholder="Masukan Judul Banner"
                borderRadius="0px"
                borderColor="#E4E4E4"
                backgroundColor="rgba(217, 217, 217, 0.1)"
                placeholderStyle={{ fontSize: "12px", lineHeight: "15px" }}
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </InputWrap>
          </DialogContentCustom>

          <DialogActionsCustom>
            <Button
              variant="contained"
              width="217px"
              borderRadius="5px"
              padding="13px"
              onClick={() => {
                dispatch(addDataBanner(banner.image.slice(22), link));
              }}
            >
              <ButtonText>Simpan Banner</ButtonText>
            </Button>
          </DialogActionsCustom>
        </PopUpDialog>
      </Container>
    </PageContainer>
  );
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const RowWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
}));

const DialogActionsCustom = styled(DialogActions)(() => ({
  padding: "0px",
  display: "flex",
  justifyContent: "end",
}));

const DialogContentCustom = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "20px",
  backgroundColor: "white",
  borderRadius: "10px",
}));

const InputWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
}));

const ButtonText = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "0.01em",
  color: "#FFFFFF",
}));

const InpuTitle = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  letterSpacing: "0.01em",
  fontSize: "12px",
  lineHeight: "15px",
  color: "#000000",
}));

const PopUpDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "674px",
    borderRadius: "10px",
    height: "fit-content",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    backgroundColor: "#F4F7FB",
  },
}));

const TitleDesc = styled("span")`
  font-weight: 600;
  width: fit-content;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  border-bottom: 1px solid #d4e3f6;
  margin-bottom: 5px;
`;

export default Banner;
