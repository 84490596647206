import {
  SET_DASHBOARD_GROUP,
  SET_ALL_GROUP,
  SET_REQUEST_DETAIL_GRUP,
  SET_ACTIVE_STEP_GROUP,
  SET_DETAIL_GRUP,
  SET_HISTORY,
  SET_PRIVACY,
  SET_CATEGORY,
  SET_IS_STATUS,
  SET_ACTIVE_PAGE_GRUP,
  SET_TOTAL_PAGE_GRUP,
  SET_MAX_PAGE_GRUP,
  SET_DETAIL_PENGGUNA,
  SET_GROUP_ID,
  SET_SELECT_GRUP,
  SET_TOTAL_DATA_GROUP,
  SET_POPUP_SETUJU,
  SET_POPUP_TOLAK,
  SET_POPUP_NONAKTIF,
  SET_POPUP_AKTIF,
} from "../actions/dataGroup";

const initialState = {
  dashboardGroup: {},
  allGroup: [],
  activeStep: "page",
  detailGrup: [],
  requestDetail: [],
  setHistory: "",
  privacy: [0],
  category: [0],
  isStatus: [0],
  activePage: 1,
  totalPage: 1,
  maxPage: 0,
  userDetail: {},
  groupId: "",
  selectGrup: {},
  totalData: 0,
  poUpSetuju: false,
  popUpTolak: false,
  popUpNonaktif: false,
  popUpAktif: false,
};

const dataGroup = (state = initialState, action) => {
  switch (action.type) {
    case SET_POPUP_AKTIF:
      return {
        ...state,
        popUpAktif: action.payload,
      };
    case SET_POPUP_NONAKTIF:
      return {
        ...state,
        popUpNonaktif: action.payload,
      };
    case SET_POPUP_TOLAK:
      return {
        ...state,
        popUpTolak: action.payload,
      };
    case SET_POPUP_SETUJU:
      return {
        ...state,
        poUpSetuju: action.payload,
      };
    case SET_TOTAL_DATA_GROUP:
      return {
        ...state,
        totalData: action.payload,
      };
    case SET_SELECT_GRUP:
      return {
        ...state,
        selectGrup: action.payload,
      };
    case SET_GROUP_ID:
      return {
        ...state,
        groupId: action.payload,
      };
    case SET_MAX_PAGE_GRUP:
      return {
        ...state,
        maxPage: action.payload,
      };
    case SET_TOTAL_PAGE_GRUP:
      return {
        ...state,
        totalPage: action.payload,
      };
    case SET_ACTIVE_PAGE_GRUP:
      return {
        ...state,
        activePage: action.payload,
      };
    case SET_PRIVACY:
      return {
        ...state,
        privacy: action.payload,
      };
    case SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case SET_IS_STATUS:
      return {
        ...state,
        isStatus: action.payload,
      };

    case SET_ACTIVE_STEP_GROUP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_DASHBOARD_GROUP:
      return {
        ...state,
        dashboardGroup: action.payload,
      };
    case SET_ALL_GROUP:
      return {
        ...state,
        allGroup: action.payload,
      };
    case SET_DETAIL_GRUP:
      return {
        ...state,
        detailGrup: action.payload,
      };
    case SET_REQUEST_DETAIL_GRUP:
      return {
        ...state,
        requestDetail: action.payload,
      };
    case SET_DETAIL_PENGGUNA:
      return {
        ...state,
        userDetail: action.payload,
      };
    case SET_HISTORY:
      return {
        ...state,
        setHistory: action.payload,
      };
    default:
      return state;
  }
};

export default dataGroup;
