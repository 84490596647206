// Page Data Pengguna
// --------------------------------------------------------
import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../../../store/actions/dataPengguna";

// Page
import DataPengguna from "./DataPengguna";
import DetailPengguna from "./DetailPengguna";
import SemuaPengguna from "./SemuaPengguna";

const Page = () => {
  const [history, setHistory] = useState("");
  const [id_user, setId_user] = useState();

  const { activeStep } = useSelector((state) => state.dataPengguna);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(setActiveStep("page"));
  }, [dispatch]);

  return (
    <PageContainer>
      {activeStep === "page" && (
        <DataPengguna setHistory={setHistory} setId_user={setId_user} />
      )}
      {activeStep === "all" && (
        <SemuaPengguna setHistory={setHistory} setId_user={setId_user} />
      )}
      {activeStep === "detail" && (
        <DetailPengguna history={history} id_user={id_user} />
      )}
    </PageContainer>
  );
};

export default Page;
