import {
  SET_ACTIVE_STEP_POST,
  SET_DATA_POSTINGAN,
  SET_DATA_HEADER,
  SET_DETAIL_POSTINGAN,
  SET_LIST_POST,
  SET_ACTIVE_PAGE_POST,
  SET_TOTAL_PAGE_POST,
  SET_MAX_PAGE_POST,
  SET_SELECT_POSTINGAN,
  SET_ID,
  SET_IS_STATUS,
  SET_FITUR,
  SET_PRIVACY,
  SET_KEYWORD,
  SET_TOTAL_DATA_POST,
  SET_POPUP_STATUS,
  REASON_NONACTIVE,
} from "../actions/dataPostingan";

const initialState = {
  dataPostingan: {},
  activeStep: "page",
  dataHeader: [],
  detailPostingan: {},
  listPost: [],
  activePage: 1,
  totalPage: 1,
  maxPage: 0,
  selectPostingan: {},
  setId: "",
  isStatus: ["0"],
  fitur: ["0"],
  privacy: ["0"],
  keyword: "",
  totalData: 0,
  popupStatus: false,
  valueReason: "",
};

const dataPostingan = (state = initialState, action) => {
  switch (action.type) {
    case REASON_NONACTIVE:
      return {
        ...state,
        valueReason: action.payload,
      };
    case SET_POPUP_STATUS:
      return {
        ...state,
        popupStatus: action.payload,
      };
    case SET_TOTAL_DATA_POST:
      return {
        ...state,
        totalData: action.payload,
      };
    case SET_SELECT_POSTINGAN:
      return {
        ...state,
        selectPostingan: action.payload,
      };
    case SET_MAX_PAGE_POST:
      return {
        ...state,
        maxPage: action.payload,
      };
    case SET_TOTAL_PAGE_POST:
      return {
        ...state,
        totalPage: action.payload,
      };
    case SET_ACTIVE_PAGE_POST:
      return {
        ...state,
        activePage: action.payload,
      };
    case SET_LIST_POST:
      return {
        ...state,
        listPost: action.payload,
      };
    case SET_DATA_HEADER:
      return {
        ...state,
        dataHeader: action.payload,
      };
    case SET_DATA_POSTINGAN:
      return {
        ...state,
        dataPostingan: action.payload,
      };
    case SET_DETAIL_POSTINGAN:
      return {
        ...state,
        detailPostingan: action.payload,
      };
    case SET_ACTIVE_STEP_POST:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_ID:
      return {
        ...state,
        setId: action.payload,
      };

    case SET_IS_STATUS:
      return {
        ...state,
        isStatus: action.payload,
      };

    case SET_FITUR:
      return {
        ...state,
        fitur: action.payload,
      };

    case SET_PRIVACY:
      return {
        ...state,
        privacy: action.payload,
      };

    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };

    default:
      return state;
  }
};

export default dataPostingan;
