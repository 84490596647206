import {
  serviceExportGrup,
  serviceGetAllGrup,
  serviceGetApprovedGrup,
  serviceGetDashboardGrup,
  serviceGetDetailDataPengguna,
  serviceGetDetailGrup,
  serviceGetRequestDetailGrup,
  serviceGetSearchGrup,
} from "../../utils/api";
import { setLoading, setValueReason } from "./pageContainer";

export const SET_DASHBOARD_GROUP = "SET_DASHBOARD_GROUP";
export const SET_ALL_GROUP = "ALL_GROUP";
export const SET_REQUEST_DETAIL_GRUP = "SET_REQUEST_DETAIL_GRUP";
export const SET_SEARCH_GROUP = "SET_SEARCH_GROUP";
export const SET_DETAIL_GRUP = "SET_DETAIL_GRUP";
export const SET_APPROVED = "SET_APPROVED";
export const SET_ACTION = "SET_ACTION";
export const SET_ACTIVE_STEP_GROUP = "SET_ACTIVE_STEP_GROUP";
export const SET_HISTORY = "SET_HISTORY";
export const SET_PRIVACY = "SET_PRIVACY";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_IS_STATUS = "SET_IS_STATUS";
export const SET_ACTIVE_PAGE_GRUP = "SET_ACTIVE_PAGE_GRUP";
export const SET_TOTAL_PAGE_GRUP = "SET_TOTAL_PAGE_GRUP";
export const SET_MAX_PAGE_GRUP = "SET_MAX_PAGE_GRUP";
export const SET_DETAIL_PENGGUNA = "SET_DETAIL_PENGGUNA";
export const SET_GROUP_ID = "SET_GROUP_ID";
export const SET_SELECT_GRUP = "SET_SELECT_GRUP";
export const SET_TOTAL_DATA_GROUP = "SET_TOTAL_DATA_GROUP";
export const SET_POPUP_SETUJU = "SET_POPUP_SETUJU";
export const SET_POPUP_TOLAK = "SET_POPUP_TOLAK";
export const SET_POPUP_AKTIF = "SET_POPUP_AKTIF";
export const SET_POPUP_NONAKTIF = "SET_POPUP_NONAKTIF";

export const setPopUpNonaktif = (payload) => ({
  type: SET_POPUP_NONAKTIF,
  payload,
});

export const setPopUpAktif = (payload) => ({
  type: SET_POPUP_AKTIF,
  payload,
});

export const setPopUpTolak = (payload) => ({
  type: SET_POPUP_TOLAK,
  payload,
});

export const setPopUpSetuju = (payload) => ({
  type: SET_POPUP_SETUJU,
  payload,
});

export const setTotalData = (payload) => ({
  type: SET_TOTAL_DATA_GROUP,
  payload,
});

export const setSelectGrup = (payload) => ({
  type: SET_SELECT_GRUP,
  payload,
});

export const setMaxPage = (payload) => ({
  type: SET_MAX_PAGE_GRUP,
  payload,
});

export const setGroupId = (payload) => ({
  type: SET_GROUP_ID,
  payload,
});

export const setTotalPage = (payload) => ({
  type: SET_TOTAL_PAGE_GRUP,
  payload,
});

export const setActivePage = (payload) => ({
  type: SET_ACTIVE_PAGE_GRUP,
  payload,
});

export const setPrivacy = (payload) => ({
  type: SET_PRIVACY,
  payload,
});

export const setCategory = (payload) => ({
  type: SET_CATEGORY,
  payload,
});

export const setIsStatus = (payload) => ({
  type: SET_IS_STATUS,
  payload,
});

export const setActiveStep = (payload) => ({
  type: SET_ACTIVE_STEP_GROUP,
  payload,
});

export const setDashboardGrup = (payload) => ({
  type: SET_DASHBOARD_GROUP,
  payload,
});

export const setAllGrup = (payload) => ({
  type: SET_ALL_GROUP,
  payload,
});

export const setDetailGrup = (payload) => ({
  type: SET_DETAIL_GRUP,
  payload,
});

export const setRequestDetailGrup = (payload) => ({
  type: SET_REQUEST_DETAIL_GRUP,
  payload,
});

export const setHistory = (payload) => ({
  type: SET_HISTORY,
  payload,
});

export const setDetailPengguna = (payload) => ({
  type: SET_DETAIL_PENGGUNA,
  payload,
});

export const getDasboardGroup = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetDashboardGrup({});
    if (status === 200) {
      dispatch(setDashboardGrup(data.data));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getAllGrup = (keyword) => async (dispatch, getState) => {
  const {
    privacy,
    category,
    isStatus,
    // totalPage,
    //  maxPage,
    activePage,
  } = getState().dataGroup;

  const { tableRows } = getState().setting;

  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetAllGrup({
      page: activePage,
      limit: tableRows,
      status:
        isStatus[0].length === 0 ? "" : isStatus[0] > 0 ? isStatus[0] : "",
      category:
        category[0].length === 0 ? "" : category[0] > 0 ? category[0] : "",
      privacy: privacy[0].length === 0 ? "" : privacy[0] > 0 ? privacy[0] : "",
      keyword,
    });
    if (status === 200) {
      dispatch(setAllGrup(data.data));
      dispatch(setTotalData(data.total_data));
      // Jika data 10 atau lebih & maksimal page tidak sama dengan totalPage, maka ada kemungkinan data di next page, maka total page bertambah
      // if (data.data.length > 10 && maxPage !== totalPage) {
      //   dispatch(setTotalPage(totalPage + 1));
      // }
      dispatch(setTotalPage(Math.ceil(parseInt(data.total_data) / 10)));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getSearchGrup = (keyword, page) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetSearchGrup({
      page,
      limit: 10,
      keyword,
    });
    dispatch(setLoading(false));
    if (status === 200) {
      dispatch(setAllGrup(data.data));
    }
  } catch {
    dispatch(setLoading(false));
  }
};

export const getDetailGrup = (grup_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetDetailGrup({
      grup_id,
    });
    dispatch(setDetailGrup(data.data));
    dispatch(setGroupId(grup_id));
    if (status === 200) {
      dispatch(setActiveStep("grup"));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getRequestGrup = (grup_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetRequestDetailGrup({
      grup_id,
    });
    dispatch(setRequestDetailGrup(data.data));
    if (status === 200) {
      dispatch(setActiveStep("detail"));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const actionRequest =
  (group_id, status, aktif = false) =>
  async (dispatch, getState) => {
    const { valueReason } = getState().pageContainer;
    dispatch(setValueReason(""));
    try {
      dispatch(setLoading(true));
      const { data } = await serviceGetApprovedGrup({
        grup_id: group_id,
        status,
        feature: "2",
        reason: valueReason,
        referenc_id: group_id,
      });
      dispatch(setValueReason(""));
      dispatch(setLoading(false));

      if (data.status) {
        if (status === "1" && !aktif) {
          dispatch(setPopUpSetuju(true));
        } else if (status === "1" && aktif) {
          dispatch(setPopUpAktif(true));
        } else if (status === "3") {
          dispatch(setPopUpTolak(true));
        } else if (status === "4") {
          dispatch(setPopUpNonaktif(true));
        }
      }
    } catch {
      dispatch(setLoading(false));
    }
  };

export const searchPenggunaFromProyek = (id_user) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetDetailDataPengguna({
      id_user,
    });

    dispatch(setDetailPengguna(data.data));
    if (status === 200) dispatch(setActiveStep("detailUser"));
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const grupExport = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceExportGrup({});
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Grup.xlsx");
      document.body.appendChild(link);
      link.click();
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};
