import axios from "axios";
import {
  requestInterceptors,
  responseInterceptors,
} from "../../utils/middlewares/interceptors";

requestInterceptors();
responseInterceptors();

const devURL = "https://admin.kubu.id/api/index.php";
// const devURL = "http://localhost/admin/api/index.php";
const apiUrl = process.env.NODE_ENV === "production" ? devURL : devURL;

// Save Setting
export const serviceAddUser = (payload) =>
  axios.post(`${apiUrl}/settings/add_users`, payload);

export const serviceGetUser = (payload) =>
  axios.post(`${apiUrl}/settings/get_data_user`, payload);

export const serviceSettingWeb = (payload) =>
  axios.get(`${apiUrl}/settings/get_data_web`, payload);

export const serviceSaveSettingWeb = (payload) =>
  axios.post(`${apiUrl}/settings/update_web`, payload);

export const serviceActionSettingUser = (payload) =>
  axios.post(`${apiUrl}/settings/action`, payload);

export const serviceLogin = (payload) => axios.post(`${apiUrl}/login`, payload);

// Api Notifikasi
export const serviceNotifikasi = (payload) =>
  axios.post(`${apiUrl}/settings/get_notif`, payload);

export const serviceAddNotifikasi = (payload) =>
  axios.post(`${apiUrl}/settings/add_notifikasi`, payload);

export const serviceActionNotifikasi = (payload) =>
  axios.post(`${apiUrl}/settings/action_notif`, payload);

// Api Tiket
export const serviceTiket = (payload) => axios.get(`${apiUrl}/tiket`, payload);

export const serviceGetTiket = (payload) =>
  axios.post(`${apiUrl}/tiket/get_data`, payload);

export const serviceTiketDetail = (payload) =>
  axios.post(`${apiUrl}/tiket/detail`, payload);

export const serviceReplyTiket = (payload) =>
  axios.post(`${apiUrl}/tiket/replay`, payload);

export const serviceActionTiket = (payload) =>
  axios.post(`${apiUrl}/tiket/action`, payload);

// Api Dashboard
export const serviceDashboard = (payload) =>
  axios.get(`${apiUrl}/dashboard`, payload);

export const serviceReadNotif = (payload) =>
  axios.post(`${apiUrl}/dashboard/action_read`, payload);

// Api Postingan
export const servicePostingan = (payload) =>
  axios.get(`${apiUrl}/postingan`, payload);

export const serviceGetPostingan = (payload) =>
  axios.post(`${apiUrl}/postingan/get_data`, payload);

export const serviceDetailPostingan = (payload) =>
  axios.post(`${apiUrl}/postingan/detail`, payload);

export const serviceActionPostingan = (payload) =>
  axios.post(`${apiUrl}/postingan/action`, payload);

// Save Loker
export const serviceGetDataLoker = (payload) =>
  axios.get(`${apiUrl}/loker`, payload);

export const serviceAddBanner = (payload) =>
  axios.post(`${apiUrl}/loker/add_banner`, payload);

export const serviceGetDataBanner = (payload) =>
  axios.get(`${apiUrl}/loker/getBanner`, payload);

export const serviceActionBanner = (payload) =>
  axios.post(`${apiUrl}/loker/action_banner`, payload);

export const serviceApproveLoker = (payload) =>
  axios.post(`${apiUrl}/loker/approved`, payload);

export const serviceGetAllLoker = (payload) =>
  axios.post(`${apiUrl}/loker/getAllLoker`, payload);

export const serviceGetDetailLoker = (payload) =>
  axios.post(`${apiUrl}/loker/getDetail`, payload);

export const serviceLokerFilter = (payload) =>
  axios.post(`${apiUrl}/loker/filter`, payload);

export const serviceLoker = (payload) =>
  axios.post(`${apiUrl}/loker/save`, payload);

export const serviceUpdateLoker = (payload) =>
  axios.post(`${apiUrl}/loker/updatejobs`, payload);

export const serviceGetCategory = (payload) =>
  axios.get(`${apiUrl}/loker/getCategory`, payload);

export const serviceGetJobType = (payload) =>
  axios.get(`${apiUrl}/loker/getJobType`, payload);

export const serviceGetProvince = (payload) =>
  axios.get(`${apiUrl}/loker/getProvince`, payload);

export const serviceGetCity = (payload) =>
  axios.post(`${apiUrl}/loker/getCity`, payload);

export const serviceLokerExport = (payload) =>
  axios.post(`${apiUrl}/loker/export`, payload, { responseType: "blob" });

// Api for pengguna
export const serviceDashboardUsers = (payload) =>
  axios.get(`${apiUrl}/users`, payload);

export const serviceUsersExport = (payload) =>
  axios.post(`${apiUrl}/users/export`, payload, {
    responseType: "blob",
  });

export const serviceGetDetailDataPengguna = (payload) =>
  axios.post(`${apiUrl}/users/get_detail`, payload);

export const serviceGetAllUsers = (payload) =>
  axios.post(`${apiUrl}/users/get_all`, payload);

export const servicePenggunaFilter = (payload) =>
  axios.post(`${apiUrl}/users/filter`, payload);

export const serviceGetSearchPengguna = (payload) =>
  axios.post(`${apiUrl}/users/search`, payload);

export const serviceActionPengguna = (payload) =>
  axios.post(`${apiUrl}/users/action`, payload);

export const serviceVerifiedPengguna = (payload) =>
  axios.post(`${apiUrl}/users/action_verified`, payload);

// API for Data Grup
export const serviceGetDashboardGrup = (payload) =>
  axios.get(`${apiUrl}/grup`, payload);

export const serviceGetAllGrup = (payload) =>
  axios.post(`${apiUrl}/grup/get_all`, payload);

export const serviceGetSearchGrup = (payload) =>
  axios.post(`${apiUrl}/grup/search`, payload);

export const serviceGetRequestDetailGrup = (payload) =>
  axios.post(`${apiUrl}/grup/request_detail`, payload);

export const serviceGetDetailGrup = (payload) =>
  axios.post(`${apiUrl}/grup/grup_detail`, payload);

export const serviceGetApprovedGrup = (payload) =>
  axios.post(`${apiUrl}/grup/approved`, payload);

export const serviceGetActionlGrup = (payload) =>
  axios.post(`${apiUrl}/grup/action`, payload);

export const serviceExportGrup = (payload) =>
  axios.post(`${apiUrl}/grup/export`, payload, { responseType: "blob" });

// API for DataProyek
export const serviceGetDashboardDataProyek = (payload) =>
  axios.get(`${apiUrl}/mytim`, payload);

export const serviceGetFilterDataProyek = (payload) =>
  axios.post(`${apiUrl}/mytim/filter`, payload);

export const serviceRequestDetail = (payload) =>
  axios.post(`${apiUrl}/mytim/request_detail`, payload);

export const serviceApproved = (payload) =>
  axios.post(`${apiUrl}/mytim/approved`, payload);

export const serviceProyekDetail = (payload) =>
  axios.post(`${apiUrl}/mytim/proyek_detail`, payload);

export const serviceTabTask = (payload) =>
  axios.post(`${apiUrl}/mytim/tab_task`, payload);

export const serviceTabDiskusiTask = (payload) =>
  axios.post(`${apiUrl}/mytim/tab_diskusi_task`, payload);

export const serviceExportProyek = (payload) =>
  axios.post(`${apiUrl}/proyek/export`, payload, { responseType: "blob" });
