import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import PopUp from "../../../components/PopUp";
import { stringToColor } from "../../../utils/helpers";
import moment from "moment";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getActionTiket,
  replyChat,
  setActiveStep,
} from "../../../store/actions/dataTiket";

// Assets
import ticket from "../../../assets/icon/Ticket.svg";
import iconSend from "../../../assets/icon/icon-send.png";

const MasalahTiket = ({ history }) => {
  const dispatch = useDispatch();
  const [popupSend, setPopupSend] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [expand, setExpand] = useState(false);

  const id_user = JSON.parse(localStorage.getItem("dataUser")).data.id_user;

  const { selectTiket, detailTiket, loadingChat } = useSelector(
    (state) => state.dataTiket,
    shallowEqual
  );

  return (
    <Container>
      {/* Tittle */}
      <Title
        title="Detail Tiket"
        withBack
        onBack={() =>
          dispatch(setActiveStep(history === "page" ? "page" : "all"))
        }
      >
        {detailTiket?.header?.id_status !== "2" && (
          <Button
            padding="8px 33px"
            borderRadius="10px"
            isLoading={loadingChat}
            onClick={() => {
              dispatch(getActionTiket("2"));
            }}
          >
            Selesai
          </Button>
        )}
      </Title>

      {/* Wrapper  */}
      <Wrapper>
        <HeaderWrapper>
          <SectionLeft>
            <img
              src={ticket}
              alt=""
              style={{ width: "30px", alignSelf: "start" }}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <span>{selectTiket.title}</span>
              <div style={{ display: !expand ? "flex" : "block" }}>
                <p
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    color: "#8c8c8d",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: !expand ? "200px" : "100%",
                    height: "1.2em",
                    whiteSpace: "nowrap",
                  }}
                >
                  {detailTiket?.header?.desc || "-"}
                </p>
                <p
                  style={{
                    cursor: "pointer",
                    display: detailTiket?.header?.desc?.length < 30 && "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    color: "#125AAA",
                  }}
                  onClick={() => setExpand((prev) => !prev)}
                >
                  {!expand ? "selengkapnya" : "tampilkan sedikit"}
                </p>
              </div>
            </div>
          </SectionLeft>
          <SectionRight>Dibalas</SectionRight>
        </HeaderWrapper>

        <BodyWrapper>
          <ChatContainer>
            {Object.keys(detailTiket).length > 0 &&
              detailTiket.conten.map((item, index) =>
                item.id_user === id_user ? (
                  <ChatWrapper style={{ justifyContent: "end" }} key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <MessageWrapper>
                        <NickName style={{ textAlign: "end" }}>
                          {item.full_name}
                        </NickName>
                        <Message>{item.message}</Message>
                      </MessageWrapper>
                      {item.foto_profile !== null ? (
                        <img
                          src={item.foto_profile}
                          alt="profile"
                          width={"50px"}
                        />
                      ) : (
                        <DefaultImgContainer
                          style={{
                            backgroundColor: stringToColor(
                              `${
                                item.full_name !== null
                                  ? item.full_name
                                  : "Blank"
                              }`
                            ),
                          }}
                        >
                          <DefaultImg>
                            {item.full_name !== null
                              ? item.full_name?.slice(0, 1).toUpperCase()
                              : ""}
                          </DefaultImg>
                        </DefaultImgContainer>
                      )}
                    </div>
                  </ChatWrapper>
                ) : (
                  <ChatWrapper key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      {item.foto_profile !== null ? (
                        <img
                          src={item.foto_profile}
                          alt="profile"
                          width={"50px"}
                        />
                      ) : (
                        <DefaultImgContainer
                          style={{
                            backgroundColor: stringToColor(
                              `${
                                item.full_name !== null
                                  ? item.full_name
                                  : "Blank"
                              }`
                            ),
                          }}
                        >
                          <DefaultImg>
                            {item.full_name !== null
                              ? item.full_name?.slice(0, 1).toUpperCase()
                              : ""}
                          </DefaultImg>
                        </DefaultImgContainer>
                      )}
                      <MessageWrapper>
                        <NickName>{item.full_name}</NickName>
                        <Message>{item.message}</Message>
                      </MessageWrapper>
                    </div>

                    <Time>{moment(item.create_at).format("DD MMM YYYY")}</Time>
                  </ChatWrapper>
                )
              )}
          </ChatContainer>
          {detailTiket?.header?.id_status !== "2" && (
            <ReplayWrapper>
              <ReplyHeader> Replay Tiket</ReplyHeader>
              <ReplyBody
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
                placeholder="Balas komentar di sini....."
              />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  padding="8px 33px"
                  borderRadius="5px"
                  isLoading={loadingChat}
                  onClick={() => {
                    setReplyText("");
                    dispatch(replyChat(replyText));
                  }}
                >
                  Kirim
                </Button>
              </div>
            </ReplayWrapper>
          )}
        </BodyWrapper>
      </Wrapper>

      {/* Popup Berhasi Kirim Balasan */}
      <PopUp
        open={popupSend}
        width="350px"
        padding="60px 30px 25px 30px"
        imgSrc={iconSend}
        onClose={() => setPopupSend(false)}
        onClickAction={() => setPopupSend(false)}
        title="Berhasil dikirim"
        info="Balasan anda berhasil dikirim."
      />
    </Container>
  );
};

const DefaultImgContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  border-radius: 30px;
`;

const DefaultImg = styled("p")`
  color: white;
  text-decoration: none;
  font-size: 13px;
`;

const Container = styled("div")`
  height: 100%;
`;

const Wrapper = styled("div")`
  margin-top: 20px;
`;

const HeaderWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 23px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
`;

const SectionLeft = styled("section")`
  display: flex;
  align-items: center;
  gap: 14px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

const SectionRight = styled("section")`
  padding: 10px;
  background: #aef8ac;
  border: 1px solid #04682c;
  border-radius: 20px;

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #04682c;
`;

const BodyWrapper = styled("div")`
  background: #f7f7f7;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ChatContainer = styled("div")`
  height: 60%;
`;

const ChatWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 18px;
`;

const MessageWrapper = styled("div")``;

const NickName = styled("h2")`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000;
`;

const Message = styled("span")`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #292929;
`;

const Time = styled("span")`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #aba7a7;
`;

const ReplayWrapper = styled("div")`
  height: 30%;
`;

const ReplyHeader = styled("div")`
  background: #fffcfc;
  padding: 11px 15px;
  border: 1px solid rgba(99, 97, 97, 0.2);
  border-radius: 10px 10px 0px 0px;
`;

const ReplyBody = styled("textarea")`
  height: 100px;
  width: 100%;
  resize: none;
  background: #efeded;
  padding: 16px 27px;
  border: 1px solid rgba(138, 136, 136, 0.3);
  border-radius: 0 0 10px 10px;
`;

export default MasalahTiket;
