/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Collapse, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppRoutes from "../../config/routes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setRoute } from "../../store/actions/pageContainer";

// Assets
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import iconLogout from "../../assets/icon/icon-logout.png";
import PopUp from "../PopUp";

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [popUpLogout, setPopUpLogout] = useState(false);

  const { menuAccess, route } = useSelector(
    (state) => state.pageContainer,
    shallowEqual
  );

  useEffect(() => {
    if (route.length === 0) {
      dispatch(setRoute(AppRoutes));
    }
  }, []);

  const handleLogout = () => {
    history.push("/nakerweb/login");
    localStorage.removeItem("dataUser");
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "228px",
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        backgroundColor: "white",
        zIndex: 900,
        marginTop: "70px",
        overflow: "scroll",
        paddingBottom: "100px",
      }}
    >
      {route.map(
        (item, index) =>
          menuAccess.includes(JSON.stringify(item.id)) &&
          item.parentId === 0 && (
            <div key={index}>
              <div
                style={{
                  display: "flex",
                  backgroundColor:
                    location?.pathname === item.path ? "#115abe" : "white",
                  color: location?.pathname === item.path ? "white" : "black",
                }}
              >
                {item.component !== null ? (
                  <Button
                    variant="text"
                    sx={{
                      p: 0,
                      textTransform: "unset",
                      borderRadius: "0px",
                      width: "100%",
                      justifyContent: "start",
                    }}
                  >
                    <LinkWrapper
                      to={item.path}
                      style={{
                        backgroundColor:
                          location?.pathname === item.path
                            ? "#115abe"
                            : "white",
                        color:
                          location?.pathname === item.path ? "white" : "black",
                      }}
                    >
                      <p>{item.nameComponent}</p>
                    </LinkWrapper>
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    sx={{
                      height: "40px",
                      p: "10px 16px",
                      textTransform: "unset",
                      borderRadius: "0px",
                      width: "100%",
                      backgroundColor: "white",
                      color: "black",
                      ":hover": {
                        backgroundColor: "white",
                      },
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      if (
                        !(
                          route.find((es) => es.path === location?.pathname)
                            .parentId ===
                          route.find((as) => as.id === item.id).id
                        )
                      ) {
                        dispatch(
                          setRoute(
                            route.map((el, idx) =>
                              idx === index ? { ...el, expand: !el.expand } : el
                            )
                          )
                        );
                      }
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "20px",
                      }}
                    >
                      {item.nameComponent}
                    </p>
                    {item.expand ||
                    route.find((es) => es.path === location?.pathname)
                      .parentId === route.find((as) => as.id === item.id).id ? (
                      <ExpandMoreIcon color="black" />
                    ) : (
                      <ExpandLessIcon color="black" />
                    )}
                  </Button>
                )}
              </div>
              {route.map(
                (el, ind) =>
                  el.parentId === item.id && (
                    <Collapse
                      key={ind}
                      in={
                        item.expand ||
                        route.find((es) => es.path === location?.pathname)
                          .parentId === route.find((as) => as.id === item.id).id
                      }
                      timeout="auto"
                      unmountOnExit
                    >
                      <Button
                        variant="text"
                        sx={{
                          p: 0,
                          textTransform: "unset",
                          borderRadius: "0px",
                          width: "100%",
                          justifyContent: "start",
                          backgroundColor:
                            location?.pathname === el.path
                              ? "#115abe"
                              : "white",
                          color:
                            location?.pathname === el.path ? "white" : "black",
                        }}
                      >
                        <LinkWrapper
                          to={el.path}
                          style={{
                            backgroundColor:
                              location?.pathname === el.path
                                ? "#115abe"
                                : "white",
                            color:
                              location?.pathname === el.path
                                ? "white"
                                : "black",
                          }}
                        >
                          <p style={{ marginLeft: "20px" }}>
                            {el.nameComponent}
                          </p>
                        </LinkWrapper>
                      </Button>
                    </Collapse>
                  )
              )}
            </div>
          )
      )}
      <Button
        variant="text"
        sx={{
          p: 0,
          textTransform: "unset",
          borderRadius: "0px",
          width: "100%",
          justifyContent: "start",
        }}
        onClick={() => setPopUpLogout(true)}
      >
        <LogoutButton>
          <p>Keluar</p>
          <img alt="logout" src={iconLogout} width={24} height={24} />
        </LogoutButton>
      </Button>

      <PopUp
        type="choice"
        open={popUpLogout}
        imgSrc={iconLogout}
        swapOptions
        onClose={() => setPopUpLogout(false)}
        onClickAction={() => handleLogout()}
        title="Keluar"
        info="Anda yakin akan akhiri sesi?"
      />
    </div>
  );
};

// Style
const LogoutButton = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Overpass, san-serif;
  color: #0e0000;
  font-weight: 600;
  font-size: 15px;
  line-height: 19.5px;
  padding: 10px 16px;
  text-decoration: none;
  width: 100%;
`;
const LinkWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Overpass, san-serif;
  color: #0e0000;
  font-weight: 600;
  font-size: 15px;
  line-height: 19.5px;
  padding: 10px 16px;
  text-decoration: none;
  width: 100%;
`;

export default Sidebar;
