/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "../../../components/Table";
import PageNextPrev from "../../../components/PageNextPrev";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";
import DropDown from "../../../components/DropDown";
import Button from "../../../components/Button";
import InputText from "../../../components/InputText";
import Colors from "../../../utils/helpers/colors";
import { InputAdornment, Menu } from "@mui/material";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setActiveStep,
  getFilterTiket,
  setKeyword,
  setSelectStatus,
  setActivePage,
} from "../../../store/actions/dataTiket";

// Assets
import iconSearch from "../../../assets/icon/icon-search.png";
import iconSlider from "../../../assets/icon/icon-slider.png";

const listDropDown = [
  {
    label: "Semua Status",
    value: 0,
  },
  {
    label: "Menunggu",
    value: 1,
  },
  {
    label: "Baru",
    value: 2,
  },
  {
    label: "Selesai",
    value: 3,
  },
  {
    label: "Sudah Dijawab",
    value: 4,
  },
];

const AllDataTiket = ({ setHistory }) => {
  const dispatch = useDispatch();

  const [menuFilter, setMenuFilter] = useState(null);

  const {
    listTiket,
    headerTable,
    keyword,
    selectStatus,
    activePage,
    totalPage,
    totalData,
  } = useSelector((state) => state.dataTiket, shallowEqual);

  useEffect(() => {
    setHistory("all");
    dispatch(getFilterTiket());
  }, []);

  return (
    <Container>
      <HeaderContainer>
        {/* Title */}

        <Title
          title="Data Tiket"
          withBack
          onBack={() => dispatch(setActiveStep("page"))}
        >
          <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
            {/* Pencarian */}
            <InputText
              type="text"
              value={keyword}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  dispatch(getFilterTiket());
                }
              }}
              onChange={(e) => {
                dispatch(setKeyword(e.target.value));
                if (e.target.value === "") {
                  dispatch(getFilterTiket());
                }
              }}
              placeholder="pencarian"
              noPadding
              width="175px"
              borderRadius="5px"
              borderColor="#115AAA"
              iconAdornment={
                <InputAdornment
                  position="start"
                  className="inputAdorment"
                  sx={{
                    zIndex: 200,
                    height: "40px",
                    justifyContent: "center",
                    width: "67px",
                    maxHeight: "56px",
                    borderRadius: "5px 0px 0px 5px",
                    backgroundColor: Colors.primary.hard,
                    ".MuiTypography-root": {
                      color: "white",
                      fontSize: "0.938rem",
                      fontWeight: "400",
                      lineHeight: "1.124rem",
                    },
                  }}
                >
                  <img
                    src={iconSearch}
                    alt="icon-search"
                    width={24}
                    height={24}
                  />
                </InputAdornment>
              }
            />

            {/* Tombol Filter */}
            <Button
              padding="8px"
              width="fit-content"
              onClick={(event) => {
                setMenuFilter(event.currentTarget);
              }}
            >
              <img src={iconSlider} alt="icon-slider" width={24} height={24} />
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={menuFilter}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                top: "40px",
                left: "10px",
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "18px",
                color: "#000000",
                "& .MuiPaper-root": {
                  borderRadius: "10px",
                },
                "& .MuiList-root": {
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                },
              }}
              open={Boolean(menuFilter)}
              onClose={() => setMenuFilter(null)}
            >
              <DropdownWrap>
                <p>Pilih Status :</p>
                <DropDown
                  dropdownValue={selectStatus}
                  handleChange={(e) => {
                    dispatch(setSelectStatus([e.target.value]));
                    dispatch(getFilterTiket());
                  }}
                  listDropDown={listDropDown}
                />
              </DropdownWrap>
            </Menu>
          </div>
        </Title>
      </HeaderContainer>

      {/* Table */}
      <Table headerContent={headerTable} dataContent={listTiket} />

      {/* PageNextPrev */}
      <PageNextPrev
        totalPage={totalPage}
        activePage={activePage}
        totalData={totalData}
        onChange={(value) => {
          dispatch(
            setActivePage(value === "LEFT" ? activePage - 1 : activePage + 1)
          );
          dispatch(getFilterTiket());
        }}
      />
    </Container>
  );
};

const DropdownWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
}));

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HeaderContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export default AllDataTiket;
