/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Title from "../../../components/Title";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import PageContainer from "../../../components/PageContainer";
import Table from "../../../components/Table";
import Button from "../../../components/Button";
import InputText from "../../../components/InputText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getActionUser,
  getDataUser,
  getSaveUser,
  setPopupSave,
} from "../../../store/actions/setting";

// Asset
import moment from "moment";
import PopUp from "../../../components/PopUp";
import iconPencil from "../../../assets/img/document-writer.png";
import Colors from "../../../utils/helpers/colors";
import iconSearch from "../../../assets/icon/icon-search.png";

export const initAccess = [
  { menu_name: "Dashboard", id: "1", select: false },
  { menu_name: "Data Pengguna", id: "2", select: false },
  { menu_name: "Data Grup", id: "3", select: false },
  { menu_name: "Data Loker", id: "4", select: false },
  { menu_name: "Data Proyek", id: "5", select: false },
  { menu_name: "Data Postingan", id: "6", select: false },
  { menu_name: "List Postingan", id: "7", select: false },
  { menu_name: "Postingan Dilaporkan", id: "8", select: false },
  { menu_name: "Tambah Postingan", id: "9", select: false },
  { menu_name: "Notitkasi", id: "10", select: false },
  { menu_name: "Pengaturan", id: "11", select: false },
];

const PengaturanPengguna = () => {
  const dispatch = useDispatch();
  const [popUpAdd, setPopUpAdd] = useState(false);
  const [popUpCDelete, setPopUpCDelete] = useState(false);
  const [keyword, setKeyword] = useState("");

  // state for input form
  const [fullName, setFullName] = useState("");
  const [selectUser, setSelectUser] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [menuAccess, setMenuAccess] = useState(initAccess);

  const { dataUser, popupSave } = useSelector(
    (state) => state.setting,
    shallowEqual
  );

  const dataHeaderAll = [
    {
      title: "No",
      key: "id_user",
      width: 50,
      render: (_, index) => <p>{index + 1}</p>,
    },
    {
      title: "Nama Lengkap",
      key: "full_name",
    },
    {
      title: "Email",
      key: "email",
      render: (rowData) => (
        <p
          style={{
            marginLeft: "",
          }}
        >
          {rowData.email}
        </p>
      ),
    },
    {
      title: "Tanggal Ditambahkan",
      key: "create_at",
      center: true,
      render: (rowData) => (
        <span>{moment(rowData.create_at).format("DD MMMM YYYY")}</span>
      ),
    },
    {
      title: "Aksi",
      width: 100,
      render: (row) => (
        <Button
          variant="contained"
          borderRadius="5px"
          padding="0 9px"
          style={{
            backgroundColor: "#FB7171",
          }}
          onClick={() => {
            setSelectUser(row);
            setPopUpCDelete(true);
          }}
        >
          Nonaktifkan
        </Button>
      ),
      center: true,
    },
  ];

  useEffect(() => {
    dispatch(getDataUser(keyword));
  }, []);

  const handleChange = (event, id) => {
    setMenuAccess((prevMenu) =>
      prevMenu.map((item) =>
        item.id === id ? { ...item, select: event.target.checked } : item
      )
    );
  };

  const addUserHandler = () => {
    dispatch(getSaveUser(fullName, email, password, menuAccess));
    setPopUpAdd(false);
    setFullName("");
    setEmail("");
    setPassword("");
    setMenuAccess(initAccess);
  };

  return (
    <PageContainer>
      <Container>
        {/* Title Page */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title title="Pengaturan Pengguna" />
          <div style={{ display: "flex", gap: "20px" }}>
            <InputText
              type="text"
              value={keyword}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  dispatch(getDataUser(keyword));
                }
              }}
              onChange={(e) => {
                setKeyword(e.target.value);
                if (e.target.value === "") {
                  dispatch(getDataUser(keyword));
                }
              }}
              placeholder="pencarian"
              noPadding
              width="175px"
              borderRadius="5px"
              borderColor="#115AAA"
              iconAdornment={
                <InputAdornment
                  position="start"
                  className="inputAdorment"
                  sx={{
                    zIndex: 200,
                    height: "40px",
                    justifyContent: "center",
                    width: "67px",
                    maxHeight: "56px",
                    borderRadius: "5px 0px 0px 5px",
                    backgroundColor: Colors.primary.hard,
                    ".MuiTypography-root": {
                      color: "white",
                      fontSize: "0.938rem",
                      fontWeight: "400",
                      lineHeight: "1.124rem",
                    },
                  }}
                >
                  <img
                    src={iconSearch}
                    alt="icon-search"
                    width={24}
                    height={24}
                  />
                </InputAdornment>
              }
            />
            <Button
              padding="12px"
              width="167px"
              borderRadius="5px"
              onClick={() => {
                setPopUpAdd(true);
              }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "18px",
                  color: "white",
                }}
              >
                Tambah Pengguna
              </p>
            </Button>
          </div>
        </div>
        <RowWrapper>
          <Table headerContent={dataHeaderAll} dataContent={dataUser} />
        </RowWrapper>

        <PopUpDialog
          open={popUpAdd}
          onClose={() => {
            setPopUpAdd(false);
          }}
          aria-labelledby="popup-dialog-title"
        >
          <DialogContentCustom>
            <InputWrap>
              <InpuTitle>Nama Lengkap</InpuTitle>
              <InputText
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Masukan Lengkap"
                borderRadius="0px"
                borderColor="#E4E4E4"
                backgroundColor="rgba(217, 217, 217, 0.1)"
                placeholderStyle={{ fontSize: "12px", lineHeight: "15px" }}
              />
            </InputWrap>
            <InputWrap>
              <InpuTitle>Almat Email</InpuTitle>
              <InputText
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Msukan Email"
                borderRadius="0px"
                borderColor="#E4E4E4"
                backgroundColor="rgba(217, 217, 217, 0.1)"
                placeholderStyle={{ fontSize: "12px", lineHeight: "15px" }}
              />
            </InputWrap>
            <InputWrap>
              <InpuTitle>Kata sandi</InpuTitle>
              <InputText
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Masukan Kata andi"
                borderRadius="0px"
                borderColor="#E4E4E4"
                backgroundColor="rgba(217, 217, 217, 0.1)"
                placeholderStyle={{ fontSize: "12px", lineHeight: "15px" }}
              />
            </InputWrap>

            <InputWrap>
              <InpuTitle>Hak Akses</InpuTitle>

              <AksesWrapper>
                {menuAccess.map((item) => (
                  <FormControlLabel
                    onChange={(event) => handleChange(event, item.id)}
                    key={item.id}
                    control={<Checkbox checked={item.select} size="small" />}
                    label={item.menu_name}
                  />
                ))}
              </AksesWrapper>
            </InputWrap>
          </DialogContentCustom>

          <DialogActionsCustom>
            <Button
              variant="contained"
              width="217px"
              borderRadius="5px"
              padding="13px"
              onClick={() => {
                addUserHandler();
              }}
              disabled={
                fullName === "" ||
                email === "" ||
                password === "" ||
                !menuAccess.some((item) => item.select === true)
              }
            >
              <ButtonText>Tambah User</ButtonText>
            </Button>
          </DialogActionsCustom>
        </PopUpDialog>
      </Container>

      <PopUp
        open={popupSave}
        imgSrc={iconPencil}
        width="350px"
        padding="60px 30px 25px 30px"
        onClose={() => dispatch(setPopupSave(false))}
        onClickAction={() => dispatch(setPopupSave(false))}
        title="Berhasil"
        info="User Berhasil Disimpan"
      />

      <PopUp
        open={popUpCDelete}
        buttonWord="Nonaktifkan"
        type="choice"
        imgSrc={iconPencil}
        onClose={() => setPopUpCDelete(false)}
        swapOptions
        onClickAction={() => {
          setPopUpCDelete(false);
          dispatch(getActionUser(selectUser.id_user, "2"));
        }}
        title="Apakah anda ingin menonaktifkan user ini ?"
      />
    </PageContainer>
  );
};

// Style
const Container = styled("div")``;

const RowWrapper = styled("div")`
  margin-top: 10px;
`;

const PopUpDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "674px",
    borderRadius: "10px",
    height: "fit-content",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    backgroundColor: "#F4F7FB",
  },
}));

const DialogContentCustom = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "20px",
  backgroundColor: "white",
  borderRadius: "10px",
}));

const InputWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
}));

const InpuTitle = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  letterSpacing: "0.01em",
  fontSize: "12px",
  lineHeight: "15px",
  color: "#000000",
}));

const DialogActionsCustom = styled(DialogActions)(() => ({
  padding: "0px",
  display: "flex",
  justifyContent: "end",
}));

const ButtonText = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "0.01em",
  color: "#FFFFFF",
}));

const AksesWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

export default PengaturanPengguna;
