import {
  SET_DATA_NOTIF,
  SET_POPUP_ADD,
  SET_ACTIVE_PAGE_NOTIF,
  SET_TOTAL_PAGE_NOTIF,
  SET_MAX_PAGE_NOTIF,
  SET_TOTAL_DATA_NOTIF,
} from "../actions/notifikasi";

const initialState = {
  dataNotif: [],
  popupAdd: false,
  activePage: 1,
  totalPage: 1,
  maxPage: 0,
  totalData: 0,
};

const notifikasi = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOTAL_DATA_NOTIF:
      return {
        ...state,
        totalData: action.payload,
      };
    case SET_MAX_PAGE_NOTIF:
      return {
        ...state,
        maxPage: action.payload,
      };
    case SET_TOTAL_PAGE_NOTIF:
      return {
        ...state,
        totalPage: action.payload,
      };
    case SET_ACTIVE_PAGE_NOTIF:
      return {
        ...state,
        activePage: action.payload,
      };
    case SET_POPUP_ADD:
      return {
        ...state,
        popupAdd: action.payload,
      };
    case SET_DATA_NOTIF:
      return {
        ...state,
        dataNotif: action.payload,
      };
    default:
      return state;
  }
};

export default notifikasi;
