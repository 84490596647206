/* eslint-disable react-hooks/exhaustive-deps */
// Page Data Loker
// --------------------------------------------------------

import React, { useEffect, useState } from "react";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";
import Table from "../../../components/Table";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Chart from "../../../components/Chart";
import ChartLine from "../../../components/ChartLine";
import NoData from "../../../components/NoData";
import moment from "moment";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  executeLoker,
  getDataLoker,
  getDetailLoker,
  getLoker,
  setActiveStep,
  setSelectedData,
} from "../../../store/actions/dataLoker";

// Asset
import imagePerson from "../../../assets/img/image-person-trending.png";
import iconArrowRight from "../../../assets/icon/icon-arrow-right.png";
import Button from "../../../components/Button";
import PopUp from "../../../components/PopUp";
import iconDelete from "../../../assets/icon/business-icon-decline.png";

const dataChart = [
  {
    data: [0, 0, 0],
    backgroundColor: ["#115ABE", "#FA3E3E", "#03B74B"],
    borderWidth: 0,
    label: [
      {
        title: "Diterima",
        color: "#115ABE",
      },
      {
        title: "Ditolak",
        color: "#FA3E3E",
      },
      {
        title: "Dilamar",
        color: "#03B74B",
      },
    ],
  },
];

const DataLoker = ({ setHistory }) => {
  const dispatch = useDispatch();

  const [pieChart, setPieChart] = useState(dataChart);
  const [totalStat, setTotalStat] = useState(0);
  const [labelStat, setLabelStat] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const dataHeaderTable = [
    {
      title: "No",
      key: "loker_id",
      width: 30,
      render: (_, index) => <p>{index + 1}</p>,
    },
    {
      title: "Posisi",
      key: "job_position",
    },
    {
      title: "Perusahaan",
      key: "name",
    },
    {
      title: "Lokasi",
      key: "city",
    },
    {
      title: "Tanggal Buka",
      key: "start_open",
      render: (rowData) => (
        <p>{moment(rowData.start_open).format("DD MMMM YYYY")}</p>
      ),
    },
    {
      title: "Sektor",
      key: "sector",
    },
    {
      title: "Status",
      key: "status",
      width: 120,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {rowData.status === "1" ? (
            <div
              style={{
                border: "1px solid #039C40",
                backgroundColor: "#96f5bf",
                borderRadius: "30px",
                padding: "4px 20px",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#039C40",
                whiteSpace: "nowrap",
              }}
            >
              Disetujui
            </div>
          ) : rowData.status === "2" ? (
            <div
              style={{
                border: "1px solid #C80707",
                backgroundColor: "#F5969633",
                borderRadius: "30px",
                padding: "4px 20px",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#C80707",
                whiteSpace: "nowrap",
              }}
            >
              Ditolak
            </div>
          ) : (
            <div
              style={{
                border: "1px solid #115abe",
                backgroundColor: "#bde2fc",
                borderRadius: "30px",
                padding: "4px 20px",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#115abe",
                whiteSpace: "nowrap",
              }}
            >
              Menunggu
            </div>
          )}
        </div>
      ),
      center: true,
    },
    {
      title: "Aksi",
      key: "action",
      render: (rowData) => (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Button
            variant="contained"
            borderRadius="5px"
            padding="0px 7px 0px 9px"
            onClick={() => {
              dispatch(setSelectedData(rowData));
              dispatch(getDetailLoker());
              setHistory("page");
            }}
            style={{ whiteSpace: "nowrap", width: "100%" }}
          >
            Detail
          </Button>
          <Button
            variant="contained"
            borderRadius="5px"
            padding="0 9px"
            style={{
              backgroundColor: "#FB7171",
              width: "100%",
            }}
            onClick={() => {
              dispatch(setSelectedData(rowData));
              setConfirmDelete(true);
            }}
          >
            Hapus
          </Button>
        </div>
      ),
      center: true,
    },
  ];

  // Get data redux
  const { lokerHome, loker, totalData } = useSelector(
    (state) => state.dataLoker,
    shallowEqual
  );

  // Render pertama untuk get data
  useEffect(() => {
    dispatch(getDataLoker());
    dispatch(getLoker());
  }, [dispatch]);

  // Menghitung data loker untuk ditampilkan sebagai pie chart ketika data berhasil diambil
  useEffect(() => {
    if (Object.keys(loker).length !== 0) {
      const { applied, rejected, accepted } = loker.total_loker;
      const total = parseInt(applied) + parseInt(rejected) + parseInt(accepted);
      setTotalStat(total);
      const data = [
        (100 / total) * accepted,
        (100 / total) * rejected,
        (100 / total) * applied,
      ];
      setPieChart([
        {
          ...dataChart[0],
          empty: total === 0,
          data: total === 0 ? [100] : data,
          backgroundColor: [
            total === 0 ? "#bbbaba" : "#115ABE",
            "#FA3E3E",
            "#03B74B",
          ],
        },
      ]);

      const label = loker.statistik.map((item) => item.name);
      setLabelStat(label);

      const dataStat = [
        {
          label: "Loker",
          data: loker.statistik
            .map((item) => item.total)
            .slice(0, moment().month() + 1),
          borderColor: "#0D5EB9",
          backgroundColor: "#0D5EB9",
        },
      ];
      setDataSets(dataStat);
    }
  }, [loker]);

  return (
    <Container>
      {/* Title */}
      <Title title="Data Loker" />

      {/* Chart */}
      <RowWrapper>
        <ContentWrapper style={{ width: "65%" }}>
          <TitleBar>Statistik Loker</TitleBar>
          <ChartLine labels={labelStat} datasets={datasets} />
        </ContentWrapper>
        <ContentWrapper style={{ width: "35%" }}>
          <TitleBar>Jumlah Loker</TitleBar>
          <Chart data={pieChart} description={`${totalStat} Loker`} />
        </ContentWrapper>
      </RowWrapper>

      <RowWrapper>
        {/* Lokasi trending */}
        <ContentWrapper style={{ width: "37%" }}>
          <TitleBar>Lokasi Trending</TitleBar>
          {loker?.trend_location?.length > 0 ? (
            <ScrollWrapper
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                maxWidth: "fit-content",
                height: "fit-content",
                justifyContent: "space-around",
                paddingRight: "15px",
              }}
            >
              {loker?.trend_location?.map((item, index) => (
                <div key={index}>
                  <TextWrapper>
                    <TextName>{item.city}</TextName>
                    <TextDetail>{item.total}</TextDetail>
                  </TextWrapper>
                </div>
              ))}
            </ScrollWrapper>
          ) : (
            <NoData />
          )}
        </ContentWrapper>

        {/* Sektor trending */}
        <ContentWrapper style={{ width: "18%" }}>
          <TitleBar>Sektor Trending</TitleBar>
          {loker?.trend_sector?.length > 0 ? (
            <ScrollWrapper>
              {loker?.trend_sector?.map((item, index) => (
                <TextWrapper key={index}>
                  <TextName>{item.name}</TextName>
                  <TextDetail>{item.total}</TextDetail>
                </TextWrapper>
              ))}
            </ScrollWrapper>
          ) : (
            <NoData />
          )}
        </ContentWrapper>

        {/* Loker trending */}
        <ContentWrapper style={{ width: "45%" }}>
          <TitleBar>Loker Trending</TitleBar>
          <ScrollWrapper style={{ gap: "5px", paddingRight: "15px" }}>
            {loker?.trend_loker?.length > 0 ? (
              <React.Fragment>
                {loker?.trend_loker?.map((item, index) => (
                  <List
                    aria-label="contacts"
                    disablePadding
                    key={index}
                    onClick={() => {
                      dispatch(setSelectedData(item));
                      dispatch(getDetailLoker());
                    }}
                  >
                    <ItemCustom>
                      <ContentWrap>
                        <img alt="person-trending" src={imagePerson} />
                        <TextWrapper>
                          <TextName>{item.job_position}</TextName>
                          <TextDetail>{item.total}</TextDetail>
                        </TextWrapper>
                      </ContentWrap>
                      <img alt="arrow-right" src={iconArrowRight} />
                    </ItemCustom>
                  </List>
                ))}
              </React.Fragment>
            ) : (
              <NoData />
            )}
          </ScrollWrapper>
        </ContentWrapper>
      </RowWrapper>

      {/* Table */}
      <RowWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <Table headerContent={dataHeaderTable} dataContent={lokerHome} />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Info>
              Total Data : <b>{totalData}</b>
            </Info>
            <TextSeeAll onClick={() => dispatch(setActiveStep("all"))}>
              Lihat Semua
            </TextSeeAll>
          </div>
        </div>
      </RowWrapper>

      <PopUp
        open={confirmDelete}
        imgSrc={iconDelete}
        type="choice"
        width="350px"
        onClose={() => {
          setConfirmDelete(false);
          dispatch(executeLoker("4", "page"));
        }}
        onClickAction={() => {
          setConfirmDelete(false);
        }}
        title="Konfirmasi"
        info="Anda yakin menghapus loker?"
      />
    </Container>
  );
};

const Info = styled("p")(() => ({
  fontSize: "15px",
  color: "black",
  margin: "0px",
}));

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const TextSeeAll = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "15px",
  lineHeight: "18px",
  color: "#115AAA",
  cursor: "pointer",
}));

const RowWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
}));

const ContentWrapper = styled("div")(() => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
  height: "267px",
  gap: "15px",
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
}));

const TextWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  justifyContent: "center",
}));

const ScrollWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  height: "100%",
  overflowY: "scroll",
  marginRight: "-10px",
}));

const TitleBar = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "0.01em",
  color: "#000000",
}));

const ItemCustom = styled(ListItemButton)(() => ({
  padding: 0,
  display: "flex",
  justifyContent: "space-between",
}));

const ContentWrap = styled("div")(() => ({
  display: "flex",
  gap: "20px",
}));

const TextName = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "15px",
  letterSpacing: "0.01em",
  color: "#000000",
}));

const TextDetail = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "12px",
  letterSpacing: "0.01em",
  color: "#948F8F",
}));

export default DataLoker;
