/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import PageContainer from "../../../components/PageContainer";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Table from "../../../components/Table";
import PageNextPrev from "../../../components/PageNextPrev";
import Button from "../../../components/Button";
import InputText from "../../../components/InputText";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  actionNotif,
  addDataNotif,
  getDataNotif,
  setActivePage,
  setMaxPage,
  setPopupAdd,
} from "../../../store/actions/notifikasi";
import DropDown from "../../../components/DropDown";

const Notifikasi = () => {
  const dispatch = useDispatch();
  const dataHeaderAll = [
    {
      title: "No",
      key: "no",
      width: 30,
      render: (_, index) => <p>{(activePage - 1) * 10 + (index + 1)}</p>,
    },
    {
      title: "Judul",
      key: "titel",
    },
    {
      title: "Jenis",
      key: "type",
      render: (rowData) => (
        <p>
          {rowData.fitur === "1"
            ? "Sosial"
            : rowData.fitur === "2"
            ? "Grup"
            : "Proyek"}
        </p>
      ),
    },
    {
      title: "Isi Broadcast",
      key: "description",
    },
    {
      title: "Aksi",
      width: 100,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            borderRadius="5px"
            color="#FB7171"
            padding="0px 7px 0px 9px"
            onClick={() => dispatch(actionNotif(rowData.notif_id))}
          >
            Hapus
          </Button>
        </div>
      ),
      center: true,
    },
  ];

  const { dataNotif, popupAdd, activePage, totalPage, totalData } = useSelector(
    (state) => state.notifikasi,
    shallowEqual
  );

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [type, setType] = useState([]);

  useEffect(() => {
    dispatch(getDataNotif());
  }, [dispatch]);

  useEffect(() => {
    if (!popupAdd) {
      setTitle("");
      setDesc("");
      setType([]);
    }
  }, [popupAdd]);

  // Jika page yang aktif sama dengan total page, maka simpan maksimal page agar tidak menambah total page
  useEffect(() => {
    if (activePage === totalPage) {
      dispatch(setMaxPage(totalPage));
    }
  }, [activePage]);

  return (
    <PageContainer>
      <Container>
        {/* Title */}
        <Title title="Broadcast">
          <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
            <Button
              padding="12px"
              width="110px"
              borderRadius="5px"
              onClick={() => {
                dispatch(setPopupAdd(true));
              }}
            >
              <p
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "18px",
                  color: "white",
                }}
              >
                Tambah
              </p>
            </Button>
          </div>
        </Title>

        {/* Table */}
        <RowWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <Table headerContent={dataHeaderAll} dataContent={dataNotif} />
            <PageNextPrev
              totalData={totalData}
              totalPage={totalPage}
              activePage={activePage}
              onChange={(value) => {
                dispatch(
                  setActivePage(
                    value === "LEFT" ? activePage - 1 : activePage + 1
                  )
                );
                dispatch(getDataNotif());
              }}
            />
          </div>
        </RowWrapper>

        <PopUpDialog
          open={popupAdd}
          onClose={() => dispatch(setPopupAdd(false))}
          aria-labelledby="popup-dialog-title"
        >
          <DialogContentCustom>
            <InputWrap>
              <InpuTitle>Judul Broadcast</InpuTitle>
              <InputText
                placeholder="Masukan Judul Broadcast"
                borderRadius="0px"
                borderColor="#E4E4E4"
                backgroundColor="rgba(217, 217, 217, 0.1)"
                placeholderStyle={{ fontSize: "12px", lineHeight: "15px" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </InputWrap>
            <InputWrap>
              <InpuTitle>Jenis Broadcast</InpuTitle>
              <DropDown
                placeHolder="Pilih Jenis Broadcast"
                dropdownValue={type}
                handleChange={(e) => {
                  setType([e.target.value]);
                }}
                width="100%"
                listDropDown={[
                  {
                    label: "Grup",
                    value: "0",
                  },
                  {
                    label: "Sosial",
                    value: "1",
                  },
                  { label: "Proyek", value: "2" },
                ]}
              />
            </InputWrap>
            <InputWrap>
              <InpuTitle>Isi Broadcast</InpuTitle>
              <InputText
                placeholder="Silahkan isi pesan"
                borderRadius="0px"
                borderColor="#E4E4E4"
                backgroundColor="rgba(217, 217, 217, 0.1)"
                placeholderStyle={{ fontSize: "12px", lineHeight: "15px" }}
                multiline
                maxRows={6}
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </InputWrap>
          </DialogContentCustom>

          <DialogActionsCustom>
            <Button
              variant="contained"
              width="217px"
              borderRadius="5px"
              padding="13px"
              disabled={title === "" || desc === "" || type.length === 0}
              onClick={() => {
                dispatch(addDataNotif(title, type, desc));
              }}
            >
              <ButtonText>Buat Broadcast</ButtonText>
            </Button>
          </DialogActionsCustom>
        </PopUpDialog>
      </Container>
    </PageContainer>
  );
};

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const RowWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
}));

const DialogActionsCustom = styled(DialogActions)(() => ({
  padding: "0px",
  display: "flex",
  justifyContent: "end",
}));

const DialogContentCustom = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "20px",
  backgroundColor: "white",
  borderRadius: "10px",
}));

const InputWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "6px",
}));

const ButtonText = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "0.01em",
  color: "#FFFFFF",
}));

const InpuTitle = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  letterSpacing: "0.01em",
  fontSize: "12px",
  lineHeight: "15px",
  color: "#000000",
}));

const PopUpDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "674px",
    borderRadius: "10px",
    height: "fit-content",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    backgroundColor: "#F4F7FB",
  },
}));

export default Notifikasi;
