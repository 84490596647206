/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// DropDown Component
// --------------------------------------------------------
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import InputText from "../InputText";

const DropDown = ({
  imageCheck,
  listDropDown,
  size,
  dropdownValue,
  handleChange,
  placeHolder,
  placeHolderInside,
  color,
  backgroundColor,
  width,
  borderColor,
  isSearch,
}) => {
  const [search, setSearch] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);

  const stopImmediatePropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleSearch = (event) => {
    const regexSearch = new RegExp(event.target.value, "i");
    setSearch(event.target.value);
    const dataSearch = listDropDown.filter((itemList) =>
      itemList.label.match(regexSearch)
    );
    setSearchResult(dataSearch);
  };

  return (
    <FormControl
      className="m-dropdown"
      sx={{
        margin: "5px 0",
        width: width,
      }}
    >
      <SelectMUI
        value={dropdownValue}
        onChange={handleChange}
        defaultValue=""
        className={`select-dropdown ${size}`}
        displayEmpty
        MenuProps={{
          autoFocus: false,
          sx: {
            ".MuiPaper-root": {
              borderRadius: "5px",
              marginTop: "4px",
            },
            ".MuiMenuItem-root": {
              paddingRight: "50px",
              padding: "6px 16px",
            },
            ".Mui-selected": {
              backgroundColor: "gray",
              backgroundImage: `url(${imageCheck})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "98.5%",
              backgroundPositionY: "center",
            },
          },
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor,
            legend: {
              width: "inherit !important",
              maxWidth: "inherit !important",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#115AAA",
          },
          "&.MuiSelect-root": {
            borderRadius: "5px",
          },
          " .MuiSelect-select": {
            color: dropdownValue.length ? (color ? color : "#115AAA") : "gray",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "24px",
          },
          "&.MuiOutlinedInput-root": {
            borderRadius: "5px",
            backgroundColor,
            height: "40px",
            "&.md": {
              width: width,
              padding: "0px",
              borderRadius: "5px",
            },
          },
        }}
        renderValue={(selected) => {
          if (dropdownValue.length === 0) {
            return placeHolder;
          }
          return listDropDown[dropdownValue]?.label || "";
        }}
        inputProps={{
          "aria-label": "Without label",
        }}
      >
        {isSearch && (
          <CustomMenuItem
            onClickCapture={stopImmediatePropagation}
            onKeyDown={(e) => e.stopPropagation()}
            style={{
              height: 40,
              backgroundColor: "transparent",
              padding: "3px",
            }}
          >
            <InputText
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: "0 !important",
                  legend: {
                    width: "inherit !important",
                    maxWidth: "inherit !important",
                  },
                },
              }}
              size="sm"
              id="dropdown-input"
              placeholder="Pencarian"
              variant="outlined"
              value={search}
              autoFocus
              onChange={(e) => handleSearch(e)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </CustomMenuItem>
        )}

        {placeHolderInside && (
          <CustomMenuItem
            style={{
              color: `gray} !important`,
              fontSize: "0.938rem",
              fontWeight: "400",
              lineHeight: "1.124rem",
            }}
            disabled
            value=""
          >
            {placeHolder}
          </CustomMenuItem>
        )}

        {search === ""
          ? listDropDown.map((item) => (
              <CustomMenuItem key={item.label} value={item.value}>
                <React.Fragment>{item.label}</React.Fragment>
              </CustomMenuItem>
            ))
          : searchResult.map((item) => (
              <CustomMenuItem key={item.label} value={item.value}>
                <React.Fragment>{item.label}</React.Fragment>
              </CustomMenuItem>
            ))}
      </SelectMUI>
    </FormControl>
  );
};

DropDown.propTypes = {
  imageCheck: PropTypes.string,
  listDropDown: PropTypes.array,
  size: PropTypes.string,
  handleChange: PropTypes.func,
  dropdownValue: PropTypes.array,
  placeHolder: PropTypes.string,
  placeHolderInside: PropTypes.bool,
  searchPlaceHolderEn: PropTypes.string,
  backgroundColor: PropTypes.string,
  width: PropTypes.string,
  borderColor: PropTypes.string,
  isSearch: PropTypes.bool,
};

DropDown.defaultProps = {
  imageCheck: "",
  listDropDown: [
    { label: "Teknologi", value: 0 },
    { label: "Kesehatan", value: 1 },
    { label: "Konstruksi", value: 2 },
    { label: "Pertanian", value: 3 },
  ],
  size: "md",
  handleChange: () => {},
  dropdownValue: [],
  placeHolder: "",
  placeHolderInside: true,
  backgroundColor: "transparent",
  width: "255px",
  borderColor: "#115AAA",
  isSearch: false,
};

const CustomMenuItem = styled(MenuItem)(() => ({
  fontSize: "15px",
  fontWeight: 400,
}));

const SelectMUI = styled(Select)(() => ({
  ".MuiSvgIcon-root": {
    color: "#115abe",
  },
}));

export default DropDown;
