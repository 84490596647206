/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";
import Table from "../../../components/Table";

import ChartLine from "../../../components/ChartLine";
import NoData from "../../../components/NoData";
import moment from "moment";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDataPostingan,
  getGetListPostingan,
  setActiveStep,
} from "../../../store/actions/dataPostingan";

// Asset
import { ReactComponent as IconThumb } from "../../../assets/icon/icon-thumb.svg";
import { ReactComponent as IconComment } from "../../../assets/icon/icon-comment.svg";
import { ReactComponent as IconShare } from "../../../assets/icon/icon-share.svg";
import { allMonthShort } from "../../../utils/helpers";

const DataPostingan = () => {
  const dispatch = useDispatch();

  const { dataPostingan, dataHeader, listPost } = useSelector(
    (state) => state.dataPostingan,
    shallowEqual
  );

  const [dataSet, setDataSet] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    dispatch(getDataPostingan(true));
    dispatch(getGetListPostingan(true, true));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(dataPostingan).length > 0) {
      const data = [
        {
          label: "Pribadi",
          data: dataPostingan.chart
            .map((item) => parseInt(item.total_private))
            .slice(0, moment().month() + 1),
          borderColor: "#FC720E",
          backgroundColor: "#FC720E",
          tension: 0.3,
          pointStyle: "rectRounded",
        },
        {
          label: "Publik",
          data: dataPostingan.chart
            .map((item) => parseInt(item.total_public))
            .slice(0, moment().month() + 1),
          borderColor: "#FF4F79",
          backgroundColor: "#FF4F79",
          tension: 0.3,
          pointStyle: "rectRounded",
          borderDash: [4, 5],
        },
      ];
      setDataSet(data);
      setLabels(allMonthShort);
    }
  }, [dataPostingan]);

  return (
    <Container>
      {/* Title */}
      <Title title="Postingan Dilaporkan" />

      <RowWrapper>
        {/* Chart Line */}
        <ContentWrapper style={{ width: "65%" }}>
          <TitleBar>Pertumbuhan postingan</TitleBar>
          <ChartLine
            datasets={dataSet}
            options={chartLineOptions}
            labels={labels}
          />
        </ContentWrapper>

        {/* Postingan Terpopuler */}
        <ContentWrapper style={{ width: "35%", gap: "8px" }}>
          <TitleBar>Postingan Terpopuler</TitleBar>
          <LineWrap />
          {dataPostingan.populate !== undefined &&
          dataPostingan.populate?.length > 0 ? (
            <ScrollWrap>
              {dataPostingan?.populate.map((item, index) => (
                <ItemWrap key={index}>
                  <img
                    alt="profile-post"
                    src={item.foto_profile}
                    height={30}
                    width={30}
                  />
                  <InfoContentWrap>
                    <TextTitle
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "200px",
                      }}
                    >
                      {item.post_text}
                    </TextTitle>
                    <ContentWrap>
                      <TextName>{item.full_name}</TextName>
                      <IconWrap>
                        <IconThumb />
                        <TextTotal>{item.total_like}</TextTotal>
                      </IconWrap>
                      <IconWrap>
                        <IconComment />
                        <TextTotal>{item.total_comment}</TextTotal>
                      </IconWrap>
                      <IconWrap>
                        <IconShare />
                        <TextTotal>{item.total_share}</TextTotal>
                      </IconWrap>
                    </ContentWrap>
                  </InfoContentWrap>
                </ItemWrap>
              ))}
            </ScrollWrap>
          ) : (
            <NoData />
          )}
        </ContentWrapper>
      </RowWrapper>

      {/* Table */}
      <RowWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <Table headerContent={dataHeader} dataContent={listPost} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <TextSeeAll
              onClick={() => {
                dispatch(setActiveStep("all"));
                dispatch(getGetListPostingan(true));
              }}
            >
              Lihat Semua
            </TextSeeAll>
          </div>
        </div>
      </RowWrapper>
    </Container>
  );
};

const chartLineOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        font: {
          size: 14,
        },
        usePointStyle: true,
      },
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 13,
        },
        autoSkip: true,
        maxTicksLimit: 4,
      },
      position: "right",
    },
    y: {
      position: "right",
    },
  },
};

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const RowWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
}));

const LineWrap = styled("div")(() => ({
  border: "0.5px solid #B9B8B8",
  margin: "0px -15px 0px -15px",
  height: "1px",
}));

const ScrollWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "13px",
  overflow: "scroll",
}));

const ItemWrap = styled("div")(() => ({
  display: "flex",
  gap: "6px",
}));

const InfoContentWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "3px",
}));

const ContentWrap = styled("div")(() => ({
  display: "flex",
  gap: "7px",
  alignItems: "center",
}));

const IconWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "3px",
}));

const ContentWrapper = styled("div")(() => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
  height: "280px",
  gap: "15px",
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
  minWidth: "fit-content",
}));

const TitleBar = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "0.01em",
  color: "#000000",
}));

const TextTitle = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 300,
  fontSize: "12px",
  lineHeight: "15px",
}));

const TextTotal = styled("p")(() => ({
  fontSize: "10px",
  fontWeight: 600,
  color: "#115ABE",
}));

const TextName = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "10px",
  lineHeight: "12px",
  color: "#1A1818",
}));

const TextSeeAll = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "15px",
  lineHeight: "18px",
  color: "#115AAA",
  cursor: "pointer",
}));

export default DataPostingan;
