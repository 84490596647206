/* eslint-disable react-hooks/exhaustive-deps */
import { Menu } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Notification from "../../components/Notification";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Loader from "../Loader";
import PopUp from "../../components/PopUp";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getNotif,
  setPopupFailed,
  setPopupLogout,
} from "../../store/actions/pageContainer";

// Asset
import iconLogout from "../../assets/icon/icon-logout.png";
import iconFail from "../../assets/icon/business-icon-decline.png";

const PageContainer = ({ children }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();

  const idLocale = require("moment/locale/id");
  moment.updateLocale("id", idLocale);

  const dataUser = localStorage.getItem("dataUser");
  const [notification, setNotification] = useState(null);

  const { isLoading, isLogin, listNotif, popupLogout, popupFailed } =
    useSelector((state) => state.pageContainer, shallowEqual);

  useEffect(() => {
    if (!isLogin && !dataUser) {
      localStorage.removeItem("dataUser");
      dispatch(setPopupLogout(true));
    }
  }, [isLogin]);

  const MINUTE_MS = 20000;

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLogin || dataUser) {
        dispatch(getNotif());
      }
    }, MINUTE_MS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <React.Fragment>
      <Header
        onClick={(event) => setNotification(event.currentTarget)}
        isNotif={listNotif.some((item) => item.is_read_admin === "0")}
      />

      <Loader isLoading={isLoading > 0} />

      <PopUp
        open={popupLogout}
        buttonWord="Login"
        title="Sesi Berakhir"
        info="Silahkan login kembali untuk memulai sesi"
        width="350px"
        padding="60px 30px 25px 30px"
        imgSrc={iconLogout}
        onClickAction={() => {
          history.push("/nakerweb/login");
          dispatch(setPopupLogout(false));
        }}
        onClose={() => {
          dispatch(setPopupLogout(false));
          history.push("/nakerweb/login");
        }}
      />

      <PopUp
        open={popupFailed}
        imgSrc={iconFail}
        swapOptions
        onClose={() => dispatch(setPopupFailed(false))}
        onClickAction={() => dispatch(setPopupFailed(false))}
        title="Gagal"
        info="Aksi Anda Belum Berhasil. Coba Lagi. "
      />

      <div style={{ display: "flex", backgroundColor: "F4F7FB" }}>
        {location?.pathname !== "/nakerweb/my-team" && (
          <Sidebar activePage={0} />
        )}
        <div
          style={{
            padding: `70px 0px 0px ${
              location?.pathname !== "/nakerweb/my-team" ? "232px" : "0px"
            }`,
            width: "100%",
            backgroundColor: "#F4F7FB",
          }}
        >
          <div
            style={{
              padding: "17px 17px 100px 17px",
              backgroundColor: "#F4F7FB",
              width: "100%",
            }}
          >
            {children}
          </div>
        </div>
      </div>

      {/* Notifikasi */}
      <Menu
        id="menu-notif"
        anchorEl={notification}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          top: "40px",
          left: "10px",
          marginTop: "10px",
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
          "& .MuiList-root": {
            padding: "0px",
          },
        }}
        open={Boolean(notification)}
        onClose={() => setNotification(null)}
      >
        <Notification />
      </Menu>
    </React.Fragment>
  );
};

export default PageContainer;
