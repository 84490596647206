// Page Detail Proyek
// --------------------------------------------------------

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "../../../components/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "../../../components/Table";
import Dialog from "@mui/material/Dialog";
import Progress from "../../../components/Progress";

// Asset
import { ReactComponent as IconArrowRight } from "../../../assets/icon/icon-arrow-right.svg";
import { ReactComponent as IconBarWhite } from "../../../assets/icon/icon-bar-white.svg";
import { ReactComponent as IconBarBlue } from "../../../assets/icon/icon-bar-blue.svg";
import { ReactComponent as IconSuccessCheck } from "../../../assets/icon/icon-success-check.svg";
import eye from "../../../assets/icon/Eye.svg";
import back from "../../../assets/icon/Back.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  getDiskusi,
  getTabTask,
  setActiveStep,
} from "../../../store/actions/dataProyek";
import moment from "moment";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "block", alignItems: "center" }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          mr: 1,
          "& .MuiLinearProgress-bar": {
            backgroundColor: props.colors,
            borderRadius: "2px",
          },
        }}
      >
        <LinearProgress
          variant="determinate"
          style={{
            backgroundColor: "#E5E5E5",
            borderRadius: "2px",
          }}
          {...props}
        />
      </Box>
    </Box>
  );
}

const DetailProyek = ({ history }) => {
  const dataHeader = [
    {
      title: "No",
      key: "id_kategori",
      width: 20,
      center: true,
      render: (_, index) => <p>{index + 1}</p>,
    },
    {
      title: "Judul Task",
      width: 250,
      key: "nama_task",
    },
    {
      title: "Deskripsi",
      width: 250,
      render: (rowData) => (
        <p
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "2",
            overflow: "hidden",
          }}
        >
          {rowData.deskripsi_task}
        </p>
      ),
    },
    {
      title: "Petugas",
      key: "petugas_task",
      center: true,
    },
    {
      title: "Aksi",
      width: 100,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            borderRadius="5px"
            padding="0px 7px 0px 9px"
            onClick={() => {
              handlerDetail(rowData);
            }}
          >
            Detail
            <img src={eye} alt="eye" />
          </Button>
        </div>
      ),
      center: true,
    },
  ];

  const [tabSelect, setTabSelect] = useState(1);
  const [popupDetail, setPopupDetail] = useState(false);
  const [dataPopUp, setDataPopUp] = useState(null);
  const dispatch = useDispatch();

  const { info_proyek, right_page } = useSelector(
    (state) => state.dataProyek.detailProyek
  );
  const { tabTask, tabDiskusi } = useSelector((state) => state.dataProyek);

  const formatTime = (data) => {
    return moment(...data.split(","), "YYYY-MM-DD HH:mm:dd")
      .startOf("second")
      .fromNow();
  };

  const handlerDetail = (data) => {
    setPopupDetail(true);
    setDataPopUp(data);
  };

  return (
    <React.Fragment>
      <Container>
        <Title>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Back
              onClick={() => {
                if (history === "page") {
                  dispatch(setActiveStep("page"));
                } else {
                  dispatch(setActiveStep("all"));
                }
              }}
            >
              <img
                src={back}
                alt="icon-back"
                width={16}
                heigh={16}
                style={{ marginRight: "5px" }}
              />
              Kembali
            </Back>
            <div style={{ display: "flex" }}>
              {info_proyek.nama_proyek}
              <IconArrowRight />
            </div>
          </div>
          <StatusWrap>{info_proyek.status}</StatusWrap>
        </Title>
        <Tab>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              borderRadius="0px"
              padding="10px"
              border="1px solid #115ABE"
              color={tabSelect !== 1 ? "white" : "#115ABE"}
              sx={{
                color: `${tabSelect !== 1 && "#115ABE"}`,
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "15px",
              }}
              onClick={() => {
                setTabSelect(1);
                // dispatch(getDetailDataProyek(idProyek));
              }}
            >
              <div style={{ display: "flex", gap: "16px" }}>
                {tabSelect !== 1 ? <IconBarBlue /> : <IconBarWhite />}
                Ringkasan Proyek
              </div>
            </Button>
            <Button
              borderRadius="0px"
              padding="10px"
              border="1px solid #115ABE"
              color={tabSelect !== 2 ? "white" : "#115ABE"}
              sx={{
                color: `${tabSelect !== 2 && "#115ABE"}`,
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "15px",
              }}
              onClick={() => {
                setTabSelect(2);
                dispatch(getTabTask(info_proyek.id_proyek));
              }}
            >
              <div style={{ display: "flex", gap: "16px" }}>
                {tabSelect !== 2 ? <IconBarBlue /> : <IconBarWhite />}Daftar
                Tugas
              </div>
            </Button>
            <Button
              borderRadius="0px"
              padding="10px"
              border="1px solid #115ABE"
              color={tabSelect !== 3 ? "white" : "#115ABE"}
              sx={{
                color: `${tabSelect !== 3 && "#115ABE"}`,
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "15px",
              }}
              onClick={() => {
                setTabSelect(3);
                dispatch(getDiskusi());
              }}
            >
              <div style={{ display: "flex", gap: "16px" }}>
                {tabSelect !== 3 ? <IconBarBlue /> : <IconBarWhite />}
                Diskusi
              </div>
            </Button>
          </div>
        </Tab>

        {tabSelect === 1 ? (
          <div
            style={{
              display: "flex",
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div
              style={{
                width: "55%",
                padding: "10px 40px 10px 16px",
                display: "flex",
                flexDirection: "column",
                gap: "13px",
              }}
            >
              <p
                style={{
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "18px",
                  color: "#979696",
                }}
              >
                #Ringkasan
              </p>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#434242",
                  }}
                >
                  <div style={{ display: "flex", fontWeight: 500 }}>
                    <p style={{ width: "150px" }}>Pembuat Proyek</p>
                    <p>: {info_proyek.full_name}</p>
                  </div>
                  <div style={{ display: "flex", fontWeight: 500 }}>
                    <p style={{ width: "150px" }}>Step Proyek</p>
                    <p>: {info_proyek.kategori}</p>
                  </div>
                  <div style={{ display: "flex", fontWeight: 500 }}>
                    <p style={{ width: "150px" }}>Jenis Proyek</p>
                    <p>
                      : {info_proyek.privacy === "0" ? "Publik" : "Private"}
                    </p>
                  </div>
                  <div style={{ display: "flex", fontWeight: 500 }}>
                    <p style={{ width: "150px" }}>Tanggal Mulai</p>
                    <p>
                      : {moment(info_proyek.start_date).format("DD MMMM YYYY")}
                    </p>
                  </div>
                  <div style={{ display: "flex", fontWeight: 500 }}>
                    <p style={{ minWidth: "150px" }}>Tanggal Selesai</p>
                    <p>: {info_proyek.end_date}</p>
                  </div>
                  <div style={{ display: "flex", fontWeight: 500 }}>
                    <p style={{ minWidth: "150px" }}>Lokasi Proyek</p>
                    <p>: {info_proyek.lokasi}</p>
                  </div>
                </div>
                <Progress
                  progress={
                    info_proyek.progress === null ? "0" : info_proyek.progress
                  }
                />
              </div>
              <div style={{ display: "flex", fontWeight: 500 }}>
                <p style={{ minWidth: "150px" }}>Deskripsi Proyek</p>
                <p>{info_proyek.deskripsi}</p>
              </div>
            </div>
            <div style={{ border: "1px solid #F1F1F1" }} />
            <div
              style={{
                width: "45%",
                padding: "40px 14px 30px 13px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {right_page.total_task_done} / {right_page.total_task} Total
                  Task
                  <IconSuccessCheck height={24} width={24} />
                </div>
                <LinearProgressWithLabel
                  value={right_page.percent_task_done}
                  colors="#115ABE"
                />
              </div>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {right_page.progress_day} / {right_page.balance_day} Waktu
                  Tersisa
                  <IconSuccessCheck height={24} width={24} />
                </div>
                <LinearProgressWithLabel
                  value={right_page.percent_day}
                  colors="#219653"
                />
              </div>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Sudah Mulai
                  <IconSuccessCheck height={24} width={24} />
                </div>
                <LinearProgressWithLabel
                  value={right_page.task_start}
                  colors="#2D9CDB"
                />
              </div>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Belum Progress
                  <IconSuccessCheck height={24} width={24} />
                </div>
                <LinearProgressWithLabel
                  value={right_page.task_not_progress}
                  colors="#7B61FF"
                />
              </div>
            </div>
          </div>
        ) : tabSelect === 2 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <Table headerContent={dataHeader} dataContent={tabTask} />

            <p style={{ color: "#7B87AF" }}>
              Jumlah Tugas : {tabTask.length === "0" ? "-" : tabTask.length}
            </p>
          </div>
        ) : (
          <RowWrapper style={{ flexDirection: "column", gap: "0px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                backgroundColor: "#115AD2",
                padding: "15px 35px",
                color: "#FFFFFF",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Ruang Diskusi
            </div>

            <div
              style={{
                minHeight: "280px",
                height: "fit-content",
                backgroundColor: "#FAFAFA",
                padding: "27px 54px",
                gap: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Chat */}
              {tabDiskusi.map((item) => (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "16px",
                  }}
                >
                  <img
                    alt="profile-chat"
                    src={item.foto_profile}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100%",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#EDEDED",
                        padding: "6px 12px 10px 8px",
                        borderRadius: "5px",
                        maxWidth: "387px",
                        position: "relative",
                      }}
                    >
                      <p>{item.komentar}</p>
                    </div>
                    <span
                      style={{
                        marginRight: "8px",
                        color: "#B3B3B3",
                      }}
                    >
                      {formatTime(item.waktu_komen)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </RowWrapper>
        )}
        <PopUpDialog
          open={popupDetail}
          onClose={() => setPopupDetail(false)}
          aria-labelledby="popup-dialog-title"
        >
          <WrapperPopUp>
            <HeadingPopUp>Nama Task</HeadingPopUp>
            <span>{dataPopUp?.nama_task}</span>
          </WrapperPopUp>

          <WrapperPopUp>
            <HeadingPopUp>Deskripsi</HeadingPopUp>
            <span>{dataPopUp?.deskripsi_task}</span>
          </WrapperPopUp>

          <WrapperPopUp>
            <HeadingPopUp>Petugas Task</HeadingPopUp>
            <span>{dataPopUp?.petugas_task}</span>
          </WrapperPopUp>
        </PopUpDialog>
      </Container>
    </React.Fragment>
  );
};

const Title = styled("div")(() => ({
  display: "flex",
  gap: "10px",
  padding: "16px",
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
  backgroundColor: "white",
}));

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));

const Tab = styled("div")(() => ({
  display: "flex",
  backgroundColor: "#FFFFFF",
  boxShadow: "box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);",
  padding: "16px",
}));

const Back = styled("div")`
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
`;

const RowWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
}));

const StatusWrap = styled("div")(() => ({
  display: "flex",
  gap: "10px",
  padding: "4px 20px",
  backgroundColor: "#115ABE",
  borderRadius: "3px",
  color: "white",
  fontWeight: 500,
  fontSize: "13px",
  height: "fit-content",
  alignSelf: "end",
}));

const WrapperPopUp = styled("div")(() => ({}));

const HeadingPopUp = styled("p")(() => ({
  color: "#9c9898",
}));

const PopUpDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "674px",
    borderRadius: "10px",
    height: "fit-content",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    backgroundColor: "#F4F7FB",
  },
}));

export default DetailProyek;
