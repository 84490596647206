import { IconButton } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

// Asset
import { logo } from "../../assets/img";
import profile from "../../assets/img/profile-post.png";
import alarm from "../../assets/icon/icon-alarm.png";
import alarmOn from "../../assets/icon/icon-alarm-notif.png";
import { stringToColor } from "../../utils/helpers";

const Header = ({ onClick, isNotif }) => {
  const dataUser = localStorage.getItem("dataUser");

  return (
    <div style={{ position: "relative" }}>
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 16px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          height: "70px",
          position: "fixed",
          backgroundColor: "white",
          width: "100%",
          zIndex: 1000,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="" width={40} height={40} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div style={{ width: "10" }}>
            <p style={{ textAlign: "center" }}>
              {JSON.parse(dataUser)?.data?.full_name || "-"}
            </p>
            <p
              style={{
                fontSize: "12px",
                lineHeight: "15px",
                color: "#fff",
                backgroundColor: "#115abe",
                padding: "3px 10px",
                textAlign: "center",
                borderRadius: "5px",
                textTransform: "capitalize",
                width: "100%",
              }}
            >
              {JSON.parse(dataUser)?.data?.tipe_admin.tipe_name}
            </p>
          </div>

          {Boolean(JSON.parse(dataUser)?.data?.image) ? (
            <img src={profile} alt="" width={44} height={44} />
          ) : (
            <DefaultImgContainer
              style={{
                backgroundColor: stringToColor(
                  `${
                    JSON.parse(dataUser)?.data?.full_name !== null
                      ? JSON.parse(dataUser)?.data?.full_name
                      : "Blank"
                  }`
                ),
              }}
            >
              <DefaultImg>
                {JSON.parse(dataUser)?.data?.full_name !== null
                  ? JSON.parse(dataUser)
                      ?.data?.full_name?.slice(0, 1)
                      .toUpperCase()
                  : ""}
              </DefaultImg>
            </DefaultImgContainer>
          )}

          {/* Icon Bell */}
          <IconButton onClick={onClick}>
            <img
              alt="icon-alarm"
              src={isNotif ? alarmOn : alarm}
              height={24}
              width={24}
            />
          </IconButton>
        </div>
      </header>
    </div>
  );
};

const DefaultImgContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  border-radius: 30px;
`;

const DefaultImg = styled("p")`
  color: white;
  text-decoration: none;
  font-size: 13px;
`;

Header.propTypes = {
  onClick: PropTypes.func,
  isNotif: PropTypes.bool,
};

Header.defaultProps = {
  onClick: () => {},
  isNotif: true,
};

export default Header;
