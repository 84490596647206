import moment from "moment";
import {
  serviceActionTiket,
  serviceGetTiket,
  serviceReplyTiket,
  serviceTiket,
  serviceTiketDetail,
} from "../../utils/api";
import { setLoading } from "./pageContainer";
import eye from "../../assets/icon/Eye.svg";
import Button from "../../components/Button";

export const SET_ACTIVE_STEP_TIKET = "SET_ACTIVE_STEP_TIKET";
export const SET_HEADER_TIKET = "SET_HEADER_TIKET";
export const SET_LIST_TIKET = "SET_LIST_TIKET";
export const SET_SELECT_TIKET = "SET_SELECT_TIKET";
export const SET_DETAIL_TIKET = "SET_DETAIL_TIKET";
export const SET_LOADING_CHAT = "SET_LOADING_CHAT";
export const SET_ACTIVE_PAGE_TIKET = "SET_ACTIVE_PAGE_TIKET";
export const SET_TOTAL_PAGE_TIKET = "SET_TOTAL_PAGE_TIKET";
export const SET_MAX_PAGE_TIKET = "SET_MAX_PAGE_TIKET";
export const SET_TOTAL_DATA_TIKET = "SET_TOTAL_DATA_TIKET";
export const SET_HEADER_TABLE = "SET_HEADER_TABLE";
export const SET_SELECT_STATUS = "SET_SELECT_STATUS";
export const SET_KEYWORD = "SET_KEYWORD";

export const setKeyword = (payload) => ({
  type: SET_KEYWORD,
  payload,
});

export const setSelectStatus = (payload) => ({
  type: SET_SELECT_STATUS,
  payload,
});

export const setHeaderTable = (payload) => ({
  type: SET_HEADER_TABLE,
  payload,
});

export const setTotalData = (payload) => ({
  type: SET_TOTAL_DATA_TIKET,
  payload,
});

export const setMaxPage = (payload) => ({
  type: SET_MAX_PAGE_TIKET,
  payload,
});

export const setTotalPage = (payload) => ({
  type: SET_TOTAL_PAGE_TIKET,
  payload,
});

export const setActivePage = (payload) => ({
  type: SET_ACTIVE_PAGE_TIKET,
  payload,
});

export const setLoadingChat = (payload) => ({
  type: SET_LOADING_CHAT,
  payload,
});

export const setDetailTiket = (payload) => ({
  type: SET_DETAIL_TIKET,
  payload,
});

export const setSelectTiket = (payload) => ({
  type: SET_SELECT_TIKET,
  payload,
});

export const setListTiket = (payload) => ({
  type: SET_LIST_TIKET,
  payload,
});

export const setHeaderTiket = (payload) => ({
  type: SET_HEADER_TIKET,
  payload,
});

export const setActiveStep = (payload) => ({
  type: SET_ACTIVE_STEP_TIKET,
  payload,
});

export const getDataTiket = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceTiket();

    if (status === 200) {
      dispatch(setHeaderTiket(data.data.header_tiket));
      dispatch(setListTiket(data.data.list_tiket));

      const dataHeader = [
        {
          title: "No",
          key: "no",
          width: 30,
          center: true,
          render: (_, index) => <p>{index + 1}</p>,
        },
        {
          title: "Judul",
          key: "title",
        },
        {
          title: "Pengirim",
          key: "full_name",
        },
        {
          title: "Status",
          key: "status",
          width: 120,
          render: (rowData) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {rowData.id_status === "1" ? (
                <div
                  style={{
                    border: "1px solid #039C40",
                    backgroundColor: "#AEF8AC",
                    borderRadius: "30px",
                    padding: "4px 20px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#039C40",
                  }}
                >
                  {rowData.status_name}
                </div>
              ) : rowData.id_status === "2" ? (
                <div
                  style={{
                    border: "1px solid #C80707",
                    backgroundColor: "#F5969633",
                    borderRadius: "30px",
                    padding: "4px 20px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#C80707",
                  }}
                >
                  {rowData.status_name}
                </div>
              ) : (
                <div
                  style={{
                    border: "1px solid #115ABE",
                    backgroundColor: "rgba(181, 190, 233, 0.29)",
                    borderRadius: "30px",
                    padding: "4px 20px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#115ABE",
                  }}
                >
                  {rowData.status_name}
                </div>
              )}
            </div>
          ),
          center: true,
        },
        {
          title: "Tgl kirim",
          key: "create_at",
          render: (rowData) => (
            <p>{moment(rowData.create_at).format("DD MMM YYYY")}</p>
          ),
          center: true,
        },
        {
          title: "Balasan terakhir",
          key: "update_at",
          render: (rowData) => (
            <p>{moment(rowData.update_at).format("DD MMM YYYY")}</p>
          ),
          center: true,
        },
        {
          title: "Aksi",
          key: "action",
          render: (rowData) => (
            <Button
              variant="contained"
              borderRadius="5px"
              padding="0px 7px 0px 9px"
              onClick={() => {
                dispatch(setSelectTiket(rowData));
                dispatch(getTiketDetail());
              }}
            >
              Detail
              <img src={eye} alt="eye" />
            </Button>
          ),
          center: true,
        },
      ];
      dispatch(setHeaderTable(dataHeader));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getFilterTiket = () => async (dispatch, getState) => {
  const { activePage, totalPage, maxPage, activeStep, selectStatus, keyword } =
    getState().dataTiket;
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetTiket({
      page: activePage,
      limit: activePage === 1 ? 11 : 10,
      status: selectStatus[0] === 0 ? "" : selectStatus[0],
      keyword,
    });

    if (status === 200) {
      const dataHeader = [
        {
          title: "No",
          key: "no",
          width: 30,
          center: true,
          render: (_, index) => <p>{(activePage - 1) * 10 + (index + 1)}</p>,
        },
        {
          title: "Judul",
          key: "title",
        },
        {
          title: "Pengirim",
          key: "full_name",
        },
        {
          title: "Status",
          key: "status",
          width: 120,
          render: (rowData) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {rowData.id_status === "1" ? (
                <div
                  style={{
                    border: "1px solid #039C40",
                    backgroundColor: "#AEF8AC",
                    borderRadius: "30px",
                    padding: "4px 20px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#039C40",
                  }}
                >
                  {rowData.status_name}
                </div>
              ) : rowData.id_status === "2" ? (
                <div
                  style={{
                    border: "1px solid #C80707",
                    backgroundColor: "#F5969633",
                    borderRadius: "30px",
                    padding: "4px 20px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#C80707",
                  }}
                >
                  {rowData.status_name}
                </div>
              ) : (
                <div
                  style={{
                    border: "1px solid #115ABE",
                    backgroundColor: "rgba(181, 190, 233, 0.29)",
                    borderRadius: "30px",
                    padding: "4px 20px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#115ABE",
                  }}
                >
                  {rowData.status_name}
                </div>
              )}
            </div>
          ),
          center: true,
        },
        {
          title: "Tgl kirim",
          key: "create_at",
          render: (rowData) => (
            <p>{moment(rowData.create_at).format("DD MMM YYYY")}</p>
          ),
          center: true,
        },
        {
          title: "Balasan terakhir",
          key: "update_at",
          render: (rowData) => (
            <p>{moment(rowData.update_at).format("DD MMM YYYY")}</p>
          ),
          center: true,
        },
        {
          title: "Aksi",
          key: "action",
          render: (rowData) => (
            <Button
              variant="contained"
              borderRadius="5px"
              padding="0px 7px 0px 9px"
              onClick={() => {
                dispatch(setSelectTiket(rowData));
                dispatch(getTiketDetail());
              }}
            >
              Detail
              <img src={eye} alt="eye" />
            </Button>
          ),
          center: true,
        },
      ];
      dispatch(setHeaderTable(dataHeader));

      dispatch(setListTiket(data.data.slice(0, 10)));
      dispatch(setTotalData(data.total_data));

      // Jika data 10 atau lebih & maksimal page tidak sama dengan totalPage, maka ada kemungkinan data di next page, maka total page bertambah
      if (data.data.length > 10 && maxPage !== totalPage) {
        dispatch(setTotalPage(totalPage + 1));
      }

      if (activeStep === "page") {
        dispatch(setActiveStep("all"));
      }
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getTiketDetail = (noLoad) => async (dispatch, getState) => {
  const { selectTiket } = getState().dataTiket;
  try {
    if (!noLoad) {
      dispatch(setLoading(true));
    }
    const { status, data } = await serviceTiketDetail({
      id_tiket: selectTiket.id_tiket,
    });

    if (status === 200) {
      dispatch(setDetailTiket(data.data));
      dispatch(setActiveStep("masalah"));
    }
    if (!noLoad) {
      dispatch(setLoading(false));
    }
  } catch {
    if (!noLoad) {
      dispatch(setLoading(false));
    }
  }
};

export const getActionTiket = (action) => async (dispatch, getState) => {
  const { selectTiket } = getState().dataTiket;
  try {
    dispatch(setLoading(true));
    const { status } = await serviceActionTiket({
      action,
      id_tiket: selectTiket.id_tiket,
    });

    if (status === 200) {
      dispatch(setActiveStep("page"));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const replyChat = (text) => async (dispatch, getState) => {
  const { selectTiket } = getState().dataTiket;
  const id_user = JSON.parse(localStorage.getItem("dataUser")).data.id_user;
  try {
    dispatch(setLoadingChat(true));
    const { status } = await serviceReplyTiket({
      id_tiket: selectTiket.id_tiket,
      message: text,
      id_user,
      status: 1,
    });

    if (status === 200) {
      dispatch(getTiketDetail(true));
    }

    dispatch(setLoadingChat(false));
  } catch {
    dispatch(setLoadingChat(false));
  }
};
