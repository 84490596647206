import React, { useEffect, useState } from "react";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from "prop-types";
import "./RichText.css";

function RichText({ onConvert, initialValue }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (initialValue) {
      // Data string HTML
      const htmlString = initialValue;

      // Konversi string HTML menjadi ContentState
      const blocksFromHTML = convertFromHTML(htmlString);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      // Buat EditorState dari ContentState
      const initialEditorState = EditorState.createWithContent(contentState);

      // Set initial EditorState
      setEditorState(initialEditorState);
    }
  }, []);

  const createMarkup = (html) => {
    return html;
  };

  const onChange = (e) => {
    setEditorState(e);
    const html = convertToHTML(editorState.getCurrentContent());
    onConvert(createMarkup(html));
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onChange}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
    />
  );
}

RichText.prototype = {
  onConvert: PropTypes.func,
};

RichText.defaultProps = {
  onConvert: () => {},
};

export default RichText;
