import React from "react";

const NoData = ({ title }) => {
  return (
    <div
      style={{
        height: "100%",
        alignSelf: "center",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "revert",
      }}
    >
      {title ? title : "Tidak ada data"}
    </div>
  );
};

export default NoData;
