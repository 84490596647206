/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Title from "../../../components/Title";
import PageContainer from "../../../components/PageContainer";
import InputText from "../../../components/InputText";
import Button from "../../../components/Button";
import PopUp from "../../../components/PopUp";

// Redux
import {
  getSettingWeb,
  saveSettingWeb,
  setPopupSave,
} from "../../../store/actions/setting";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Compressor from "compressorjs";

// Asset
import IconPicture from "../../../assets/icon/icon-picture.png";
import iconUser from "../../../assets/icon/icon-edit.png";

const PengaturanPengguna = () => {
  const dispatch = useDispatch();

  const { dataWeb, popupSave } = useSelector(
    (state) => state.setting,
    shallowEqual
  );

  const convertToBase64 = (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const inputEl = useRef(null);
  const [data, setData] = useState([]);
  const [initial, setInitial] = useState([]);
  const [indexSelect, setIndexSelect] = useState();

  const onButtonClick = () => {
    inputEl.current.click();
  };

  const onChangeImage = async (event) => {
    const originalFile = event.target.files[0];
    const regex = /^.*base64,/;

    if (originalFile.type.split("/")[0] !== "image") {
      setInitial((prevInitial) =>
        prevInitial.map((item, ind) =>
          ind === indexSelect
            ? {
                ...item,
                image:
                  "`The file selected was a not an image type and will not be reduced.`",
              }
            : item
        )
      );
    } else {
      new Promise((resolve, reject) => {
        new Compressor(originalFile, {
          quality: 1,
          maxWidth: 1600,
          maxHeight: 1600,
          checkOrientation: false,
          success: resolve,
          error: reject,
        });
      })
        .then((blob) => convertToBase64(blob))
        .then((base64Image) => {
          const decoded = atob(base64Image.replace(regex, ""));
          setInitial((prevInitial) =>
            prevInitial.map((item, ind) =>
              ind === indexSelect
                ? {
                    ...item,
                    image: base64Image,
                    originalSize: originalFile.size,
                    reducedSize: decoded.length,
                  }
                : item
            )
          );
        });
    }
  };

  useEffect(() => {
    dispatch(getSettingWeb());
  }, []);

  useEffect(() => {
    if (dataWeb.length > 0) {
      setData(dataWeb);
      const init = [];
      dataWeb.forEach(() =>
        init.push({
          testType: "global",
          message: "",
          image: null,
          originalSize: null,
          reducedSize: null,
          files: [],
          image2: null,
          image2ReducedSize: null,
        })
      );
      setInitial(init);
    }
  }, [dataWeb]);

  const handleTitle = (e, id) => {
    setData((prev) =>
      prev.map((item, index) =>
        index === id ? { ...item, judul: e.target.value } : item
      )
    );
  };

  const handleDesc = (e, id) => {
    setData((prev) =>
      prev.map((item, index) =>
        index === id ? { ...item, content: e.target.value } : item
      )
    );
  };

  return (
    <PageContainer>
      <Container>
        <Title title="Pengaturan Web" />

        {data.length > 0 &&
          data.map((item, index) => (
            <RowWrapper key={index}>
              <p style={{ fontFamily: "Inter", fontSize: "20px" }}>
                {item?.name}
              </p>
              <CardWrapper>
                {/* <ContentWrapper> */}
                {item?.image !== null && (
                  <ImageWrapper>
                    <img
                      alt="img-post"
                      src={initial[index]?.image || item?.image}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    />
                  </ImageWrapper>
                )}
                {/* </ContentWrapper> */}
                <FormWrapper>
                  <InputWrapper>
                    <p>Judul</p>
                    <InputText
                      value={item?.judul}
                      type="title"
                      onChange={(e) => {
                        handleTitle(e, index);
                      }}
                      borderRadius="5px"
                      placeholder="Masukkan Judul"
                      height="40px"
                      backgroundColor="rgba(217, 217, 217, 0.1)"
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <p>Deskripsi</p>
                    <InputText
                      value={item?.content}
                      onChange={(e) => {
                        handleDesc(e, index);
                      }}
                      type="desc"
                      borderRadius="5px"
                      height="193px"
                      placeholder="Masukkan deskripsi"
                      backgroundColor="rgba(217, 217, 217, 0.1)"
                      multiline
                      maxRows={6}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "160px",
                          padding: "0px",
                          alignItems: "start",
                        },
                      }}
                    />
                  </InputWrapper>
                </FormWrapper>
              </CardWrapper>
              <ButtonWrapper onClick={() => setIndexSelect(index)}>
                {item?.image !== null && (
                  <Button style={{ width: "22.7%" }} onClick={onButtonClick}>
                    <img
                      src={IconPicture}
                      alt="icon-pic"
                      width={15}
                      height={15}
                    />
                    Ganti Foto
                  </Button>
                )}

                <input
                  type="file"
                  ref={inputEl}
                  accept=".jpg, .png"
                  style={{ visibility: "hidden", display: "none" }}
                  onChange={(e) => {
                    onChangeImage(e);
                  }}
                />
                <div
                  style={{
                    width: item?.image !== null ? "72.3%" : "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    onClick={() => {
                      dispatch(saveSettingWeb(data, index, initial));
                    }}
                  >
                    Simpan Perubahan
                  </Button>
                </div>
              </ButtonWrapper>
            </RowWrapper>
          ))}
      </Container>

      <PopUp
        open={popupSave}
        imgSrc={iconUser}
        width="350px"
        padding="60px 30px 25px 30px"
        onClose={() => dispatch(setPopupSave(false))}
        onClickAction={() => dispatch(setPopupSave(false))}
        title="Berhasil"
        info="Perubahan Berhasil Disimpan"
      />
    </PageContainer>
  );
};

// Style

const ButtonWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  gap: "20px",
}));

const InputWrapper = styled("div")(() => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  gap: "5px",
}));

const ImageWrapper = styled("div")(() => ({
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  display: "flex",
  height: "317px",
  width: "30%",
}));

const FormWrapper = styled("div")(() => ({
  display: "flex",
  height: "fit-content",
  flexDirection: "column",
  gap: "20px",
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  padding: "20px",
  width: "100%",
}));

const CardWrapper = styled("div")(() => ({
  display: "flex",
  width: "100%",
  height: "fit-content",
  gap: "20px",
}));

const RowWrapper = styled("div")(() => ({
  background: "#EDEDED",
  display: "flex",
  width: "100%",
  height: "fit-content",
  padding: "20px",
  flexDirection: "column",
  gap: "20px",
  borderRadius: "10px",
}));

const Container = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

export default PengaturanPengguna;
