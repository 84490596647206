/* eslint-disable react-hooks/exhaustive-deps */
// Page Data Loker
// --------------------------------------------------------

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PageContainer from "../../../components/PageContainer";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// Page
import DataLoker from "./DataLoker";
import SemuaLoker from "./SemuaLoker";
import DetailLoker from "./DetailLoker";
import FormulirLoker from "./FormulirLoker";
import { setActiveStep } from "../../../store/actions/dataLoker";

const Page = () => {
  const dispatch = useDispatch();
  const [history, setHistory] = useState("");
  const { activeStep } = useSelector((state) => state.dataLoker, shallowEqual);

  useEffect(() => {
    // Fungsi ini membuat loker kembali ke halaman utama saat menu lain dipilih
    return () => {
      dispatch(setActiveStep("page"));
    };
  }, []);

  return (
    <PageContainer>
      <Container>
        {activeStep === "page" && <DataLoker setHistory={setHistory} />}
        {activeStep === "all" && <SemuaLoker setHistory={setHistory} />}
        {activeStep === "detail" && <DetailLoker history={history} />}
        {activeStep === "formulir" && <FormulirLoker />}
      </Container>
    </PageContainer>
  );
};

const Container = styled("div")(() => ({
  width: "100%",
  height: "100%",
}));

export default Page;
