import {
  SET_DATA_WEB,
  SET_POPUP_SAVE,
  SET_DATA_USER,
  SET_TABLE_ROWS,
} from "../actions/setting";

const initialState = {
  dataWeb: [],
  popupSave: false,
  dataUser: [],
  tableRows: 10,
};

const setting = (state = initialState, action) => {
  switch (action.type) {
    case SET_TABLE_ROWS:
      return {
        ...state,
        tableRows: action.payload,
      };
    case SET_DATA_USER:
      return {
        ...state,
        dataUser: action.payload,
      };
    case SET_POPUP_SAVE:
      return {
        ...state,
        popupSave: action.payload,
      };
    case SET_DATA_WEB:
      return {
        ...state,
        dataWeb: action.payload,
      };
    default:
      return state;
  }
};

export default setting;
