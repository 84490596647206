import {
  serviceActionPengguna,
  serviceActionPostingan,
  serviceDetailPostingan,
  serviceGetDetailDataPengguna,
  serviceGetPostingan,
  servicePostingan,
} from "../../utils/api";
import { setLoading } from "./pageContainer";

import eye from "../../assets/icon/Eye.svg";
import { ReactComponent as IconThumb } from "../../assets/icon/icon-thumb.svg";
import { ReactComponent as IconComment } from "../../assets/icon/icon-comment.svg";
import { ReactComponent as IconShare } from "../../assets/icon/icon-share.svg";
import moment from "moment";
import Button from "../../components/Button";
import { setDetailDataPengguna } from "./dataPengguna";

export const SET_DATA_POSTINGAN = "SET_DATA_POSTINGAN";
export const SET_ACTIVE_STEP_POST = "SET_ACTIVE_STEP_POST";
export const SET_DATA_HEADER = "SET_DATA_HEADER";
export const SET_DETAIL_POSTINGAN = "SET_DETAIL_POSTINGAN";
export const SET_LIST_POST = "SET_LIST_POST";
export const SET_ACTIVE_PAGE_POST = "SET_ACTIVE_PAGE_POST";
export const SET_TOTAL_PAGE_POST = "SET_TOTAL_PAGE_POST";
export const SET_MAX_PAGE_POST = "SET_MAX_PAGE_POST";
export const SET_SELECT_POSTINGAN = "SET_SELECT_POSTINGAN";
export const SET_ID = "SET_ID";
export const SET_IS_STATUS = "SET_IS_STATUS";
export const SET_FITUR = "SET_FITUR";
export const SET_PRIVACY = "SET_PRIVACY";
export const SET_KEYWORD = "SET_KEYWORD";
export const SET_TOTAL_DATA_POST = "SET_TOTAL_DATA_POST";
export const SET_POPUP_STATUS = "SET_POPUP_STATUS";
export const REASON_NONACTIVE = "REASON_NONACTIVE";

export const setValueReason = (payload) => ({
  type: REASON_NONACTIVE,
  payload,
});

export const setPopupStatus = (payload) => ({
  type: SET_POPUP_STATUS,
  payload,
});

export const setTotalData = (payload) => ({
  type: SET_TOTAL_DATA_POST,
  payload,
});

export const setSelectPostingan = (payload) => ({
  type: SET_SELECT_POSTINGAN,
  payload,
});

export const setMaxPage = (payload) => ({
  type: SET_MAX_PAGE_POST,
  payload,
});

export const setTotalPage = (payload) => ({
  type: SET_TOTAL_PAGE_POST,
  payload,
});

export const setActivePage = (payload) => ({
  type: SET_ACTIVE_PAGE_POST,
  payload,
});

export const setListPost = (payload) => ({
  type: SET_LIST_POST,
  payload,
});

// for filter
export const setIsStatus = (payload) => ({
  type: SET_IS_STATUS,
  payload,
});

export const setFitur = (payload) => ({
  type: SET_FITUR,
  payload,
});

export const setPrivacy = (payload) => ({
  type: SET_PRIVACY,
  payload,
});

export const setKeyword = (payload) => ({
  type: SET_KEYWORD,
  payload,
});

export const setDataHeader = (payload) => ({
  type: SET_DATA_HEADER,
  payload,
});

export const setDataPostingan = (payload) => ({
  type: SET_DATA_POSTINGAN,
  payload,
});

export const setActiveStep = (payload) => ({
  type: SET_ACTIVE_STEP_POST,
  payload,
});

export const setDetailPostingan = (payload) => ({
  type: SET_DETAIL_POSTINGAN,
  payload,
});

export const setId = (payload) => ({
  type: SET_ID,
  payload,
});

export const getDetailDataPostingan =
  (is_report, select) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { status, data } = await serviceDetailPostingan({
        post_id: select.post_id,
        is_report,
      });

      dispatch(setId(select.post_id));

      if (status === 200) {
        dispatch(setDetailPostingan(data.data));
        dispatch(setActiveStep("detail"));
      }
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const getDataPostingan = (is_report) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await servicePostingan();

    dispatch(
      setDataHeader([
        {
          title: "No",
          key: "no",
          width: 30,
          render: (_, index) => <p>{index + 1}</p>,
        },
        {
          title: "Username",
          key: "full_name",
          width: 100,
        },
        {
          title: "Postingan",
          key: "post_text",
          width: 300,
          render: (row) => (
            <p
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "300px",
                whiteSpace: "nowrap",
              }}
            >
              {row.post_text}
            </p>
          ),
        },
        {
          title: "Interaksi",
          center: true,
          render: (rowData) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <p style={{ fontSize: "10px" }}>{rowData.total_like}</p>
                <IconThumb />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <p style={{ fontSize: "10px" }}>{rowData.total_comment}</p>
                <IconComment />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <p style={{ fontSize: "10px" }}>{rowData.total_share}</p>
                <IconShare />
              </div>
            </div>
          ),
        },
        {
          title: "Fitur",
          key: "fitur",
          render: (rowData) => (
            <p>{rowData.fitur === "1" ? "Sosial" : "Grup"}</p>
          ),
        },
        {
          title: "Status",
          center: true,
          width: 150,
          render: (rowData) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {rowData.id_status === "1" ? (
                <div
                  style={{
                    border: "1px solid #115abe",
                    backgroundColor: "#bde2fc",
                    borderRadius: "30px",
                    padding: "4px 20px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#115abe",
                    whiteSpace: "nowrap",
                  }}
                >
                  Dipublikasikan
                </div>
              ) : (
                rowData.id_status === "2" && (
                  <div
                    style={{
                      border: "1px solid #C80707",
                      backgroundColor: "#F5969633",
                      borderRadius: "30px",
                      padding: "4px 20px",
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "13px",
                      lineHeight: "16px",
                      color: "#C80707",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Dinonaktifkan
                  </div>
                )
              )}
            </div>
          ),
        },
        {
          title: "Tanggal",
          key: "create_at",
          render: (rowData) => (
            <p>{moment(rowData.create_at).format("DD MMMM YYYY")}</p>
          ),
        },
        {
          title: "Aksi",
          width: 100,
          render: (rowData) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                borderRadius="5px"
                padding="0px 7px 0px 9px"
                onClick={() =>
                  dispatch(getDetailDataPostingan(is_report, rowData))
                }
              >
                <p style={{ whiteSpace: "nowrap" }}>Detail</p>
                <img src={eye} alt="eye" />
              </Button>
            </div>
          ),
          center: true,
        },
      ])
    );

    if (status === 200) {
      dispatch(setDataPostingan(data.data));
      if (!is_report) {
        dispatch(setListPost(data.data.list_post));
      }
    }

    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getGetListPostingan =
  (is_report, no_page = false) =>
  async (dispatch, getState) => {
    const {
      maxPage,
      totalPage,
      isStatus,
      fitur,
      privacy,
      keyword,
      activePage,
    } = getState().dataPostingan;

    const { tableRows } = getState().setting;

    try {
      dispatch(setLoading(true));
      const { status, data } = await serviceGetPostingan({
        is_report,
        page: activePage,
        limit: tableRows,
        status: isStatus[0] === "0" ? "" : isStatus[0],
        fitur: fitur[0] === "0" ? "" : fitur[0],
        privacy: privacy[0] === "0" ? "" : privacy[0],
        keyword,
        short: "ASC",
      });

      console.log(data.data.list_post);

      if (status === 200) {
        dispatch(
          setDataHeader([
            {
              title: "No",
              key: "no",
              width: 30,
              render: (_, index) => (
                <p>{(activePage - 1) * tableRows + (index + 1)}</p>
              ),
            },
            {
              title: "Username",
              key: "full_name",
              width: 100,
            },
            {
              title: "Postingan",
              key: "post_text",
              width: 300,
              render: (row) => (
                <p
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "300px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {row.post_text}
                </p>
              ),
            },
            {
              title: "Interaksi",
              center: true,
              render: (rowData) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: "8px",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <p style={{ fontSize: "10px" }}>{rowData.total_like}</p>
                    <IconThumb />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <p style={{ fontSize: "10px" }}>{rowData.total_comment}</p>
                    <IconComment />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <p style={{ fontSize: "10px" }}>{rowData.total_share}</p>
                    <IconShare />
                  </div>
                </div>
              ),
            },
            {
              title: "Fitur",
              key: "fitur",
              render: (rowData) => (
                <p>{rowData.fitur === "1" ? "Sosial" : "Grup"}</p>
              ),
            },
            {
              title: "Status",
              center: true,
              width: 150,
              render: (rowData) => (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {rowData.id_status === "1" ? (
                    <div
                      style={{
                        border: "1px solid #115abe",
                        backgroundColor: "#bde2fc",
                        borderRadius: "30px",
                        padding: "4px 20px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                        fontSize: "13px",
                        lineHeight: "16px",
                        color: "#115abe",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Dipublikasikan
                    </div>
                  ) : (
                    rowData.id_status === "2" && (
                      <div
                        style={{
                          border: "1px solid #C80707",
                          backgroundColor: "#F5969633",
                          borderRadius: "30px",
                          padding: "4px 20px",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "13px",
                          lineHeight: "16px",
                          color: "#C80707",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Dinonaktifkan
                      </div>
                    )
                  )}
                </div>
              ),
            },
            {
              title: "Tanggal",
              key: "create_at",
              render: (rowData) => (
                <p>{moment(rowData.create_at).format("DD MMMM YYYY")}</p>
              ),
            },
            {
              title: "Aksi",
              width: 100,
              render: (rowData) => (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    borderRadius="5px"
                    padding="0px 7px 0px 9px"
                    onClick={() =>
                      dispatch(getDetailDataPostingan(is_report, rowData))
                    }
                  >
                    <p style={{ whiteSpace: "nowrap" }}>Detail</p>
                    <img src={eye} alt="eye" />
                  </Button>
                </div>
              ),
              center: true,
            },
          ])
        );
        dispatch(setListPost(data.data.list_post));
        dispatch(setTotalData(data.data.total_data));

        // Jika data 10 atau lebih & maksimal page tidak sama dengan totalPage, maka ada kemungkinan data di next page, maka total page bertambah
        if (
          data.data.list_post.length > tableRows &&
          maxPage !== totalPage &&
          !no_page
        ) {
          dispatch(setTotalPage(totalPage + 1));
        }

        if (data.data.total_data !== null) {
          dispatch(setMaxPage(Math.ceil(data.data.total_data / 10)));
          dispatch(setTotalPage(Math.ceil(data.data.total_data / 10)));
        }
      }
      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

export const actionPostingan =
  (post_id, action) => async (dispatch, getState) => {
    const { valueReason } = getState().dataPostingan;
    try {
      dispatch(setLoading(true));
      const { data } = await serviceActionPostingan({
        post_id,
        action,
        reason: valueReason,
      });
      if (data.status) {
        dispatch(setPopupStatus(true));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

export const getDetailDataPengguna = (id_user) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetDetailDataPengguna({
      id_user,
    });

    if (status === 200) {
      dispatch(setDetailDataPengguna(data.data));
    }
    dispatch(setActiveStep("pengguna"));
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const executeUser = () => async (dispatch, getState) => {
  const { id_user, is_status } = getState().dataPengguna.userDetail;
  try {
    dispatch(setLoading(true));
    const { status } = await serviceActionPengguna({
      user_id: id_user,
      action: is_status === "0" ? 3 : 0,
    });
    if (status === 200) {
      dispatch(setActiveStep("page"));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getAllPostingan = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    // const {status, data} = await
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};
