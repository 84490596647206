import {
  serviceActionNotifikasi,
  serviceAddNotifikasi,
  serviceNotifikasi,
} from "../../utils/api";
import { setLoading } from "./pageContainer";

export const SET_DATA_NOTIF = "SET_DATA_NOTIF";
export const SET_POPUP_ADD = "SET_POPUP_ADD";
export const SET_ACTIVE_PAGE_NOTIF = "SET_ACTIVE_PAGE_NOTIF";
export const SET_TOTAL_PAGE_NOTIF = "SET_TOTAL_PAGE_NOTIF";
export const SET_MAX_PAGE_NOTIF = "SET_MAX_PAGE_NOTIF";
export const SET_TOTAL_DATA_NOTIF = "SET_TOTAL_DATA_NOTIF";

export const setTotalData = (payload) => ({
  type: SET_TOTAL_DATA_NOTIF,
  payload,
});

export const setMaxPage = (payload) => ({
  type: SET_MAX_PAGE_NOTIF,
  payload,
});

export const setTotalPage = (payload) => ({
  type: SET_TOTAL_PAGE_NOTIF,
  payload,
});

export const setActivePage = (payload) => ({
  type: SET_ACTIVE_PAGE_NOTIF,
  payload,
});

export const setPopupAdd = (payload) => ({
  type: SET_POPUP_ADD,
  payload,
});

export const setDataNotif = (payload) => ({
  type: SET_DATA_NOTIF,
  payload,
});

export const getDataNotif = () => async (dispatch, getState) => {
  const { maxPage, totalPage, activePage } = getState().notifikasi;
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceNotifikasi({
      page: activePage,
      limit: activePage === 1 ? 11 : 10,
    });
    if (status === 200) {
      dispatch(setDataNotif(data.data.slice(0, 10)));
      dispatch(setTotalData(data.total_data));

      // Jika data 10 atau lebih & maksimal page tidak sama dengan totalPage, maka ada kemungkinan data di next page, maka total page bertambah
      if (data.data.length > 10 && maxPage !== totalPage) {
        dispatch(setTotalPage(totalPage + 1));
      }
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const addDataNotif = (title, type, desc) => async (dispatch) => {
  try {
    const user = JSON.parse(localStorage.getItem("dataUser"));
    dispatch(setLoading(true));
    const { status } = await serviceAddNotifikasi({
      title,
      id_user: user.data.id_user,
      description: desc,
      fitur: type[0],
    });
    if (status === 200) {
      dispatch(getDataNotif());
      dispatch(setPopupAdd(false));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const actionNotif = (id_notif) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceActionNotifikasi({
      id_notif,
    });
    if (status === 200) {
      dispatch(setPopupAdd(false));
      dispatch(getDataNotif());
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};
