// Page Data Proyek
// --------------------------------------------------------

import React, { useEffect, useState } from "react";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";
import Table from "../../../components/Table";
import Button from "../../../components/Button";
import Chart from "../../../components/Chart";
import ChartBar from "../../../components/ChartBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardDataProyek,
  getDetailDataProyek,
  getRequestDataProyek,
  setActiveStep,
} from "../../../store/actions/dataProyek";

// Asset
import eye from "../../../assets/icon/Eye.svg";
import { ReactComponent as IconGroup } from "../../../assets/icon/icon_group.svg";
import iconCalendar from "../../../assets/icon/icon-calendar.png";
import profile from "../../../assets/img/profile-post.png";
import { allMonthShort, stringToColor } from "../../../utils/helpers";
import moment from "moment";

const dataChart = [
  {
    data: [0, 0, 0],
    backgroundColor: ["#115ABE", "#FFBF0B", "#FA3E3E"],
    borderWidth: 0,
    label: [
      {
        title: "Aktif",
        color: "#115ABE",
      },
      {
        title: "Menunggu",
        color: "#FFBF0B",
      },
      {
        title: "Ditolak",
        color: "#FA3E3E",
      },
    ],
  },
];

const DataProyek = ({ setHistory, setIdProyek }) => {
  const dispatch = useDispatch();
  const { dashboardProyek } = useSelector((state) => state.dataProyek);

  const dataHeader = [
    {
      title: "No",
      key: "id_proyek",
      width: 10,
      center: true,
      render: (_, index) => <p>{index + 1}</p>,
    },
    {
      title: "Profile",
      key: "profile",
      render: (rowData) => (
        <>
          {rowData.cover ? (
            <img
              src={profile}
              alt="profile"
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "100%",
              }}
            />
          ) : (
            <DefaultImgContainer
              style={{
                backgroundColor: stringToColor(
                  `${rowData.full_name !== null ? rowData.full_name : "Blank"}`
                ),
              }}
            >
              <DefaultImg>
                {rowData.full_name.slice(0, 1).toUpperCase()}
              </DefaultImg>
            </DefaultImgContainer>
          )}
        </>
      ),
      width: 50,
      center: true,
    },
    {
      title: "Nama Proyek",
      width: 0,
      key: "nama_proyek",
    },
    {
      title: "Jenis Proyek",
      key: "privacy",
      center: true,
      render: (rowData) => (
        <span>{rowData.privacy === "1" ? "Private" : "Public"}</span>
      ),
    },
    {
      title: "Pembuat Proyek",
      key: "full_name",
    },
    {
      title: "Member",
      width: 70,
      center: true,
      render: (rowData) => (
        <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
          <IconGroup />
          <p>{rowData.total_member}</p>
        </div>
      ),
    },
    {
      title: "Tanggal Dibuat",
      key: "create_at",
      center: true,
      render: (rowData) => (
        <p>{moment(rowData.create_at).format("DD MMMM YYYY")}</p>
      ),
    },
    {
      title: "Status",
      width: 140,
      center: true,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: `1px solid ${
                rowData.id_status === "1"
                  ? "#115ABE"
                  : rowData.id_status === "2"
                  ? "#039C40"
                  : rowData.id_status === "3"
                  ? "#C80707"
                  : ""
              }`,
              backgroundColor:
                rowData.id_status === "1"
                  ? "rgba(181, 190, 233, 0.29)"
                  : rowData.id_status === "2"
                  ? "#AEF8AC"
                  : rowData.id_status === "3"
                  ? "rgba(245, 150, 150, 0.2)"
                  : "",
              borderRadius: "30px",
              padding: "4px 20px",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "16px",
              color:
                rowData.id_status === "1"
                  ? "#115ABE"
                  : rowData.id_status === "2"
                  ? "#039C40"
                  : rowData.id_status === "3"
                  ? "#C80707"
                  : "",
            }}
          >
            {rowData.status}
          </div>
        </div>
      ),
    },
    {
      title: "Aksi",
      width: 100,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            borderRadius="5px"
            padding="0px 7px 0px 9px"
            onClick={() => {
              actionHandler(rowData.id_proyek, rowData.id_status);
            }}
          >
            Detail
            <img src={eye} alt="eye" />
          </Button>
        </div>
      ),
      center: true,
    },
  ];

  const [pieChart, setPieChart] = useState(dataChart);
  const [statistic, setStatistic] = useState([]);

  useEffect(() => {
    dispatch(getDashboardDataProyek());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(dashboardProyek).length !== 0) {
      const { Aktif, Menunggu, Ditolak } = dashboardProyek.status;
      const total = parseInt(Aktif) + parseInt(Menunggu) + parseInt(Ditolak);
      const data = [
        (100 / total) * Aktif,
        (100 / total) * Menunggu,
        (100 / total) * Ditolak,
      ];
      setPieChart([
        {
          ...dataChart[0],
          empty: total === 0,
          data: total === 0 ? [100] : data,
          backgroundColor: [
            total === 0 ? "#bbbaba" : "#115ABE",
            "#FFBF0B",
            "#FA3E3E",
          ],
        },
      ]);

      const dataStat = [
        {
          label: "Private",
          data: dashboardProyek.chart.map((item) => parseInt(item.private)),
          backgroundColor: "#F86C6C",
          pointStyle: "rectRounded",
        },
        {
          label: "Public",
          data: dashboardProyek.chart.map((item) => parseInt(item.public)),
          backgroundColor: "#115ABE",
          pointStyle: "rectRounded",
        },
      ];

      setStatistic(dataStat);
    }
  }, [dashboardProyek]);

  const actionHandler = (id_proyek, status) => {
    if (status === "2" || status === "4") {
      dispatch(getDetailDataProyek(id_proyek));
    } else {
      dispatch(getRequestDataProyek(id_proyek));
    }
    setHistory("page");
  };

  return (
    <Container>
      {/* Title */}
      <Title title="Data Proyek" />

      <RowWrapper>
        <ContentWrapper style={{ width: "70%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "15px",
                  lineHeight: "18px",
                }}
              >
                Total Proyek
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "24px",
                }}
              >
                {dashboardProyek?.status?.total}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                gap: "3px",
                backgroundColor: "#DEE5EF",
                boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
                padding: "4px 6px",
                height: "fit-content",
                borderRadius: "3px",
                alignItems: "center",
              }}
            >
              <img
                alt="img-calendar"
                src={iconCalendar}
                width={18}
                height={18}
              />
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "10px",
                  lineHeight: "12px",
                }}
              >
                {new Date().getFullYear()}
              </p>
            </div>
          </div>
          <ChartBar datasets={statistic} labels={allMonthShort} />
        </ContentWrapper>
        <ContentWrapper style={{ width: "30%" }}>
          <TitleBar>Jumlah Status Proyek</TitleBar>
          <Chart
            data={pieChart}
            description={`${dashboardProyek?.status?.total} Proyek`}
          />
        </ContentWrapper>
      </RowWrapper>

      {/* Table */}
      <RowWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "10px",
          }}
        >
          <Table
            headerContent={dataHeader}
            dataContent={dashboardProyek?.list_proyek}
          />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "15px",
                lineHeight: "18px",
                color: "#115AAA",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setActiveStep("all"));
              }}
            >
              Lihat Semua
            </p>
          </div>
        </div>
      </RowWrapper>
    </Container>
  );
};

const DefaultImgContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 100%;
`;

const DefaultImg = styled("p")`
  color: white;
  text-decoration: none;
  font-size: 13px;
`;

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const RowWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
}));

const ContentWrapper = styled("div")(() => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
  height: "267px",
  gap: "15px",
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
  minWidth: "fit-content",
}));

const TitleBar = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "0.01em",
  color: "#000000",
}));

export default DataProyek;
