/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../../../store/actions/dataTiket";

// Component
import PageTiket from "./PageTiket";
import AllDataTiket from "./AllDataTiket";
import MasalahTiket from "./MasalahTiket";

const DataTiket = () => {
  const dispatch = useDispatch();

  const { activeStep } = useSelector((state) => state.dataTiket, shallowEqual);
  const [history, setHistory] = useState("page");

  useEffect(() => {
    // Fungsi ini membuat tiket kembali ke halaman utama saat menu lain dipilih
    return () => {
      dispatch(setActiveStep("page"));
    };
  }, []);

  return (
    <PageContainer>
      <React.Fragment>
        {activeStep === "page" && <PageTiket setHistory={setHistory} />}
        {activeStep === "all" && <AllDataTiket setHistory={setHistory} />}
        {activeStep === "masalah" && <MasalahTiket history={history} />}
      </React.Fragment>
    </PageContainer>
  );
};

export default DataTiket;
