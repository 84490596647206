import {
  serviceApproveLoker,
  serviceGetAllLoker,
  serviceGetCategory,
  serviceGetCity,
  serviceGetDataLoker,
  serviceGetDetailLoker,
  serviceGetJobType,
  serviceLokerExport,
  serviceLokerFilter,
} from "../../utils/api";
import { setLoading, setValueReason } from "./pageContainer";
import { capFirstLetter } from "../../utils/helpers";

export const SET_ALL_LOKER = "SET_ALL_LOKER";
export const SET_SECTOR = "SET_SECTOR";
export const SET_HEADER_TABLE = "SET_HEADER_TABLE";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_DROPDOWN_SECTOR = "SET_DROPDOWN_SECTOR";
export const SET_VALUE_SECTOR = "SET_VALUE_SECTOR";
export const SET_DROPDOWN_JOB_TYPE = "SET_DROPDOWN_JOB_TYPE";
export const SET_VALUE_JOB_TYPE = "SET_VALUE_JOB_TYPE";
export const SET_VALUE_STATUS = "SET_VALUE_STATUS";
export const SET_SEARCH = "SET_SEARCH";
export const SET_ACTIVE_STEP_LOKER = "SET_ACTIVE_STEP_LOKER";
export const SET_HEADER_TABLE_HOME = "SET_HEADER_TABLE_HOME";
export const SET_LOKER_HOME = "SET_LOKER_HOME";
export const SET_LOKER = "SET_LOKER";
export const SET_ALL_CITY = "SET_ALL_CITY";
export const VALUE_CITY = "VALUE_CITY";
export const SET_SELECTED_DATA = "SET_SELECTED_DATA";
export const SET_POPUP_STATUS = "SET_POPUP_STATUS";
export const SET_DETAIL_LOKER = "SET_DETAIL_LOKER";
export const SET_ACTIVE_PAGE_LOKER = "SET_ACTIVE_PAGE_LOKER";
export const SET_TOTAL_PAGE_LOKER = "SET_TOTAL_PAGE_LOKER";
export const SET_MAX_PAGE_LOKER = "SET_MAX_PAGE_LOKER";
export const SET_TOTAL_DATA_LOKER = "SET_TOTAL_DATA_LOKER";
export const SET_LOKER_FILTER_ROWS = "SET_LOKER_FILTER_ROWS";

export const setFilterLokerRows = (payload) => ({
  type: SET_LOKER_FILTER_ROWS,
  payload,
});

export const setTotalData = (payload) => ({
  type: SET_TOTAL_DATA_LOKER,
  payload,
});

export const setMaxPage = (payload) => ({
  type: SET_MAX_PAGE_LOKER,
  payload,
});

export const setTotalPage = (payload) => ({
  type: SET_TOTAL_PAGE_LOKER,
  payload,
});

export const setActivePage = (payload) => ({
  type: SET_ACTIVE_PAGE_LOKER,
  payload,
});

export const setDetailLoker = (payload) => ({
  type: SET_DETAIL_LOKER,
  payload,
});

export const setPopupStatus = (payload) => ({
  type: SET_POPUP_STATUS,
  payload,
});

export const setSelectedData = (payload) => ({
  type: SET_SELECTED_DATA,
  payload,
});

export const setValueCity = (payload) => ({
  type: VALUE_CITY,
  payload,
});

export const setAllCity = (payload) => ({
  type: SET_ALL_CITY,
  payload,
});

export const setLoker = (payload) => ({
  type: SET_LOKER,
  payload,
});

export const setActiveStep = (payload) => ({
  type: SET_ACTIVE_STEP_LOKER,
  payload,
});

export const setSearch = (payload) => ({
  type: SET_SEARCH,
  payload,
});

export const setValueStatus = (payload) => ({
  type: SET_VALUE_STATUS,
  payload,
});

export const setValueJobType = (payload) => ({
  type: SET_VALUE_JOB_TYPE,
  payload,
});

export const setDropDownJobType = (payload) => ({
  type: SET_DROPDOWN_JOB_TYPE,
  payload,
});

export const setAllLoker = (payload) => ({
  type: SET_ALL_LOKER,
  payload,
});

export const setLokerHome = (payload) => ({
  type: SET_LOKER_HOME,
  payload,
});

export const setSector = (payload) => ({
  type: SET_SECTOR,
  payload,
});

export const setHeaderTableHome = (payload) => ({
  type: SET_HEADER_TABLE_HOME,
  payload,
});

export const setHeaderTable = (payload) => ({
  type: SET_HEADER_TABLE,
  payload,
});

export const setPagination = (payload) => ({
  type: SET_PAGINATION,
  payload,
});

export const setDropDownSector = (payload) => ({
  type: SET_DROPDOWN_SECTOR,
  payload,
});

export const setValueSector = (payload) => ({
  type: SET_VALUE_SECTOR,
  payload,
});

export const getCategory = () => async (dispatch) => {
  try {
    const { status, data } = await serviceGetCategory();

    if (status === 200) {
      dispatch(setSector(data.data));
      let count = 1;
      let sector = [
        {
          label: "Semua Sektor",
          value: 0,
          sektor_id: "",
        },
      ];
      data.data.forEach((item) => {
        sector.push({
          label: item.name,
          value: count,
          sektor_id: item.sektor_id,
        });
        count = count + 1;
      });
      dispatch(setDropDownSector(sector));
    }
  } catch {}
};

export const getJobType = () => async (dispatch) => {
  try {
    const { status, data } = await serviceGetJobType();

    if (status === 200) {
      let count = 1;
      let sector = [
        {
          label: "Semua Kategori",
          value: 0,
          id: "",
        },
      ];
      data.data.forEach((item) => {
        sector.push({
          label: item.job_type_name,
          value: count,
          id: item.id,
        });
        count = count + 1;
      });
      dispatch(setDropDownJobType(sector));
    }
  } catch {}
};

export const getAllCity = () => async (dispatch) => {
  try {
    const { status, data } = await serviceGetCity({
      province_id: 0,
    });

    if (status === 200) {
      let count = 1;
      let allCity = [
        {
          kode: 0,
          label: "Semua Kab/Kota",
          value: 0,
        },
      ];
      data.data.forEach((item) => {
        allCity.push({
          ...item,
          label: capFirstLetter(item.nama),
          value: count,
        });
        count = count + 1;
      });
      dispatch(setAllCity(allCity));
    }
  } catch {}
};

export const getLoker = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetDataLoker({});

    if (status === 200) {
      dispatch(setLoker(data.data));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getDetailLoker = () => async (dispatch, getState) => {
  const { loker_id } = getState().dataLoker.selectedData;
  let sector = getState().dataLoker.sector;
  if (sector.length === 0) {
    await dispatch(getCategory());
    sector = getState().dataLoker.sector;
  }

  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetDetailLoker({
      loker_id,
    });

    if (status === 200) {
      dispatch(
        setDetailLoker({ ...data.data.info_loker, ...data.data.info_pic })
      );
      dispatch(setActiveStep("detail"));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getDataLoker = () => async (dispatch, getState) => {
  const pagination = getState().dataLoker.pagination;
  let sector = getState().dataLoker.sector;
  if (sector.length === 0) {
    await dispatch(getCategory());
    sector = getState().dataLoker.sector;
  }

  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetAllLoker({
      page: 1,
    });

    if (status === 200) {
      dispatch(setLokerHome(data.data));
      dispatch(setTotalData(data.total));

      dispatch(
        setPagination({
          ...pagination,
          count: Math.ceil(data.total / 10),
          totalData: data.total,
          currentData: data.data.length,
        })
      );
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const getLokerFilter = () => async (dispatch, getState) => {
  let sector = getState().dataLoker.sector;
  if (sector.length === 0) {
    await dispatch(getCategory());
    sector = getState().dataLoker.sector;
  }

  const {
    valueStatus,
    dropDownJobType,
    valueJobType,
    dropDownSector,
    valueSector,
    search,
    allCity,
    valueCity,
    activePage,
    // totalPage,
    // maxPage,
  } = getState().dataLoker;

  const { tableRows } = getState().setting;

  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceLokerFilter({
      page: activePage,
      limit: tableRows,
      sector: dropDownSector[valueSector]?.sektor_id || "",
      job_type: dropDownJobType[valueJobType[0]]?.id || "",
      city: allCity[valueCity[0]]?.kode || "",
      status: valueStatus[0],
      keyword: search,
    });

    if (status === 200) {
      dispatch(setAllLoker(data.data));
      dispatch(setTotalData(parseInt(data.total_data)));

      // Jika data 10 atau lebih & maksimal page tidak sama dengan totalPage, maka ada kemungkinan data di next page, maka total page bertambah
      dispatch(setTotalPage(Math.ceil(parseInt(data.total_data) / 10)));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const executeLoker =
  (action, page = "all") =>
  async (dispatch, getState) => {
    const selectData = getState().dataLoker.selectedData;
    const { valueReason } = getState().pageContainer;

    try {
      dispatch(setLoading(true));
      const { status, data } = await serviceApproveLoker({
        loker_id: selectData.loker_id,
        // action 3 untuk hapus
        status: action,
        feature: "3",
        reason: valueReason,
        referenc_id: selectData.loker_id,
      });
      dispatch(setLoading(false));

      if (status === 200) {
        if (data.message === "Berhasil disetujui") {
          dispatch(setPopupStatus(1));
        } else if (data.message === "Berhasil ditolak") {
          dispatch(setPopupStatus(2));
        }

        dispatch(setValueReason(""));
        if (page === "all") {
          dispatch(getLokerFilter());
        } else {
          dispatch(getDataLoker());
        }
        // dispatch(setActiveStep(page));
      }
    } catch {
      dispatch(setLoading(false));
    }
  };

export const getLokerExport = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceLokerExport({});
    if (status === 200) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Loker.xlsx");
      document.body.appendChild(link);
      link.click();
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};
