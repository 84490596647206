/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";
import Table from "../../../components/Table";
import PageNextPrev from "../../../components/PageNextPrev";
import Button from "../../../components/Button";
import InputAdornment from "@mui/material/InputAdornment";
import InputText from "../../../components/InputText";
import Colors from "../../../utils/helpers/colors";
import Menu from "@mui/material/Menu";
import DropDown from "../../../components/DropDown";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getAllGrup,
  getDetailGrup,
  getRequestGrup,
  grupExport,
  setActivePage,
  setActiveStep,
  setCategory,
  setIsStatus,
  // setMaxPage,
  setPrivacy,
  setSelectGrup,
} from "../../../store/actions/dataGroup";

// Asset
import eye from "../../../assets/icon/Eye.svg";
import iconSlider from "../../../assets/icon/icon-slider.png";
import iconSearch from "../../../assets/icon/icon-search.png";
import iconXls from "../../../assets/icon/icon-xls-white.png";
import { ReactComponent as IconGroup } from "../../../assets/icon/icon_group.svg";
import { capFirstLetter, stringToColor } from "../../../utils/helpers";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { setTableRows } from "../../../store/actions/setting";

const SemuaGroup = ({ setHistory }) => {
  const dispatch = useDispatch();

  // useState
  const [menuFilter, setMenuFilter] = useState(null);

  // state for filter
  const { privacy, isStatus, category, activePage, totalPage, totalData } =
    useSelector((state) => state.dataGroup);

  // state for search form
  const [keyword, setKeyword] = useState("");

  const { allGroup } = useSelector((state) => state.dataGroup, shallowEqual);

  const { tableRows } = useSelector((state) => state.setting);

  const dataHeader = [
    {
      title: "No",
      key: "group_id",
      width: 30,
      render: (_, index) => <p>{(activePage - 1) * tableRows + (index + 1)}</p>,
    },
    {
      title: "Profile",
      width: 40,
      center: true,
      render: (rowData) => (
        <React.Fragment>
          {rowData.group_cover !== null ? (
            <img
              alt="profile-chat"
              src={rowData.group_cover}
              height={24}
              width={24}
              style={{ borderRadius: "20px" }}
            />
          ) : (
            <DefaultImgContainer
              style={{
                backgroundColor: stringToColor(
                  `${rowData.full_name !== null ? rowData.full_name : "Blank"}`
                ),
              }}
            >
              <DefaultImg>
                {rowData.full_name.slice(0, 1).toUpperCase()}
              </DefaultImg>
            </DefaultImgContainer>
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Nama Grup",
      key: "group_name",
    },
    {
      title: "Jenis Group",
      key: "category_name",
    },
    {
      title: "Anggota",
      render: (rowData) => (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <IconGroup />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              whiteSpace: "nowrap",
            }}
          >
            <p>Laki-laki : {rowData.total_male}</p>
            <p>Perempuan : {rowData.total_famale}</p>
            <p>Total : {rowData.total_member}</p>
          </div>
        </div>
      ),
      center: true,
    },
    {
      title: "Kota",
      render: (rowData) => (
        <div>
          <p>{rowData.city ? capFirstLetter(rowData.city) : "-"}</p>
        </div>
      ),
    },
    {
      title: "Jumlah Sektor",
      render: (rowData) => (
        <div>
          <p>{rowData.total_sector || "0"}</p>
        </div>
      ),
    },
    {
      title: "Postingan",
      key: "total_post",
      width: 40,
      center: true,
      render: (rowData) => <p>{rowData.total_post + " Post"}</p>,
    },
    {
      title: "Status",
      center: true,
      width: 150,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {
            <div
              style={{
                border:
                  rowData.group_status === "1"
                    ? "1px solid #039C40"
                    : rowData.group_status === "2"
                    ? "1px solid #115ABE"
                    : rowData.group_status === "3"
                    ? "1px solid #C80707"
                    : "1px solid #C80707",
                backgroundColor:
                  rowData.group_status === "1"
                    ? "#AEF8AC"
                    : rowData.group_status === "2"
                    ? "rgba(181, 190, 233, 0.29)"
                    : "rgba(245, 150, 150, 0.2)",
                borderRadius: "30px",
                padding: "4px 20px",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                color:
                  rowData.group_status === "1"
                    ? "#039C40"
                    : rowData.group_status === "2"
                    ? "#115ABE"
                    : "#C80707",
              }}
            >
              {rowData.group_status === "1"
                ? "Aktif"
                : rowData.group_status === "2"
                ? "Menunggu"
                : rowData.group_status === "3"
                ? "Ditolak"
                : "Dinonaktifkan"}
            </div>
          }
        </div>
      ),
    },
    {
      title: "Aksi",
      width: 100,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            borderRadius="5px"
            padding="0px 7px 0px 9px"
            onClick={() => {
              actionHandler(rowData.group_id, rowData.group_status);
              dispatch(setSelectGrup(rowData));
            }}
          >
            Detail
            <img src={eye} alt="eye" />
          </Button>
        </div>
      ),
      center: true,
    },
  ];

  useEffect(() => {
    dispatch(getAllGrup(keyword));
  }, [dispatch]);

  const actionHandler = (group_id, group_status) => {
    if (group_status === "1" || group_status === "4") {
      dispatch(getDetailGrup(group_id));
    } else {
      dispatch(getRequestGrup(group_id));
    }
    setHistory("all");
  };

  const searchHandler = (event) => {
    if (event.key === "Enter") {
      dispatch(getAllGrup(keyword));
    }
  };

  return (
    <Container>
      {/* Title */}
      <Title
        title="Semua Grup"
        withBack
        onBack={() => dispatch(setActiveStep("page"))}
      >
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          {/* Pencarian */}
          <InputText
            type="text"
            value={keyword}
            onKeyDown={searchHandler}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="pencarian"
            noPadding
            width="175px"
            borderRadius="5px"
            borderColor="#115AAA"
            iconAdornment={
              <InputAdornment
                position="start"
                className="inputAdorment"
                sx={{
                  zIndex: 200,
                  height: "40px",
                  justifyContent: "center",
                  width: "67px",
                  maxHeight: "56px",
                  borderRadius: "5px 0px 0px 5px",
                  backgroundColor: Colors.primary.hard,
                  ".MuiTypography-root": {
                    color: "white",
                    fontSize: "0.938rem",
                    fontWeight: "400",
                    lineHeight: "1.124rem",
                  },
                }}
              >
                <img
                  src={iconSearch}
                  alt="icon-search"
                  width={24}
                  height={24}
                />
              </InputAdornment>
            }
          />
          {/* Tombol Export */}
          <div style={{ display: "flex", gap: "2px" }}>
            <Button
              onClick={() => dispatch(grupExport())}
              padding="8px"
              width="fit-content"
              borderRadius="5px"
            >
              Export
              <img
                src={iconXls}
                style={{ marginLeft: "5px" }}
                alt="icon-slider"
                width={24}
                height={24}
              />
            </Button>
          </div>

          {/* Tombol Filter */}
          <Button
            padding="8px"
            width="fit-content"
            onClick={(event) => {
              setMenuFilter(event.currentTarget);
            }}
          >
            <img src={iconSlider} alt="icon-slider" width={24} height={24} />
          </Button>
          <Menu
            id="menu-appbar"
            anchorEl={menuFilter}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              top: "40px",
              left: "10px",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "15px",
              lineHeight: "18px",
              color: "#000000",
              "& .MuiPaper-root": {
                borderRadius: "10px",
              },
              "& .MuiList-root": {
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              },
            }}
            open={Boolean(menuFilter)}
            onClose={() => setMenuFilter(null)}
          >
            <DropdownWrap>
              <p>Pilih jenis grup :</p>
              <DropDown
                dropdownValue={privacy}
                placeHolder="Pilih Jenis Grup"
                handleChange={(e) => {
                  dispatch(setPrivacy([e.target.value]));
                  dispatch(getAllGrup(keyword));
                }}
                listDropDown={[
                  {
                    label: "Semua Jenis Grup",
                    value: 0,
                  },
                  {
                    label: "Private",
                    value: 1,
                  },
                  { label: "Publik", value: 2 },
                ]}
              />
            </DropdownWrap>
            <DropdownWrap>
              <p>Pilih Kategori grup :</p>
              <DropDown
                placeHolder="Pilih Kategori Grup"
                dropdownValue={category}
                handleChange={(e) => {
                  dispatch(setCategory([e.target.value]));
                  dispatch(getAllGrup(keyword));
                }}
                listDropDown={[
                  {
                    label: "Semua Kategori",
                    value: "0",
                  },
                  {
                    label: "Serikat Pekerja",
                    value: "1",
                  },
                  { label: "Perusahaan", value: "2" },
                  { label: "Komunitas", value: "3" },
                ]}
              />
            </DropdownWrap>
            <DropdownWrap>
              <p>Pilih status grup :</p>
              <DropDown
                dropdownValue={isStatus}
                handleChange={(e) => {
                  dispatch(setIsStatus(e.target.value));
                  dispatch(getAllGrup(keyword));
                }}
                placeHolder="Pilih Status Grup"
                listDropDown={[
                  {
                    label: "Pilih Semua Status",
                    value: "0",
                  },
                  {
                    label: "Aktif",
                    value: "1",
                  },
                  { label: "Menunggu", value: "2" },
                  { label: "Tidak Aktif", value: "3" },
                  { label: "Dinonaktifkan", value: "4" },
                ]}
              />
            </DropdownWrap>
          </Menu>
        </div>
      </Title>

      {/* Table */}
      <RowWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <Table headerContent={dataHeader} dataContent={allGroup} />

          <div style={{ width: "100px", alignSelf: "flex-end" }}>
            <FormControl fullWidth>
              <InputLabel id="Select Rows Data">Rows</InputLabel>
              <Select
                labelId="Select Rows Data"
                id="rowsSelect"
                label="Rows"
                onChange={(e) => {
                  dispatch(setTableRows(e.target.value));
                  dispatch(getAllGrup(keyword));
                }}
                value={tableRows}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>

          <PageNextPrev
            totalPage={totalPage}
            activePage={activePage}
            totalData={totalData}
            onChange={(value) => {
              dispatch(
                setActivePage(
                  value === "LEFT" ? activePage - 1 : activePage + 1
                )
              );
              dispatch(getAllGrup(keyword));
            }}
          />
        </div>
      </RowWrapper>
    </Container>
  );
};

const DefaultImgContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 100%;
`;

const DefaultImg = styled("p")`
  color: white;
  text-decoration: none;
  font-size: 13px;
`;

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const DropdownWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
}));

const RowWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
}));

export default SemuaGroup;
