// Page Profil Grup
// --------------------------------------------------------

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import PopUp from "../../../components/PopUp";
import Chart from "../../../components/Chart";
import ChartLine from "../../../components/ChartLine";
import moment from "moment";
import PopUpPenolakan from "../../../components/PopUpPenolakan";
import NoData from "../../../components/NoData";

// Redux
import {
  actionRequest,
  setActiveStep,
  setPopUpAktif,
  setPopUpNonaktif,
} from "../../../store/actions/dataGroup";
import { useDispatch, useSelector } from "react-redux";
import { setValueReason } from "../../../store/actions/pageContainer";

// Asset
import bannerImage from "../../../assets/img/banner-image.png";
import { ReactComponent as IconSuccessCheck } from "../../../assets/icon/icon-success-check.svg";
import iconEdit from "../../../assets/icon/icon-edit.png";
import iconContract from "../../../assets/icon/icon-contract.png";
import iconComment from "../../../assets/icon/icon-comment.png";
import iconThumb from "../../../assets/icon/icon-thumb-fill-blue.png";
import imagePerson from "../../../assets/img/image-person-trending.png";
import iconArrowRight from "../../../assets/icon/icon-arrow-right.png";
import { ReactComponent as IconBack } from "../../../assets/icon/icon-back.svg";
import successDelete from "../../../assets/img/icon_succes_deleted_grup.png";

const dataChart1 = [
  {
    data: [0, 0, 0],
    backgroundColor: ["#FFBF0B", "#03B74B", "#FE4747"],
    borderWidth: 0,
    label: [
      {
        title: "Belum Disetujui",
        color: "#FFBF0B",
      },
      {
        title: "Aktif",
        color: "#03B74B",
      },
      {
        title: "Ditolak/Diblokir",
        color: "#FE4747",
      },
    ],
  },
];

const dataChart2 = [
  {
    data: [0, 0, 0],
    backgroundColor: ["#FFBF0B", "#FA3E3E"],
    borderWidth: 0,
    label: [
      {
        title: "Pria",
        color: "#FFBF0B",
      },
      {
        title: "Wanita",
        color: "#FA3E3E",
      },
    ],
  },
];

const dataChart3 = [
  {
    data: [0, 0, 0, 0],
    backgroundColor: ["#115ABE", "#03B74B", "#FFBF0B", "#FA3E3E"],
    borderWidth: 0,
    label: [
      {
        title: "< 15 Thn",
        color: "#115ABE",
      },
      {
        title: "15 -> 17 Thn",
        color: "#03B74B",
      },
      {
        title: "18 -> 55 Thn",
        color: "#FFBF0B",
      },
      {
        title: "> 56 Thn",
        color: "#FA3E3E",
      },
    ],
  },
];

const ProfilGroup = ({ history }) => {
  const dispatch = useDispatch();

  const [menuEdit, setMenuEdit] = useState(null);
  const [popUpKonfTolak, setPopKonfTolak] = useState(false);
  const [popUpKonfAktif, setPopKonfAktif] = useState(false);
  const [labelStat, setLabelStat] = useState([]);
  const [datasets, setDataSets] = useState([]);

  const { detailGrup, selectGrup, groupId, popUpAktif, popUpNonaktif } =
    useSelector((state) => state.dataGroup);
  const [pieChart1, setPieChart1] = useState(dataChart1);
  const [pieChart2, setPieChart2] = useState(dataChart2);
  const [totalGender, setTotalGender] = useState(0);
  const [pieChart3, setPieChart3] = useState(dataChart3);

  // state for reason penolakan
  const { valueReason } = useSelector((state) => state.pageContainer);

  useEffect(() => {
    if (detailGrup.length !== 0) {
      // Pie Chart Jumlah Anggota
      const { Aktif, Diblokir } = detailGrup.total_all;
      const total =
        parseInt(detailGrup.total_all["Belum Disetujui"]) +
        parseInt(Aktif) +
        parseInt(Diblokir);
      const data = [
        (100 / total) * Diblokir,
        (100 / total) * Aktif,
        (100 / total) * detailGrup.total_all["Belum Disetujui"],
      ];
      setPieChart1([
        {
          ...dataChart1[0],
          empty: total === 0,
          data: total === 0 ? [100] : data,
          backgroundColor: [
            total === 0 ? "#bbbaba" : "#FFBF0B",
            "#03B74B",
            "#FE4747",
          ],
        },
      ]);

      // Pie Chart Gender
      const { Pria, Wanita } = detailGrup.gender;
      const total2 = parseInt(Pria) + parseInt(Wanita);
      setTotalGender(total2);
      const data2 = [(100 / total2) * Pria, (100 / total2) * Wanita];
      setPieChart2([
        {
          ...dataChart2[0],
          empty: total2 === 0,
          data: total2 === 0 ? [100] : data2,
          backgroundColor: [total2 === 0 ? "#bbbaba" : "#FFBF0B", "#FA3E3E"],
        },
      ]);

      // Pie Chart Rentang Usia
      const { dewasa, muda, anak, tua } = detailGrup.age;
      const total3 =
        parseInt(anak) + parseInt(muda) + parseInt(dewasa) + parseInt(tua);
      const data3 = [
        (100 / total3) * anak,
        (100 / total3) * muda,
        (100 / total3) * dewasa,
        (100 / total3) * tua,
      ];
      setPieChart3([
        {
          ...dataChart3[0],
          empty: total3 === 0,
          data: total3 === 0 ? [100] : data3,
          backgroundColor: [
            total3 === 0 ? "#bbbaba" : "#115ABE",
            "#03B74B",
            "#FFBF0B",
            "#FA3E3E",
          ],
        },
      ]);

      // Statistik Pertumbuhan Anggota
      const label = detailGrup.analis.map((item) => item.bulan);
      setLabelStat(label);

      const dataStat = [
        {
          label: "Anggota",
          data: detailGrup.analis
            .map((item) => item.total)
            .slice(0, moment().month() + 1),
          borderColor: "#0D5EB9",
          backgroundColor: "#0D5EB9",
        },
      ];
      setDataSets(dataStat);
    }
  }, [detailGrup]);

  return (
    <Container>
      <IconButton
        style={{ position: "absolute", zIndex: 100 }}
        onClick={() => {
          if (history === "page") {
            dispatch(setActiveStep("page"));
          } else {
            dispatch(setActiveStep("all"));
          }
        }}
      >
        <IconBack />
      </IconButton>

      {/* Banner Image */}
      <BannerWrap>
        <img
          src={bannerImage}
          alt="Notebook"
          style={{
            width: "100%",
            verticalAlign: "middle",
            objectFit: "cover",
            borderRadius: "10px",
          }}
          height={180}
        />
        <InfoBannerWrap>
          <TextBannerWrap>
            <p
              style={{
                fontWeight: 500,
                fontSize: "30px",
                lineHeight: "36px",
                display: "flex",
                alignItems: "center",
                gap: "13px",
              }}
            >
              {selectGrup?.group_title || "-"}
              <IconSuccessCheck height={32} width={32} />
            </p>
            <p
              style={{
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "18px",
              }}
            >
              Grup di buat oleh @{selectGrup?.full_name || ""}
            </p>
          </TextBannerWrap>
          <div>
            <IconButton
              aria-label="delete"
              onClick={(event) => setMenuEdit(event.currentTarget)}
            >
              <img alt="icon-edit" src={iconEdit} height={32} width={32} />
            </IconButton>
          </div>
          <Menu
            id="menu-appbar"
            anchorEl={menuEdit}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              top: "40px",
              left: "10px",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "13px",
              lineHeight: "16px",
              color: "#000000",
              "& .MuiPaper-root": {
                borderRadius: "10px",
              },
              "& .MuiList-root": {
                padding: "0px",
              },
            }}
            open={Boolean(menuEdit)}
            onClose={() => setMenuEdit(null)}
          >
            <MenuItem
              onClick={() => {
                if (selectGrup?.group_status === "4") {
                  setPopKonfAktif(true);
                } else {
                  setPopKonfTolak(true);
                }
              }}
              sx={{ padding: "11px 18px" }}
            >
              {selectGrup?.group_status === "4"
                ? "Aktifkan Grup"
                : "Nonaktifkan Grup"}
            </MenuItem>
          </Menu>
        </InfoBannerWrap>
      </BannerWrap>

      <ContentWrapper>
        {/* Postingan Terpopuler */}
        <div style={{ display: "flex", gap: "20px" }}>
          <BoxWrapper style={{ width: "60%", height: "auto" }}>
            <TitleBar>Postingan terpopuler</TitleBar>
            <ScrollWrapper style={{ gap: "5px", paddingRight: "15px" }}>
              {detailGrup.length !== 0 && detailGrup?.trend_post.length > 0 ? (
                detailGrup.trend_post.map((item, index) => (
                  <List aria-label="contacts" disablePadding key={index}>
                    <ItemCustom onClick={() => dispatch(setActiveStep("all"))}>
                      <ContentWrap>
                        <img alt="person-trending" src={imagePerson} />
                        <TextWrapper>
                          <TextName>{item.post_text}</TextName>
                          <TextDetail>{`${item.total_like} Like, ${item.total_comment} Komentar`}</TextDetail>
                        </TextWrapper>
                      </ContentWrap>
                      <img alt="arrow-right" src={iconArrowRight} />
                    </ItemCustom>
                  </List>
                ))
              ) : (
                <NoData />
              )}
            </ScrollWrapper>
          </BoxWrapper>
          {/* Jumlah Interaksi */}
          <BoxWrapper style={{ width: "40%" }}>
            <TitleBar>Jumlah Interaksi</TitleBar>
            <InfoWrap>
              <ItemWrap>
                <ImageWrap>
                  <img
                    alt="icon-contract"
                    src={iconContract}
                    height={24}
                    width={24}
                  />
                  Postingan
                </ImageWrap>
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "24px",
                  }}
                >
                  {detailGrup.post.Postingan}
                </p>
              </ItemWrap>
              <ItemWrap>
                <ImageWrap>
                  <img
                    alt="icon-contract"
                    src={iconComment}
                    height={24}
                    width={24}
                  />
                  Komentar
                </ImageWrap>
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "24px",
                  }}
                >
                  {detailGrup.post.Komentar}
                </p>
              </ItemWrap>
              <ItemWrap>
                <ImageWrap>
                  <img
                    alt="icon-contract"
                    src={iconThumb}
                    height={24}
                    width={24}
                  />
                  Like
                </ImageWrap>
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "24px",
                  }}
                >
                  {detailGrup.post.Like}
                </p>
              </ItemWrap>
            </InfoWrap>
          </BoxWrapper>
        </div>

        {/* Chart Line Pertumbuhan Anggota */}
        <BoxWrapper>
          <TitleBar>Pertumbuhan Anggota</TitleBar>
          <ChartLine labels={labelStat} datasets={datasets} />
        </BoxWrapper>

        {/* Chart Donat Jumlah Anggota */}
        <BoxWrapper>
          <TitleBar>Jumlah Anggota</TitleBar>
          <ChartWrap>
            <Chart
              data={pieChart1}
              description={`Total : ${detailGrup.total_all.total}`}
              title="Semua"
            />
            <Chart
              data={pieChart2}
              description={`Total ${totalGender}`}
              title="Jenis Kelamin"
            />
            <Chart
              data={pieChart3}
              description={`Total : ${detailGrup.age.total}`}
              title="Rentang Usia"
            />
          </ChartWrap>
        </BoxWrapper>
      </ContentWrapper>

      {/* Popup Konfirmasi Nonaktif Grup */}
      <PopUpPenolakan
        open={popUpKonfTolak}
        valueInput={valueReason}
        onChange={(e) => dispatch(setValueReason(e.target.value))}
        type="choice"
        width="350px"
        onClose={() => setPopKonfTolak(false)}
        onClickAction={() => {
          setPopKonfTolak(false);
          dispatch(actionRequest(groupId, "4"));
        }}
        title="Konfirmasi"
        info="Masukan Alasan Penonaktifan Group"
      />

      {/* Nonaktif Grup */}
      <PopUp
        open={popUpNonaktif}
        width="350px"
        padding="60px 30px 25px 30px"
        imgSrc={successDelete}
        onClose={() => {
          dispatch(setPopUpNonaktif(false));
          dispatch(setActiveStep("page"));
        }}
        onClickAction={() => {
          dispatch(setPopUpNonaktif(false));
          dispatch(setActiveStep("page"));
        }}
        title="Berhasil"
        info="Berhasil menonaktifkan grup."
      />

      {/* Popup Konfirmasi Aktif Grup */}
      <PopUp
        open={popUpKonfAktif}
        type="choice"
        width="350px"
        onClose={() => setPopKonfAktif(false)}
        onClickAction={() => {
          setPopKonfAktif(false);
          dispatch(actionRequest(groupId, "1", true));
        }}
        swapOptions
        title="Konfirmasi"
        info="Apakah anda yakin aktifkan grup?"
      />

      {/* Aktif Grup*/}
      <PopUp
        open={popUpAktif}
        width="350px"
        padding="60px 30px 25px 30px"
        imgSrc={successDelete}
        onClose={() => {
          dispatch(setPopUpAktif(false));
          dispatch(setActiveStep("page"));
        }}
        onClickAction={() => {
          dispatch(setPopUpAktif(false));
          dispatch(setActiveStep("page"));
        }}
        title="Berhasil"
        info="Berhasil mengaktifkan grup."
      />
    </Container>
  );
};

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const ItemWrap = styled("div")(() => ({
  backgroundColor: "#F4F7FB",
  borderRadius: "5px",
  padding: "12px 10px",
  display: "flex",
  justifyContent: "space-between",
}));

const ImageWrap = styled("div")(() => ({
  display: "flex",
  gap: "7px",
  alignItems: "center",
}));

const ChartWrap = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-around",
  height: "100%",
  alignItems: "center",
  paddingBottom: "60px",
}));

const TextBannerWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignSelf: "center",
  marginTop: "40px",
}));

const InfoBannerWrap = styled("div")(() => ({
  position: "absolute",
  bottom: "0",
  background: "rgba(17, 90, 190, 0.7)",
  color: "#f1f1f1",
  width: "100%",
  padding: "10px 10px 10px 30px",
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  height: "180px",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "10px",
}));

const ItemCustom = styled(ListItemButton)(() => ({
  padding: 0,
  display: "flex",
  justifyContent: "space-between",
}));

const TextName = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "15px",
  letterSpacing: "0.01em",
  color: "#000000",
}));

const TextDetail = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "10px",
  lineHeight: "12px",
  letterSpacing: "0.01em",
  color: "#948F8F",
}));

const InfoWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "11px",
}));

const ScrollWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  height: "100%",
  overflowY: "scroll",
  marginRight: "-10px",
}));

const ContentWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
  minWidth: "fit-content",
  gap: "15px",
}));

const TextWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  justifyContent: "center",
}));

const ContentWrap = styled("div")(() => ({
  display: "flex",
  gap: "20px",
}));

const BoxWrapper = styled("div")(() => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
  height: "fit-content",
  gap: "15px",
  display: "flex",
  flexDirection: "column",
}));

const BannerWrap = styled("div")(() => ({
  bottom: "0",
  background: "rgba(0, 0, 0, 0.5)",
  color: "#f1f1f1",
  width: "100%",
  position: "relative",
  margin: "0 auto",
  boxSizing: "border-box",
  borderRadius: "10px",
}));

const TitleBar = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "0.01em",
  color: "#000000",
}));

export default ProfilGroup;
