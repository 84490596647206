import { combineReducers } from "redux";

import pageContainer from "./pageContainer";
import dataLoker from "./dataLoker";
import dataPengguna from "./dataPengguna";
import dataGroup from "./dataGroup";
import dataProyek from "./dataProyek";
import dashboard from "./dashboard";
import dataPostingan from "./dataPostingan";
import notifikasi from "./notifikasi";
import dataTiket from "./dataTiket";
import setting from "./setting";
import banner from "./banner";

export default combineReducers({
  pageContainer: pageContainer,
  dataLoker: dataLoker,
  dataPengguna: dataPengguna,
  dataGroup: dataGroup,
  dataProyek: dataProyek,
  dashboard: dashboard,
  dataPostingan: dataPostingan,
  notifikasi: notifikasi,
  dataTiket: dataTiket,
  setting: setting,
  banner: banner,
});
