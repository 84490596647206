// Popup Component
// --------------------------------------------------------
import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "../Button";
import "../../index.css";

const PopUpDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const PopUpPenolakan = ({
  open,
  onClose,
  onClickAction,
  type,
  title,
  info,
  width,
  maxWidth,
  buttonWord,
  valueInput,
  onChange,
}) => {
  return (
    <PopUpDialog
      open={open}
      onClose={onClose}
      aria-labelledby="popup-dialog-title"
      sx={{
        ".MuiPaper-root": {
          width: width,
          maxWidth: maxWidth,
          borderRadius: "20px",
          height: "fit-content",
          padding: "25px 20px",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogContent
        sx={{
          justifyContent: "center",
          textAlign: "center",
          display: "flex",
          gap: "40px",
          flexDirection: "column",
          padding: "0px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "15px",
                lineHeight: "18px",
                letterSpacing: "0.01em",
                color: "#000000",
              }}
            >
              {title}
            </p>
          </div>
          <div
            style={{
              margin: "10px 0",
            }}
          >
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "13px",
                textAlign: "left",
                lineHeight: "18px",
                letterSpacing: "0.01em",
                color: "#575554",
              }}
            >
              {info && info} :
            </p>
            <textarea
              rows="3"
              cols="3"
              onChange={onChange}
              value={valueInput}
              style={{
                resize: "vertical",
                padding: "10px",
                width: "100%",
              }}
            ></textarea>
          </div>
        </div>
      </DialogContent>

      {type === "standard" && (
        <DialogActions
          sx={{
            justifyContent: "center",
            textAlign: "center",
            padding: "0px",
            border: "1px solid red",
          }}
        >
          <Button variant="contained" full onClick={onClickAction}>
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: "17px",
                lineHeight: "18px",
                letterSpacing: "0.01em",
                color: "#FFFFFF",
              }}
            >
              {buttonWord}
            </p>
          </Button>
        </DialogActions>
      )}

      {type === "choice" && (
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: "0px",
            display: "flex",
            gap: "20px",
          }}
        >
          <Button
            color="#115aaa"
            variant="outlined"
            onClick={onClose}
            width="112px"
            full
          >
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "18px",
                letterSpacing: "0.01em",
                color: "#fff",
              }}
            >
              Tidak
            </p>
          </Button>
          <Button
            variant="contained"
            color="#E20000"
            onClick={onClickAction}
            width="112px"
            full
          >
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "18px",
                letterSpacing: "0.01em",
                color: "#FFFFFF",
              }}
            >
              Ya
            </p>
          </Button>
        </DialogActions>
      )}
    </PopUpDialog>
  );
};

PopUpPenolakan.propTypes = {
  type: PropTypes.oneOf(["standard", "choice", "otp", "others"]),
  onClickAction: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  info: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  padding: PropTypes.string,
  buttonWord: PropTypes.string,
};
PopUpPenolakan.defaultProps = {
  type: "standard",
  onClickAction: () => {},
  title: "",
  info: "",
  width: "480px",
  maxWidth: "480px",
  padding: "30px 20px 20px 20px",
  buttonWord: "OK",
};

export default PopUpPenolakan;
