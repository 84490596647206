// Page Info Proyek
// --------------------------------------------------------

import React, { useState } from "react";
import Title from "../../../components/Title";
import { styled } from "@mui/material/styles";
import {
  actionRequest,
  searchPenggunaFromProyek,
  setActiveStep,
} from "../../../store/actions/dataProyek";
import PopUp from "../../../components/PopUp";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";

// Asset
import { ReactComponent as IconGroup } from "../../../assets/icon/icon_grup.svg";
import { ReactComponent as IconUser } from "../../../assets/icon/icon_user.svg";
import groupSuccess from "../../../assets/img/group-success.png";
import deleteGroup from "../../../assets/img/group-delete.png";
import PopUpPenolakan from "../../../components/PopUpPenolakan";
import { setValueReason } from "../../../store/actions/pageContainer";
import moment from "moment";

const InfoProyek = ({ history }) => {
  // state for popup
  const [poupKonfSetuju, setPopUpKonfSetuju] = useState(false);
  const [poUpSetuju, setPopUpSetuju] = useState(false);
  const [popUpKonfTolak, setPopKonfTolak] = useState(false);
  const [popUpTolak, setPopUpTolak] = useState(false);

  const dispatch = useDispatch();

  const { info_proyek, info_user } = useSelector(
    (state) => state.dataProyek.requestProyek
  );
  // state for penolakan
  const { valueReason } = useSelector((state) => state.pageContainer);

  const backToTable = () => {
    if (history === "page") {
      dispatch(setActiveStep("page"));
    } else {
      dispatch(setActiveStep("all"));
    }
  };

  return (
    <Container>
      {/* Title */}
      <Title
        title="Data Proyek"
        withBack
        onBack={() => {
          if (history === "page") {
            dispatch(setActiveStep("page"));
          } else {
            dispatch(setActiveStep("all"));
          }
        }}
      />

      <RowWrapper>
        <div
          style={{
            bottom: "0",
            background: "rgba(0, 0, 0, 0.5)",
            color: "#f1f1f1",
            width: "100%",
            position: "relative",
            margin: "0 auto",
            boxSizing: "border-box",
          }}
        >
          <img
            src={info_proyek.cover}
            alt="Notebook"
            style={{
              width: "100%",
              verticalAlign: "middle",
              objectFit: "cover",
            }}
            height={205}
          />
          <div
            style={{
              position: "absolute",
              bottom: "0",
              background: "rgba(17, 90, 190, 0.7)",
              color: "#f1f1f1",
              width: "100%",
              padding: "11px 20px",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "15px",
              lineHeight: "18px",
            }}
          >
            <p>Proyek di buat oleh @{info_proyek.full_name}</p>
          </div>
        </div>
      </RowWrapper>

      <RowWrapper>
        <ContentWrapper style={{ width: "60%", height: "initial" }}>
          <TitleBar>
            <IconGroup height={24} width={24} />
            Info Proyek
          </TitleBar>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginLeft: "30px",
            }}
          >
            <div style={{ display: "flex", fontWeight: 500 }}>
              <p style={{ width: "150px" }}>Nama projek</p>
              <p>: {info_proyek.nama_proyek}</p>
            </div>
            <div style={{ display: "flex", fontWeight: 500 }}>
              <p style={{ width: "150px" }}>Kategori</p>
              <p>: {info_proyek.kategori}</p>
            </div>
            <div style={{ display: "flex", fontWeight: 500 }}>
              <p style={{ width: "150px" }}>Jenis projek</p>
              <p>: {info_proyek.privacy === "0" ? "Publik" : "Private"}</p>
            </div>

            <div style={{ display: "flex", fontWeight: 500 }}>
              <p style={{ width: "150px" }}>Tanggal dibuat</p>
              <p>: {moment(info_proyek.create_at).format("DD MMMM YYYY")}</p>
            </div>
            <div style={{ display: "flex", fontWeight: 500 }}>
              <p style={{ width: "150px" }}>Lokasi</p>
              <p>: {info_proyek.lokasi}</p>
            </div>
            <div style={{ display: "flex", fontWeight: 500 }}>
              <p style={{ minWidth: "150px" }}>Deskripsi</p>
              <p>: {info_proyek.deskripsi}</p>
            </div>
          </div>
        </ContentWrapper>

        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <ContentWrapper>
            <TitleBar>
              <IconUser height={24} width={24} />
              Info pembuat projek
            </TitleBar>

            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                fontWeight: 500,
              }}
            >
              <img
                alt="profile-post"
                src={info_user.foto_profile}
                style={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "100%",
                }}
              />
              <div
                style={{ display: "flex", gap: "4px", flexDirection: "column" }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#A1BDE3",
                    fontStyle: "italic",
                  }}
                >
                  Username
                </p>
                <p>{info_user.full_name}</p>
              </div>
            </div>

            <div
              style={{
                marginLeft: "15px",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div
                style={{ display: "flex", gap: "4px", flexDirection: "column" }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#A1BDE3",
                    fontStyle: "italic",
                  }}
                >
                  Nama Lengkap
                </p>
                <p>{info_user.full_name}</p>
              </div>
              <div
                style={{ display: "flex", gap: "4px", flexDirection: "column" }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#A1BDE3",
                    fontStyle: "italic",
                  }}
                >
                  Email
                </p>
                <p>{info_user.email}</p>
              </div>
              <div
                style={{ display: "flex", gap: "4px", flexDirection: "column" }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#A1BDE3",
                    fontStyle: "italic",
                  }}
                >
                  Tanggal bergabung
                </p>
                <p>{info_user.create_at.split(" ", 1)}</p>
              </div>
              <div
                style={{ display: "flex", gap: "4px", flexDirection: "column" }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#A1BDE3",
                    fontStyle: "italic",
                  }}
                >
                  Alamat
                </p>
                <p>{info_user.address}</p>
              </div>
              <div
                style={{ display: "flex", gap: "4px", flexDirection: "column" }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#A1BDE3",
                    fontStyle: "italic",
                  }}
                >
                  Alamat
                </p>
                <p>{"+" + info_user.phone}</p>
              </div>
            </div>
          </ContentWrapper>
        </div>
      </RowWrapper>

      <RowWrapper>
        <div
          style={{
            width: "60%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {info_proyek.id_status === "1" && (
            <>
              <Button
                onClick={() => {
                  setPopUpKonfSetuju(true);
                }}
              >
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "22px",
                  }}
                >
                  Setujui
                </p>
              </Button>
              <Button color="#E20000" onClick={() => setPopKonfTolak(true)}>
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "22px",
                  }}
                >
                  Tolak
                </p>
              </Button>
            </>
          )}
        </div>
        <div style={{ width: "40%" }}>
          <Button
            full
            color="#039C40"
            onClick={() => {
              dispatch(searchPenggunaFromProyek(info_proyek.id_owner));
            }}
          >
            <p
              style={{
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "22px",
              }}
            >
              Tampilkan profile lengkap
            </p>
          </Button>
        </div>
      </RowWrapper>

      {/* Konfirmasi Persetujuan */}
      <PopUp
        open={poupKonfSetuju}
        width="350px"
        padding="60px 30px 25px 30px"
        imgSrc={groupSuccess}
        onClose={() => setPopUpKonfSetuju(false)}
        onClickAction={() => {
          setPopUpKonfSetuju(false);
          setPopUpSetuju(true);
          dispatch(actionRequest(info_proyek.id_proyek, "2"));
        }}
        swapOptions
        type="choice"
        title="Konfirmasi Persetujuan"
        info="Apakah anda yakin ingin setujui permintaan ini ?"
        buttonWord="Setujui"
      />
      {/* Disetujui */}
      <PopUp
        open={poUpSetuju}
        width="350px"
        padding="60px 30px 25px 30px"
        imgSrc={groupSuccess}
        onClose={() => setPopUpSetuju(false)}
        onClickAction={() => {
          setPopUpSetuju(false);
          backToTable();
        }}
        title="Permintaan Disetujui"
        info="Permintaan pembuatan grup berhasil disetujui."
      />
      {/* Konfirmasi Tolak */}
      <PopUpPenolakan
        open={popUpKonfTolak}
        valueInput={valueReason}
        onChange={(e) => dispatch(setValueReason(e.target.value))}
        type="choice"
        width="350px"
        onClose={() => setPopKonfTolak(false)}
        onClickAction={() => {
          setPopKonfTolak(false);
          dispatch(actionRequest(info_proyek.id_proyek, "3"));
          setPopUpTolak(true);
        }}
        title="Konfirmasi"
        info="Masukan Alasan Penolakan"
      />
      {/* Ditolak */}
      <PopUp
        open={popUpTolak}
        width="350px"
        padding="60px 30px 25px 30px"
        imgSrc={deleteGroup}
        onClose={() => {
          setPopUpTolak(false);
        }}
        onClickAction={() => {
          backToTable();
          setPopUpTolak(false);
        }}
        title="Berhasil tolak"
        info="Berhasil menolak pembuatan grup."
      />
    </Container>
  );
};

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const RowWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  width: "100%",
}));

const ContentWrapper = styled("div")(() => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
  minHeight: "267px",
  height: "fit-content",
  gap: "15px",
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
  minWidth: "fit-content",
}));

const TitleBar = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "0.01em",
  color: "#115AAA",
  display: "flex",
  gap: "8px",
  alignItems: "center",
}));

export default InfoProyek;
