/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Route,
  // Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import AppRoutes, {
  BeforeLogin,
  DesignSystemRoutes,
  LokerPublic,
  User,
} from "../../config/routes";
// import { Login } from "../pages";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setMenuAccess } from "../../store/actions/pageContainer";

const App = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuAccess } = useSelector(
    (state) => state.pageContainer,
    shallowEqual
  );

  const dataUser = localStorage.getItem("dataUser");

  useEffect(() => {
    if (
      dataUser !== null &&
      menuAccess.length > 0 &&
      (location.pathname === "/nakerweb/login" ||
        location.pathname === "/nakerweb/login/")
    ) {
      history.push("/nakerweb/dashboard");
      window.location.reload(false);
    }

    if (dataUser !== null && menuAccess.length === 0) {
      dispatch(
        setMenuAccess(
          JSON.parse(dataUser)?.data.menu_access?.map((item) => item.id)
        )
      );
    }
  }, [menuAccess]);

  return (
    <React.Fragment>
      {!(menuAccess.length === 0 && dataUser == null) && (
        <React.Fragment>
          {DesignSystemRoutes.map((route) => (
            <Route key={route.id} {...route} />
          ))}

          {AppRoutes.map((route) => (
            <Route key={route.id} {...route} />
          ))}

          {User.map((route) => (
            <Route key={route.id} {...route} />
          ))}
        </React.Fragment>
      )}

      {LokerPublic.map((route) => (
        <Route key={route.id} {...route} />
      ))}

      {BeforeLogin.map((route) => (
        <Route key={route.id} {...route} />
      ))}
    </React.Fragment>
  );
};

export default App;
