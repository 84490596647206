import {
  SET_ACTIVE_STEP_TIKET,
  SET_HEADER_TIKET,
  SET_LIST_TIKET,
  SET_SELECT_TIKET,
  SET_DETAIL_TIKET,
  SET_LOADING_CHAT,
  SET_ACTIVE_PAGE_TIKET,
  SET_TOTAL_PAGE_TIKET,
  SET_MAX_PAGE_TIKET,
  SET_TOTAL_DATA_TIKET,
  SET_HEADER_TABLE,
  SET_SELECT_STATUS,
  SET_KEYWORD,
} from "../actions/dataTiket";

const initialState = {
  activeStep: "page",
  headerTiket: {},
  listTiket: [],
  selectTiket: {},
  detailTiket: {},
  loadingChat: false,
  activePage: 1,
  totalPage: 1,
  maxPage: 0,
  totalData: 0,
  headerTable: [],
  selectStatus: [0],
  keyword: "",
};

const dataTiket = (state = initialState, action) => {
  switch (action.type) {
    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };
    case SET_SELECT_STATUS:
      return {
        ...state,
        selectStatus: action.payload,
      };
    case SET_HEADER_TABLE:
      return {
        ...state,
        headerTable: action.payload,
      };
    case SET_TOTAL_DATA_TIKET:
      return {
        ...state,
        totalData: action.payload,
      };
    case SET_MAX_PAGE_TIKET:
      return {
        ...state,
        maxPage: action.payload,
      };
    case SET_TOTAL_PAGE_TIKET:
      return {
        ...state,
        totalPage: action.payload,
      };
    case SET_ACTIVE_PAGE_TIKET:
      return {
        ...state,
        activePage: action.payload,
      };
    case SET_LOADING_CHAT:
      return {
        ...state,
        loadingChat: action.payload,
      };
    case SET_DETAIL_TIKET:
      return {
        ...state,
        detailTiket: action.payload,
      };
    case SET_SELECT_TIKET:
      return {
        ...state,
        selectTiket: action.payload,
      };
    case SET_LIST_TIKET:
      return {
        ...state,
        listTiket: action.payload,
      };
    case SET_HEADER_TIKET:
      return {
        ...state,
        headerTiket: action.payload,
      };
    case SET_ACTIVE_STEP_TIKET:
      return {
        ...state,
        activeStep: action.payload,
      };
    default:
      return state;
  }
};

export default dataTiket;
