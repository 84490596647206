import {
  serviceActionBanner,
  serviceAddBanner,
  serviceGetDataBanner,
} from "../../utils/api";
import { setLoading } from "./pageContainer";

export const SET_DATA_BANNER = "SET_DATA_BANNER";
export const SET_POPUP_ADD = "SET_POPUP_ADD";
export const SET_ACTIVE_PAGE_BANNER = "SET_ACTIVE_PAGE_BANNER";
export const SET_TOTAL_PAGE_BANNER = "SET_TOTAL_PAGE_BANNER";
export const SET_MAX_PAGE_BANNER = "SET_MAX_PAGE_BANNER";
export const SET_TOTAL_DATA_BANNER = "SET_TOTAL_DATA_BANNER";

export const setTotalData = (payload) => ({
  type: SET_TOTAL_DATA_BANNER,
  payload,
});

export const setMaxPage = (payload) => ({
  type: SET_MAX_PAGE_BANNER,
  payload,
});

export const setTotalPage = (payload) => ({
  type: SET_TOTAL_PAGE_BANNER,
  payload,
});

export const setActivePage = (payload) => ({
  type: SET_ACTIVE_PAGE_BANNER,
  payload,
});

export const setPopupAdd = (payload) => ({
  type: SET_POPUP_ADD,
  payload,
});

export const setDataNotif = (payload) => ({
  type: SET_DATA_BANNER,
  payload,
});

export const getDataBanner = () => async (dispatch, getState) => {
  const { maxPage, totalPage, activePage } = getState().notifikasi;
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceGetDataBanner({
      page: activePage,
      limit: activePage === 1 ? 11 : 10,
    });
    if (status === 200) {
      dispatch(setDataNotif(data.data.slice(0, 10)));
      dispatch(setTotalData(data.total_data));

      // Jika data 10 atau lebih & maksimal page tidak sama dengan totalPage, maka ada kemungkinan data di next page, maka total page bertambah
      if (data.data.length > 10 && maxPage !== totalPage) {
        dispatch(setTotalPage(totalPage + 1));
      }
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const addDataBanner = (banner, link) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceAddBanner({
      img_banner: banner,
      link,
    });
    if (status === 200) {
      dispatch(getDataBanner());
      dispatch(setPopupAdd(false));
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};

export const actionBanner = (id_banner) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceActionBanner({
      id_banner,
    });
    if (status === 200) {
      dispatch(setPopupAdd(false));
      dispatch(getDataBanner());
    }
    dispatch(setLoading(false));
  } catch {
    dispatch(setLoading(false));
  }
};
