// format 100.000.000
export const formatAmountDot = (input = "") => {
  if (!input) return input;
  const inputNumber = input
    .toString()
    .replace(/\./g, "")
    .replace(/[^0-9]/g, "");

  if (Number(inputNumber) === 0) return "";
  if (inputNumber[0] === "0" || inputNumber[3] === "0")
    return `${Number(inputNumber)
      .toString()
      .replace(/\./g, "")
      .replace(/[^0-9]/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  // eslint-disable-next-line consistent-return
  if (inputNumber.length >= 43)
    return `${inputNumber
      .slice(0, -1)
      .replace(/\./g, "")
      .replace(/[^0-9]/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  // eslint-disable-next-line consistent-return
  return `${inputNumber
    .replace(/\./g, "")
    .replace(/[^0-9]/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
};

export const isJSONString = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

export const capFirstLetter = (words) => {
  var separateWord = words.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
};

export const allMonthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Ags",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];

export function stringToColor(string) {
  var hash = 0;
  var i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  var color = "#";
  for (i = 0; i < 3; i += 1) {
    var value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }

  return color;
}

export const isImgUrl = (url) => {
  return /\.(jpg|jpeg|png|webp|avif|gif)$/.test(url);
};

export const parseNumber = (val) => {
  const normalize = val && val.replace(/,\./g, "").replace(/[^0-9]/g, "");
  return normalize;
};
