/* eslint-disable react-hooks/exhaustive-deps */
// Page Semua Pengguna
// --------------------------------------------------------

import {
  FormControl,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import DropDown from "../../../components/DropDown";
import InputText from "../../../components/InputText";
import PageNextPrev from "../../../components/PageNextPrev";
import Table from "../../../components/Table";
import Title from "../../../components/Title";
import Colors from "../../../utils/helpers/colors";

// Redux
import iconXls from "../../../assets/icon/icon-xls-white.png";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  getDetailDataPengguna,
  getPenggunaFilter,
  getPenggunaSearch,
  setActivePage,
  setActiveStep,
  setMaxPage,
  userExport,
} from "../../../store/actions/dataPengguna";

// Asset
import eye from "../../../assets/icon/Eye.svg";
import iconSlider from "../../../assets/icon/icon-slider.png";
import iconSearch from "../../../assets/icon/icon-search.png";
import { stringToColor } from "../../../utils/helpers";
import { setTableRows } from "../../../store/actions/setting";

const SemuaPengguna = ({ setHistory }) => {
  const [keyword, setKeyword] = useState("");
  const [menuFilter, setMenuFilter] = useState(null);

  // state for filter
  const [pages, setPages] = useState(1);
  const [gender, setGender] = useState([0]);
  const [age, setAge] = useState([0]);
  const [valueCity, setValueCity] = useState([0]);
  const [isStatus, setIsStatus] = useState([0]);

  // state global
  const dispatch = useDispatch();
  const { allUsers, dropDownCity, totalPage, activePage, totalData } =
    useSelector((state) => state.dataPengguna, shallowEqual);

  const { tableRows } = useSelector((state) => state.setting);

  const dataHeader = [
    {
      title: "No",
      key: "id_user",
      width: 30,
      center: true,
      render: (_, index) => <p>{(activePage - 1) * tableRows + (index + 1)}</p>,
    },
    {
      title: "Profile",
      key: "profile",
      render: (rowData) => (
        <>
          {rowData.foto_profile ? (
            <img
              src={rowData.foto_profile}
              alt="profile"
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "100%",
              }}
            />
          ) : (
            <DefaultImgContainer
              style={{
                backgroundColor: stringToColor(
                  `${rowData.full_name !== null ? rowData.full_name : "Blank"}`
                ),
              }}
            >
              <DefaultImg>
                {rowData.full_name.slice(0, 1).toUpperCase()}
              </DefaultImg>
            </DefaultImgContainer>
          )}
        </>
      ),
      width: 40,
      center: true,
    },
    {
      title: "Nama Lengkap",
      key: "full_name",
    },
    {
      title: "Jenis Kelamin",
      key: "gender",
      center: true,
      render: (rowData) => (
        <p>
          {rowData.gender === "0"
            ? "Pria"
            : rowData.gender === "1"
            ? "Wanita"
            : "-"}
        </p>
      ),
    },
    {
      title: "Kota",
      key: "city",
      center: true,
    },
    {
      title: "Status",
      key: "is_status",
      width: 120,
      render: (rowData) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {rowData.is_status === "1" ? (
            <div
              style={{
                border: "1px solid #039C40",
                backgroundColor: "#AEF8AC",
                borderRadius: "30px",
                padding: "4px 20px",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#039C40",
              }}
            >
              Aktif
            </div>
          ) : rowData.is_status === "2" ? (
            <div
              style={{
                border: "1px solid #C80707",
                backgroundColor: "#F5969633",
                borderRadius: "30px",
                padding: "4px 20px",
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#C80707",
              }}
            >
              Nonaktif
            </div>
          ) : (
            rowData.is_status === "3" && (
              <div
                style={{
                  border: "1px solid #C80707",
                  backgroundColor: "#F5969633",
                  borderRadius: "30px",
                  padding: "4px 20px",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "16px",
                  color: "#C80707",
                }}
              >
                Diblokir
              </div>
            )
          )}
        </div>
      ),
      center: true,
    },
    {
      title: "Usia",
      key: "age",
      width: 50,
      center: true,
      render: (rowData) => (
        <span>{rowData.age ? rowData.age + " Thn" : "-"}</span>
      ),
    },
    {
      title: "Aksi",
      key: "action",
      render: (rowData) => (
        <Button
          variant="contained"
          borderRadius="5px"
          padding="0px 7px 0px 9px"
          onClick={() => {
            dispatch(getDetailDataPengguna(rowData.id_user));
            setHistory("all");
          }}
        >
          Detail
          <img src={eye} alt="eye" />
        </Button>
      ),
      center: true,
    },
  ];

  useEffect(() => {
    dispatch(getPenggunaFilter(gender, age, isStatus, valueCity));
  }, [dispatch, pages, gender, age, isStatus, valueCity]);

  // Jika page yang aktif sama dengan total page, maka simpan maksimal page agar tidak menambah total page
  useEffect(() => {
    if (activePage === totalPage) {
      dispatch(setMaxPage(totalPage));
    }
  }, [activePage]);

  const searchHandler = (event) => {
    if (event.key === "Enter") {
      if (keyword !== "") {
        dispatch(getPenggunaSearch(keyword));
      } else {
        dispatch(getAllUsers());
      }
    }
  };

  return (
    <Container>
      <Title
        title="Semua Pengguna"
        withBack
        onBack={() => dispatch(setActiveStep("page"))}
      >
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          {/* Pencarian */}
          <InputText
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="pencarian"
            onKeyDown={searchHandler}
            noPadding
            width="175px"
            borderRadius="5px"
            borderColor="#115AAA"
            iconAdornment={
              <InputAdornment
                position="start"
                className="inputAdorment"
                sx={{
                  zIndex: 200,
                  height: "40px",
                  justifyContent: "center",
                  width: "67px",
                  maxHeight: "56px",
                  borderRadius: "5px 0px 0px 5px",
                  backgroundColor: Colors.primary.hard,
                  ".MuiTypography-root": {
                    color: "white",
                    fontSize: "0.938rem",
                    fontWeight: "400",
                    lineHeight: "1.124rem",
                  },
                }}
              >
                <img
                  src={iconSearch}
                  alt="icon-search"
                  width={24}
                  height={24}
                />
              </InputAdornment>
            }
          />
          {/* Tombol Export */}
          <div style={{ display: "flex", gap: "2px" }}>
            <Button
              padding="8px"
              width="fit-content"
              borderRadius="5px"
              onClick={() => dispatch(userExport())}
            >
              Export
              <img
                src={iconXls}
                style={{ marginLeft: "5px" }}
                alt="icon-slider"
                width={24}
                height={24}
              />
            </Button>
          </div>

          {/* Tombol Filter */}
          <Button
            padding="8px"
            width="fit-content"
            onClick={(event) => {
              setMenuFilter(event.currentTarget);
            }}
          >
            <img src={iconSlider} alt="icon-slider" width={24} height={24} />
          </Button>
          {/* Filter */}
          <Menu
            id="menu-appbar"
            anchorEl={menuFilter}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              top: "40px",
              left: "10px",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "15px",
              lineHeight: "18px",
              color: "#000000",
              "& .MuiPaper-root": {
                borderRadius: "10px",
              },
              "& .MuiList-root": {
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              },
            }}
            open={Boolean(menuFilter)}
            onClose={() => setMenuFilter(null)}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Jenis Kelamin :</p>
              <DropDown
                dropdownValue={gender}
                listDropDown={[
                  {
                    label: "Semua Jenis Kelamin",
                    value: 0,
                  },
                  {
                    label: "Laki-laki",
                    value: 1,
                  },
                  { label: "Perempuan", value: 2 },
                ]}
                placeHolder="Pilih Jenis Kelamin"
                handleChange={(e, value) => {
                  setGender([e.target.value]);
                  setPages(1);
                }}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Range Usia :</p>
              <DropDown
                dropdownValue={age}
                listDropDown={[
                  {
                    label: "Semua Usia",
                    value: 0,
                  },
                  {
                    label: "< 15 Thn",
                    value: 1,
                  },
                  { label: "15 -> 17 Thn", value: 2 },
                  { label: "18 -> 55 Thn", value: 3 },
                  { label: "> 56", value: 4 },
                ]}
                placeHolder="Pilih Range Usia"
                handleChange={(e) => {
                  setAge([e.target.value]);
                  setPages(1);
                }}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Kota :</p>
              <DropDown
                dropdownValue={valueCity}
                listDropDown={dropDownCity}
                placeHolder="Pilih Kota"
                handleChange={(e, kode) => {
                  setValueCity([e.target.value]);
                  setPages(1);
                }}
                isSearch
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p>Status :</p>
              <DropDown
                dropdownValue={isStatus}
                listDropDown={[
                  {
                    label: "Semua Status",
                    value: "0",
                  },
                  {
                    label: "Aktif",
                    value: "1",
                  },
                  { label: "Tidak Aktif", value: "2" },
                ]}
                placeHolder="Pilih Status Akun"
                handleChange={(e) => {
                  setIsStatus([e.target.value]);
                  setPages(1);
                }}
              />
            </div>
          </Menu>
        </div>
      </Title>

      <Table dataContent={allUsers} headerContent={dataHeader} />

      <div style={{ width: "100px", alignSelf: "flex-end" }}>
        <FormControl fullWidth>
          <InputLabel id="Select Rows Data">Rows</InputLabel>
          <Select
            labelId="Select Rows Data"
            id="rowsSelect"
            label="Rows"
            onChange={(e) => {
              dispatch(setTableRows(e.target.value));
              dispatch(getPenggunaFilter(gender, age, isStatus, valueCity));
            }}
            value={tableRows}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </div>

      {/* PageNextPrev */}
      <PageNextPrev
        totalData={totalData}
        totalPage={totalPage}
        activePage={activePage}
        onChange={(value) => {
          dispatch(
            setActivePage(value === "LEFT" ? activePage - 1 : activePage + 1)
          );
          dispatch(getPenggunaFilter(gender, age, isStatus, valueCity));
        }}
      />
    </Container>
  );
};

// Style
const DefaultImgContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 100%;
`;

const DefaultImg = styled("p")`
  color: white;
  text-decoration: none;
  font-size: 13px;
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default SemuaPengguna;
