// Page Data Postingan
// --------------------------------------------------------

import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PageContainer from "../../../components/PageContainer";

// Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setActivePage,
  setActiveStep,
  setMaxPage,
  setTotalPage,
} from "../../../store/actions/dataPostingan";

// Page
import DataPostingan from "./DataPostingan";
import DetailPostingan from "./DetailPostingan";
import SemuaPostingan from "./SemuaPostingan";

const Page = () => {
  const dispatch = useDispatch();

  const { activeStep } = useSelector(
    (state) => state.dataPostingan,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      dispatch(setActiveStep("page"));
      dispatch(setMaxPage(0));
      dispatch(setTotalPage(1));
      dispatch(setActivePage(1));
    };
  }, [dispatch]);

  return (
    <PageContainer>
      <Container>
        {activeStep === "page" && <DataPostingan />}
        {activeStep === "detail" && <DetailPostingan />}
        {activeStep === "all" && <SemuaPostingan />}
      </Container>
    </PageContainer>
  );
};

const Container = styled("div")(() => ({
  width: "100%",
  height: "100%",
}));

export default Page;
