import {
  SET_LOADING,
  SET_MESSAGE,
  SET_LIST_NOTIF,
  SET_POPUP_LOGOUT,
  SET_MENU_ACCESS,
  SET_VALUE_REASON,
  SET_ACTIVE_STEP_PAGE,
  SET_ROUTE,
  SET_POPUP_FAILED,
} from "../actions/pageContainer";

const initialState = {
  isLoading: 0,
  message: "",
  listNotif: [],
  popupLogout: false,
  menuAccess: [],
  valueReason: "",
  activeStep: "page",
  route: [],
  popupFailed: false,
};

const pageContainer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POPUP_FAILED:
      return {
        ...state,
        popupFailed: action.payload,
      };
    case SET_ROUTE:
      return {
        ...state,
        route: action.payload,
      };
    case SET_ACTIVE_STEP_PAGE:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_MENU_ACCESS:
      return {
        ...state,
        menuAccess: action.payload,
      };
    case SET_POPUP_LOGOUT:
      return {
        ...state,
        popupLogout: action.payload,
      };
    case SET_LIST_NOTIF:
      return {
        ...state,
        listNotif: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
          ? state.isLoading + 1
          : state.isLoading > 0
          ? state.isLoading - 1
          : 0,
      };
    case SET_VALUE_REASON:
      return {
        ...state,
        valueReason: action.payload,
      };
    default:
      return state;
  }
};

export default pageContainer;
