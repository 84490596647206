import {
  SET_DATA_BANNER,
  SET_POPUP_ADD,
  SET_ACTIVE_PAGE_BANNER,
  SET_TOTAL_PAGE_BANNER,
  SET_MAX_PAGE_BANNER,
  SET_TOTAL_DATA_BANNER,
} from "../actions/banner";

const initialState = {
  dataBanner: [],
  popupAdd: false,
  activePage: 1,
  totalPage: 1,
  maxPage: 0,
  totalData: 0,
};

const banner = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOTAL_DATA_BANNER:
      return {
        ...state,
        totalData: action.payload,
      };
    case SET_MAX_PAGE_BANNER:
      return {
        ...state,
        maxPage: action.payload,
      };
    case SET_TOTAL_PAGE_BANNER:
      return {
        ...state,
        totalPage: action.payload,
      };
    case SET_ACTIVE_PAGE_BANNER:
      return {
        ...state,
        activePage: action.payload,
      };
    case SET_POPUP_ADD:
      return {
        ...state,
        popupAdd: action.payload,
      };
    case SET_DATA_BANNER:
      return {
        ...state,
        dataBanner: action.payload,
      };
    default:
      return state;
  }
};

export default banner;
