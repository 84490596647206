import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Title from "../../../components/Title";
import List from "@mui/material/List";
import Summary from "../../../components/Summary";
import PageContainer from "../../../components/PageContainer";
import Chart from "../../../components/Chart";
import moment from "moment";
import NoData from "../../../components/NoData";

// Redux
import { getDataDashboard } from "../../../store/actions/dashboard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// Assets
import arrowRight from "../../../assets/icon/arrow-right.svg";
import profile from "../../../assets/img/profile.png";
import survey from "../../../assets/icon/survey.svg";
import { Divider } from "@mui/material";

const Dashboard = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState("home");

  const { dataDashboard } = useSelector(
    (state) => state.dashboard,
    shallowEqual
  );

  const [summary, setSummary] = useState();
  const [chartGroup, setChartGroup] = useState(initChartGroup);
  const [chartUser, setChartUser] = useState(initChartUser);

  useEffect(() => {
    dispatch(getDataDashboard());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(dataDashboard).length !== 0) {
      setSummary({
        user: dataDashboard.header.total_users,
        group: dataDashboard.header.total_grup,
        blueCoral: dataDashboard.header.total_loker,
        myTeam: dataDashboard.header.total_proyek,
      });

      const { active, waiting, rejected } = dataDashboard.statistik.chart_grup;
      const totalChart1 =
        parseInt(active) + parseInt(waiting) + parseInt(rejected);
      const dataChart1 = [
        (100 / totalChart1) * waiting,
        (100 / totalChart1) * active,
        (100 / totalChart1) * rejected,
      ];
      setChartGroup([
        {
          ...initChartGroup[0],
          data: totalChart1 === 0 ? [100] : dataChart1,
          empty: totalChart1 === 0,
          backgroundColor: [
            totalChart1 === 0 ? "#bbbaba" : "#115ABE",
            "#03B74B",
            "#FF710B",
          ],
          label: [
            {
              title: "Grup Belum Verifikasi",
              detail: `${waiting} Grup`,
              color: "#115ABE",
            },
            {
              title: "Grup Disetujui",
              detail: `${active} Grup`,
              color: "#03B74B",
            },
            {
              title: "Grup Ditolak",
              detail: `${rejected} Grup`,
              color: "#FF710B",
            },
          ],
        },
      ]);

      const { chart_user } = dataDashboard.statistik;
      const totalChart2 =
        parseInt(chart_user.active) +
        parseInt(chart_user.noanctive) +
        parseInt(chart_user.block);
      const dataChart2 = [
        (100 / totalChart2) * chart_user.noanctive || 0,
        (100 / totalChart2) * chart_user.active || 0,
        (100 / totalChart2) * chart_user.block || 0,
      ];
      setChartUser([
        {
          ...initChartUser[0],
          data: totalChart2 === 0 ? [100] : dataChart2,
          empty: totalChart2 === 0,
          backgroundColor: [
            totalChart2 === 0 ? "#bbbaba" : "#FFBF0B",
            "#03B74B",
            "#FE4747",
          ],
          label: [
            {
              title: "Pengguna Belum Verifikasi",
              detail: `${chart_user.noanctive} Pengguna`,
              color: "#FFBF0B",
            },
            {
              title: "Pengguna Terverifikasi",
              detail: `${chart_user.active} Pengguna`,
              color: "#03B74B",
            },
            {
              title: "Pengguna Diblokir",
              detail: `${chart_user.block} Pengguna`,
              color: "#FE4747",
            },
          ],
        },
      ]);
    }
  }, [dataDashboard]);

  return (
    <PageContainer>
      {page === "home" ? (
        <Container>
          {/* Title Page */}
          <Title title="Dashboard" />

          <div style={{ marginTop: "20px" }}>
            {/* Summary */}
            <Summary data={summary} />

            {/* Grid Container */}
            <GridContainer onClick={() => localStorage.removeItem("dataUser")}>
              {/* Chart Component */}
              <ChartComponent>
                <TitleBar>Statistik Data Loker dan Grup</TitleBar>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <Chart
                    data={chartUser}
                    description={`${
                      dataDashboard?.statistik?.chart_user?.total_user || 0
                    } Pengguna`}
                  />
                  <Chart
                    data={chartGroup}
                    description={`${
                      dataDashboard?.statistik?.chart_grup?.total_grup || 0
                    } Grup`}
                  />
                </div>
              </ChartComponent>

              {/* Notification latest activity*/}
              <LatestActivity>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  {/* Title header latest activity */}
                  <TitleBar>Aktivitas Terakhir</TitleBar>

                  {/* Button to see other all activity */}
                  {dataDashboard?.recent?.length > 0 && (
                    <SeeOther onClick={() => setPage("log")}>
                      <p>Lihat lainnya</p>
                      <img src={arrowRight} alt="" width={"15px"} />
                    </SeeOther>
                  )}
                </div>

                {/* Scroll view for see activity */}
                <ScrollView>
                  {dataDashboard?.recent?.length > 0 ? (
                    dataDashboard?.recent?.map((item, index) => (
                      <List aria-label="contacts" disablePadding key={index}>
                        <ContentWrap>
                          <img alt="person-trending" src={profile} />
                          <TextWrapper>
                            <span style={{ fontWeight: "600" }}>
                              {item.full_name}
                              {", "}
                            </span>
                            <span>{item.description}</span>
                            {", "}
                            <span>{moment(item.create_at).fromNow()}</span>
                          </TextWrapper>
                        </ContentWrap>
                      </List>
                    ))
                  ) : (
                    <NoData />
                  )}
                </ScrollView>

                {/* <NoData /> */}
              </LatestActivity>

              {/* List Project */}
              <ListProject>
                <TitleBar>Daftar Project</TitleBar>

                {/* Project Content */}
                <ProjectContent>
                  <ProjectSection>
                    <ImageContainer color="#3b9cf1">
                      <img src={survey} alt="" />
                    </ImageContainer>
                    <div>
                      <TotalProject>
                        {dataDashboard?.chart_proyek?.active || 0} Project
                      </TotalProject>
                      <DescProject>Project Menunggu</DescProject>
                    </div>
                  </ProjectSection>
                  <ProjectSection>
                    <ImageContainer color="rgba(241, 59, 59, 0.83)">
                      <img src={survey} alt="" />
                    </ImageContainer>
                    <div>
                      <TotalProject>
                        {dataDashboard?.chart_proyek?.rejected || 0} Project
                      </TotalProject>
                      <DescProject>Project Ditolak</DescProject>
                    </div>
                  </ProjectSection>
                  <ProjectSection>
                    <ImageContainer color="rgba(42, 219, 13, 0.83);">
                      <img src={survey} alt="" />
                    </ImageContainer>
                    <div>
                      <TotalProject>
                        {dataDashboard?.chart_proyek?.aproved || 0} Project
                      </TotalProject>
                      <DescProject>Project Disetujui</DescProject>
                    </div>
                  </ProjectSection>
                </ProjectContent>
              </ListProject>
            </GridContainer>
          </div>
        </Container>
      ) : (
        <React.Fragment>
          <Title
            title="Log Aktifitas"
            withBack
            onBack={() => setPage("home")}
          />

          <LatestActivity style={{ marginTop: "30px" }}>
            {/* Scroll view for see activity */}
            <ScrollView>
              {Object.keys(dataDashboard).length > 0 ? (
                dataDashboard?.recent.map((item, index) => (
                  <List aria-label="contacts" disablePadding key={index}>
                    <ContentWrap>
                      <img alt="person-trending" src={profile} />
                      <TextWrapper>
                        <span style={{ fontWeight: "600" }}>
                          {item.full_name}
                          {", "}
                        </span>
                        <span>{item.description}</span>
                        {", "}
                        <span>{moment(item.create_at).fromNow()}</span>
                      </TextWrapper>
                    </ContentWrap>
                    <Divider />
                  </List>
                ))
              ) : (
                <NoData />
              )}
            </ScrollView>
            {/* <NoData /> */}
          </LatestActivity>
        </React.Fragment>
      )}
    </PageContainer>
  );
};

const initChartUser = [
  {
    data: [0, 0, 0],
    backgroundColor: ["#FFBF0B", "#03B74B", "#FE4747"],
    borderWidth: 0,
    label: [
      {
        title: "Pengguna Diblokir",
        detail: "0 Pengguna",
        color: "#FFBF0B",
      },
      {
        title: "Pengguna Belum verifikasi",
        detail: "0 Pengguna",
        color: "#03B74B",
      },
      {
        title: "Pengguna terverifikasi",
        detail: "0 Pengguna",
        color: "#FE4747",
      },
    ],
  },
];

const initChartGroup = [
  {
    data: [0, 0, 0],
    backgroundColor: ["#115ABE", "#03B74B", "#FF710B"],
    borderWidth: 0,
    label: [
      {
        title: "Grup Belum Verifikasi",
        detail: "0 Grup",
        color: "#115ABE",
      },
      {
        title: "Grup Disetujui",
        detail: "0 Grup",
        color: "#03B74B",
      },
      {
        title: "Grup Ditolak",
        detail: "0 Grup",
        color: "#FF710B",
      },
    ],
  },
];

// Style
const Container = styled("div")``;

const GridContainer = styled("div")`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2fr 1fr;
  grid-template-areas:
    "chart activity"
    "listProject activity";
  max-height: 498px;
  grid-gap: 15px;
  margin-top: 15px;
`;

const ChartComponent = styled("div")(() => ({
  gridArea: "chart",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
}));

const LatestActivity = styled("div")(() => ({
  gridArea: "activity",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
  height: "object-fit",
}));

const ListProject = styled("div")(() => ({
  gridArea: "listProject",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
  padding: "15px",
  borderRadius: "10px",
}));

const TitleBar = styled("p")(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "15px",
  lineHeight: "18px",
  letterSpacing: "0.01em",
  color: "#000000",
}));

const SeeOther = styled("div")`
  display: flex;
  align-items: center;
  padding: 2px;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  color: #0061a7;
  cursor: pointer;
`;

const ScrollView = styled("div")`
  overflow: scroll;
  height: 92%;
  /* padding: 13px 11px; */
`;

const ContentWrap = styled("div")(() => ({
  display: "flex",
  padding: "5px 10px",
  gap: "20px",
  alignItems: "center",
}));

const TextWrapper = styled("div")`
  font-weight: 500;
  font-size: 10px;
  line-height: 10.89px;
`;

const ProjectContent = styled("div")`
  display: flex;
  justify-content: space-around;
  margin: 21px 0;
`;

const ProjectSection = styled("div")`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const ImageContainer = styled("div")`
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  border-radius: 10px;
`;

const TotalProject = styled("p")`
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
`;

const DescProject = styled("p")`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #979494;
`;

export default Dashboard;
